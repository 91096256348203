import React, { useEffect } from "react";
import Image from "../Image";
import { ReactComponent as ShapeSquareLeft } from "../../Assets/Images/Shapes/shape-square.svg";
import { ReactComponent as ShapeSquareRight } from "../../Assets/Images/Shapes/shape-square--light.svg";
import { ReactComponent as LinearShapeRight } from "../../Assets/Images/Shapes/shape-square-linear.svg";
import { ReactComponent as LinearShapeLeft } from "../../Assets/Images/Shapes/shape-square-linear--left.svg";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTAButtonWrapper from "../CTABtnWrapper";

export default function FeatureHeader({ data, pageName, id }) {
  const { getTranslatableContent} = useTranslationEncapsulated();
  
  useEffect(() => {}, [data]);
  
  return (
    <section id={id} className="product-details-header js-section_nav-dark">
      <div className="container mx-auto">
        <div className="product-details-header__top">
          <div className="product-details-header__top-inner">
            <span className="product-details-header__top__pen">
              {getTranslatableContent(data?.alias)}
            </span>
            {data?.status ? (
              <span
                className={`product-details-header__top__status product-details-header__top__status--${data.status.alias}`}
              >
                {getTranslatableContent(data?.status.label)}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="parent">
          <div className="div1">
            <div className="product-icon__wrapper">
              <div className="product-icon">
                {data?.icon ? (
                  <Image
                    fileName={`Assets/Images/Features/${data?.icon}`}
                    width=""
                  />
                ) : null}
              </div>
            </div>
          </div>
          <div className="div2">
            <div className="product-alias__wrapper">
              <div className="product-alias">
                <span className="product-alias__name">{getTranslatableContent(pageName)}</span>
              </div>
            </div>
          </div>
          <div className="div3">
            <div className="product-shape-square-left__wrapper">
              <div className="product-shape-square-left">
                <ShapeSquareLeft />
              </div>
            </div>
          </div>
          <div className="div4">
            <div className="product-content__wrapper">
              <div className="product-content">
                {" "}
                <h1 className="product-content__name">
                  {/*<span className="highlight">Power up your </span>
                  localization with generative <span className="shine">AI</span>*/}
                  {getTranslatableContent(data?.shortText)}
                </h1>
                <span className="product-content__description">
                {getTranslatableContent(data?.description)}
                </span>{" "}
                {data?.cta?.length ? (
                  <>
                    <div className="product-content__cta-btn__wrapper">
                      {data.cta.map((c, i) => (
                        <CTAButtonWrapper
                          id={c.id}
                          key={c.id}
                          options={{
                            formPopupTheme: "light",
                            utmLabel: "js_product_header_cta_btn",
                          }}
                          children={
                            <button
                            id={c.id}
                            key={c.id + "_children"}
                              className="product-content__cta-btn"
                            >
                              {" "}
                              {getTranslatableContent(c?.label)}
                            </button>
                          }
                          data={{
                            ...c,
                            ...{ pageName: getTranslatableContent(pageName) ? getTranslatableContent(pageName) : "" },
                          }}
                        ></CTAButtonWrapper>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <div className="div5">
            <div className="product-shape-linear-right__wrapper">
              <div className="product-shape-linear-right">
                <LinearShapeLeft />
              </div>
            </div>
          </div>
          <div className="div6">
            <div className="product-shape-linear-left__wrapper">
              <div className="product-shape-linear-left">
                <LinearShapeRight />
              </div>
            </div>
          </div>
          <div className="div7">
            <div className="product-shape-square-right__wrapper">
              <div className="product-shape-square-right">
                <ShapeSquareRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
