import React, { useEffect, useState } from "react";
import CONFIG from "../../../Services/ConfigService";
import { getCookie, setCookie } from "../../../Libs/utils";
import { Link } from "react-router-dom";
import { ReactComponent as HelpIcon } from "../../../Assets/Icons/info.svg";
import "./index.scss";

export default function CookieSettings() {
  const [cookieChannels, setCookieChannel] = useState([]);

  const onChangeCookieSettingsItem = (cookieType, cookieValue) => {
    const updatedCookieChannels = cookieChannels.map((c) =>
      c.cookieType === cookieType ? { ...c, ...{ value: cookieValue } } : c
    );
    setCookieChannel(updatedCookieChannels);
  };

  const getCheckBoxItemActions = (type) => {
    const cookieElement = cookieChannels.find((c) => c.cookieType === type);
    if (!cookieElement) {
      return null;
    }
    return (
      <div className="cookie-settings-page-content__card-actions">
        <span className={`state ${cookieElement.value ? "checked" : ""}`}>
          {cookieElement.value ? "Enabled" : "Disabled"}
        </span>
        <div className="input">
          <input
            type="checkbox"
            id={cookieElement.cookieType}
            onChange={(e) => {
              onChangeCookieSettingsItem(
                cookieElement.cookieType,
                e.target.checked
              );
            }}
            checked={cookieElement.value}
          />
          <label for={cookieElement.cookieType}>Advertising</label>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let cookieSettings;
    try {
      cookieSettings = JSON.parse(
        getCookie(CONFIG.APP.COOKIE_SETTINGS_KEY_NAME)
      );
      if (cookieSettings === null) {
        setCookieChannel(
          CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE?.channelSettings
        );
      } else {
        setCookieChannel(cookieSettings?.channelSettings);
      }
    } catch (e) {
      return console.error(e);
    }
  }, []);

  useEffect(() => {
    if (cookieChannels.length) {
      let cookieSettings;
      try {
        cookieSettings = JSON.parse(
          getCookie(CONFIG.APP.COOKIE_SETTINGS_KEY_NAME)
        );
        let cookieVal;
        if (cookieSettings === null) {
          cookieVal = {
            ...CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE,
            ...{
              channelSettings: cookieChannels,
            },
          };
        } else {
          cookieVal = {
            ...CONFIG.APP.DEFAULT_COOKIE_SETTINGS_VALUE,
            ...{
              channelSettings: cookieChannels,
              cookiePopupVisibility: false,
            },
          };
        }
        setCookie(
          CONFIG.APP.COOKIE_SETTINGS_KEY_NAME,
          JSON.stringify(cookieVal),
          CONFIG.APP.COOKIE_SETTINGS_EXPIRE_IN_DAYS
        );
      } catch (e) {
        return console.error(e);
      }
    }
  }, [cookieChannels]);

  return (
    <div className="cookie-settings-page">
      <section className="cookie-settings-page-hero__wrapper js-section_nav-dark">
        <div className="cookie-settings-page-hero">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-hero__header">
                  Cookie Settings
                </div>
                <div className="cookie-settings-page-hero__desc">
                  Lugath uses cookies and similar technologies to improve your
                  experience and for marketing purposes. Review and manage your
                  cookie settings below to control your privacy. For more
                  information on how we use cookies, please see Lugath's Cookie
                  Policy.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cookie-settings-page-content js-section_nav-dark">
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Advertising
                    </div>
                    {getCheckBoxItemActions("advertising")}
                    <div className="cookie-settings-page-content__card-desc">
                      Advertising cookies and similar technologies on lugath.com
                      allow us to place targeted Lugath advertisements on other
                      sites you visit and measure your engagement with those
                      ads. Disable the “Advertising” section here to opt-out of
                      targeted advertising, including data “sharing” under
                      California privacy law.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Preferences
                    </div>
                    {getCheckBoxItemActions("preferences")}
                    <div className="cookie-settings-page-content__card-desc">
                      Preference cookies let us save your preferences and
                      recognize you when you return to our Services.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Analytics
                    </div>
                    {getCheckBoxItemActions("analytics")}
                    <div className="cookie-settings-page-content__card-desc">
                      Analytics cookies help us understand how visitors interact
                      with our Services, allowing us to analyze and improve our
                      Services (also through third party analytics).
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Authentication
                    </div>
                    <div className="cookie-settings-page-content__card-actions">
                      <span className="state required">Required</span>
                      <div className="group mandatory">
                        <HelpIcon />
                        <span className="group-hover:opacity-100 transition-opacity absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto tooltip">
                          These cookies are necessary for our systems to work.
                        </span>
                      </div>
                    </div>
                    <div className="cookie-settings-page-content__card-desc">
                      Authentication cookies enable Lugath to remember you so
                      you don’t have to log in as you use our Services.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Fraud prevention
                    </div>
                    <div className="cookie-settings-page-content__card-actions">
                      <span className="state required">Required</span>
                      <div className="group mandatory">
                        <HelpIcon />
                        <span className="group-hover:opacity-100 transition-opacity absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto tooltip">
                          These cookies are necessary for our systems to work.
                        </span>
                      </div>
                    </div>
                    <div className="cookie-settings-page-content__card-desc">
                      Fraud prevention cookies, and similar technologies that we
                      deploy through our Site, help us learn things about
                      computers and web browsers used to access Lugath Services.
                      This information helps us monitor for and detect
                      potentially harmful or illegal use of our Services.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Functionality{" "}
                    </div>
                    <div className="cookie-settings-page-content__card-actions">
                      <span className="state required">Required</span>
                      <div className="group mandatory">
                        <HelpIcon />
                        <span className="group-hover:opacity-100 transition-opacity absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto tooltip">
                          These cookies are necessary for our systems to work.
                        </span>
                      </div>
                    </div>
                    <div className="cookie-settings-page-content__card-desc">
                      Functionality cookies are used to keep our Site and
                      Services working correctly, like showing you the right
                      information for your selected location.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Security{" "}
                    </div>
                    <div className="cookie-settings-page-content__card-actions">
                      <span className="state required">Required</span>
                      <div className="group mandatory">
                        <HelpIcon />
                        <span className="group-hover:opacity-100 transition-opacity absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto tooltip">
                          These cookies are necessary for our systems to work.
                        </span>
                      </div>
                    </div>
                    <div className="cookie-settings-page-content__card-desc">
                      Security cookies enable us to protect user data from
                      unauthorized access.
                    </div>
                    <Link
                      to="/legal/cookie-policy"
                      className="cookie-settings-page-content__card-link"
                    >
                      View Cookie Policy
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cookie-settings-page-content__item">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div className="cookie-settings-page-content__card-wrapper">
                  <div className="cookie-settings-page-content__card">
                    <div className="cookie-settings-page-content__card-title">
                      Third-party providers
                    </div>
                    <div className="cookie-settings-page-content__card-desc">
                      We work with third-party providers like Facebook, Google,
                      and LinkedIn, who also set cookies for analytics and
                      marketing purposes. This list may not be exhaustive, but
                      we commit to keeping it updated based on information from
                      these providers. Please see the following resources for
                      more detailed information about the cookies set by
                      Facebook, Google, and LinkedIn:
                    </div>
                    <a
                      href="https://www.facebook.com/policy/cookies/"
                      target="_blank"
                      rel="noopener"
                      className="cookie-settings-page-content__card-link"
                    >
                      Facebook documentation
                    </a>
                    <a
                      href="https://policies.google.com/technologies/types"
                      target="_blank"
                      rel="noopener"
                      className="cookie-settings-page-content__card-link"
                    >
                      Google documentation
                    </a>
                    <a
                      href="https://www.linkedin.com/legal/l/cookie-table"
                      target="_blank"
                      rel="noopener"
                      className="cookie-settings-page-content__card-link"
                    >
                      LinkedIn documentation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
