import React, { useState } from "react";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTABtnWrapper from "../CTABtnWrapper";
import Image from "../Image";
import "./index.scss";

export default function SecurityCoverage({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
  },
  pageName = "Security",
  id
}) {
  const { getTranslatableContent, Trans, t } = useTranslationEncapsulated();
  const cta = {};
  return (
    <section id={id} className="security-coverage js-section_nav-dark">
      <div className="container mx-auto">
        <div className="security-coverage__wrapper">
          <div className="security-coverage__inner">
            <div className="security-coverage__title">
              {getTranslatableContent(data.title)}
            </div>
            <div className="security-coverage__desc">
              {getTranslatableContent(data.description)}
            </div>
            <div className="security-coverage__content__wrapper">
              <div className="security-coverage__content">
                <div className="security-coverage__content-list__wrapper">
                  <ul className="security-coverage__content-list">
                    {data?.items.map((c) => (
                      <li className="security-coverage__content-list__item">
                        {getTranslatableContent(c.description)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
