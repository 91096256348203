import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import features from "./features";
import solutions from "./solutions";
import settings from "./settings";

export default combineReducers({
  features,
  solutions,
  settings,
  routing: routerReducer,
});
