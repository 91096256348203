import React, { useState, useEffect } from "react";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import { ReactComponent as PlayCircleIcon } from "../../Assets/Icons/play-circle.svg";
import ReactModal from "react-modal";
import VideoPlayer from "../VideoPlayer";
import { videosData } from "../../Services/DataService";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function VideoList({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
  },
}) {
  const { getTranslatableContent, Trans, getRawKey, t } =
    useTranslationEncapsulated();
  const [showModal, setShowModal] = useState(false);
  const [activeVideoURL, setActiveVideoURL] = useState(false);

  const onClickVideoItem = (item) => {
    setActiveVideoURL(item.videoURL);
  };

  const onCloseModal = () => {
    setShowModal(false);
    setActiveVideoURL(false);
  };

  useEffect(() => {
    if (activeVideoURL?.length) {
      setShowModal(true);
    }
  }, [activeVideoURL]);

  return (
    <section className="video-list-content js-section_nav-dark">
        {data.categories.map((category) => {
          return (
            <div className="video-list-content__item">
              <div className="container mx-auto">
                <div className="parent">
                  <div className="div1">
                    <div className="video-list-content__card-wrapper">
                      <div className="video-list-content__card">
                        <div className="video-list-content__card-title">
                          {getTranslatableContent(category.categoryName)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div2">
                    <div className="video-list-content__card-wrapper">
                      <div className="video-list-content__card">
                        <ul className="video-list-content__card-list">
                          {category.items.map((item) => (
                            <li className="video-list-content__card-list__item">
                              <button onClick={() => onClickVideoItem(item)}>
                                <span className="icon">
                                  <PlayCircleIcon />
                                </span>
                                <span className="label">{getTranslatableContent(item.videoName)}</span>
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
         <ReactModal
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: "9999999999",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            height: "80%",
            maxWidth: "1280px",
            maxHeight: "720px",
            transform: "translate(-50%, -50%)",
            background: "#282828",
            overflow: "visible",
            WebkitOverflowScrolling: "touch",
            borderRadius: "0px",
            outline: "none",
            padding: "0",
            border: "0px",
            boxShadow: "0px 0px 125px rgba(0,0,0,0.5)",
          },
        }}
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
      >
        <VideoPlayer url={activeVideoURL} />
        <button className="close-video-btn" onClick={() => onCloseModal()}>
          <span className="label">CLOSE</span>
          <CloseIcon />
        </button>
      </ReactModal>
      </section>
  );
}
