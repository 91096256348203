import React, { useState } from "react";
import "../index.scss";

export default function SLAPolicy(props) {
  return (
    <div className="policy-page-bg">
      <div className="contract-content container mx-auto">
        <div className="rows-pan-full hidden lg:block"></div>
        <div className="col-span-full lg:col-start-2 lg:col-span-7 ">
          <h1>Service Level Agreement (SLA) for <br/>Lugath Customers</h1>
          <span className="policy-page-date">
            Last Modified/Updated Date . <strong>1 June 2022</strong>
          </span>
          <span className="policy-page-date">
            Version . <strong>1.1</strong>
          </span>
        </div>
        <div className="col-span-full lg:col-start-2 lg:col-span-6 paragraph-paddings">
        <h1 className="padding-t-10"><u>1.Agreement Overview</u></h1>
        <br /><br />
          <p>This Agreement represents a Service Level Agreement (“SLA” or “Agreement”) between Lugath and Enterprise Customer for the provisioning of IT services required to support and sustain the Product or service. </p>
          <br />
          <p>
          This Agreement remains valid until superseded by a revised agreement mutually endorsed by the stakeholders.
          </p>
          <br />
          <p>
          This Agreement outlines the parameters of all IT services covered as they are mutually understood by the primary stakeholders. This Agreement does not supersede current processes and procedures unless explicitly stated herein.
          </p>
          <h1 className="padding-t-10"><u>2.Goals & Objectives</u></h1>
          <br /><br />
          <p>
          The purpose of this Agreement is to ensure that the proper elements and commitments are in place to provide consistent IT service support and delivery to the Customer(s) by the Service Provider(s). 
          </p>
          <br />

          <p>The goal of this Agreement is to obtain mutual agreement for IT service provision between the Service Provider(s) and Customer(s).</p>
          <br />
          <p>The objectives of this Agreement are to:</p>
          <br />
          <ul>
            <li>
              <p>
              Provide clear reference to service ownership, accountability, roles and/or responsibilities.
              </p>
            </li>
            <li>
              <p>
              Present a clear, concise and measurable description of service provision to the customer.
              </p>
            </li>
            <li>
              <p>
              Match perceptions of expected service provision with actual service support & delivery.
              </p>
            </li>
          </ul>
          <h1 className="padding-t-10"><u>3.Stakeholders</u></h1>
          <br />
          <br />
          <p>
          The following Service Provider(s) and Customer(s) will be used as the basis of the Agreement and represent the <strong>primary stakeholders</strong> associated with this SLA:
          </p>
          <ul>
            <li>
              <p>
              <b>Service Provider:</b> Lugath (“Provider”)
              </p>
            </li>
            <li>
              <p>
              <b>Customer : </b>Platform Users (“Customer”)
              </p>
            </li>
          </ul>
          <h1 className="padding-t-10"><u>4.Periodic Review</u></h1>
          <br />
          <br />
          <p>
          This Agreement is valid from the Effective Date outlined herein and is valid until further notice. This Agreement should be reviewed at a minimum once per fiscal year; however, in lieu of a review during any period specified, the current Agreement will remain in effect. 
          </p>
          <br />
          <p>
          The Business Relationship Manager (“Document Owner”) is responsible for facilitating regular reviews of this document. Contents of this document may be amended as required, provided mutual agreement is obtained from the primary stakeholders and communicated to all affected parties. The Document Owner will incorporate all subsequent revisions and obtain mutual agreements / approvals as required. </p>
          <ul>
            <li>
              <p>
              <b>Business Relationship Manager:</b> Business Relationship Manager
              </p>
            </li>
            <li>
              <p>
              <b>Review Period:</b> Bi-Yearly (6 months)
              </p>
            </li>
            <li>
              <p>
              <b>Previous Review Date:</b> Register Date
              </p>
            </li>
            <li>
              <p>
              <b>Next Review Date:</b> 6 Months after Last Review Date
              </p>
            </li>
          </ul>
          <br />
          <h1 className="padding-t-10">
            <u>5. Service Aggrement</u>
          </h1>
          <p>
            Except as may be otherwise noted, the information, materials
            (including, without limitation, HTML, text, audio, video, white
            papers, press releases, data sheets, product descriptions, software
            and FAQs and other content) available through the Platform Services
            (collectively, &quot;
            <em> Lugath Content </em>
            &quot;) are the copyrighted works of Lugath and its licensors, and
            Lugath and its licensors expressly retain all right title and
            interest in and to the Lugath Content, including, without
            limitation, all intellectual property rights therein and thereto.
          </p>
          <br />
          <h2>
            <u>1.6 Third Party Content and Trademarks.</u>
          </h2>
          <p>
            In addition to Lugath Content, the Platform Services may contain
            information and materials (API descriptions, specifications and
            metadata, pricing information and links to third-party websites and
            other information) that have either been made accessible to Lugath
            by third parties, including but not limited to suppliers,
            manufacturers, merchants, publications, publicists; or been gathered
            from public-domain sources (collectively, &quot;
            <em> Third Party Content </em>
            &quot;). Third Party Content is the copyrighted work of its owner,
            who expressly retains all right title and interest in and to the
            Third Party Content, including, without limitation, all intellectual
            property rights therein and thereto. In addition to being subject to
            these Terms of Service, Third Party Content may also be subject to
            different and/or additional terms of use and/or privacy policies of
            such third parties. Please contact the appropriate third party for
            further information regarding any such different and/or additional
            terms of use applicable to Third Party Content. All third-party
            trademarks, registered trademarks, product names, and company names
            or logos mentioned in the Platform Service (&quot;
            <em> Third-Party Trademarks </em>
            &quot;) are the property of their respective owners, and the use of
            such Third-Party Trademarks inures to the benefit of each owner. The
            use of such Third-Party Trademarks is intended to describe the
            third-party goods or services and to denote interoperability and
            does not constitute an affiliation by Lugath and its licensors with
            such company or an endorsement or approval by such company of Lugath
            or its licensors or their respective products or services.
          </p>
          <br />
          <h2>
            <u>1.7 Third Party Content Accuracy.</u>
          </h2>
          <p>
            The intention of Lugath is that all information on the Lugath
            website (the &quot;
            <em>
              {" "}
              <strong>Site</strong>{" "}
            </em>
            &quot;) should be accurate and up-to-date. However, Lugath cannot
            guarantee the reliability or the accuracy of the information
            contained within or provided by the Platform Services.
          </p>
          <p>
            The aforementioned information may be displayed in either original
            form or after being processed by various algorithms including but
            not limited to machine translation, summarization and entity
            extraction.
          </p>
          <p>
            While the information contained in the Platform Services has been
            obtained from sources believed to be reliable, Lugath disclaims all
            warranties as to the accuracy, completeness or adequacy of such
            information. Customer assumes sole responsibility for the use it
            makes of Platform Services to achieve Customer&#39;s intended
            results.
          </p>
          <p>
            In case of any error or infringement please contact us at{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
          </p>
          <br />
          <h2>
            <u>1.8 New Applications and Platform Services.</u>
          </h2>
          <p>
            Lugath may: (i) make new applications, tools, features or
            functionality available from time to time through the Platform
            Services and (ii) add new Platform Services to the &quot;Platform
            Services&quot; definition from time to time (by adding them at the
            URL set forth under that definition), the use of which may be
            contingent upon Customer&#39;s agreement to additional terms.
          </p>
          <br />
          <h2>
            <u>1.9 Modifications.</u>
          </h2>
          <p>
            <strong>
              <u>a. To the Platform Services.</u>
            </strong>{" "}
            Lugath may make updates to the Platform Services from time to time.
          </p>
          <br />
          <p>
            <strong>
              <u>b. To the Terms and Conditions.</u>
            </strong>{" "}
            Lugath may make changes to these Terms and Conditions. Customer will
            be notified 30 days before the material changes to the Terms and
            Conditions become effective, except if the changes apply to new
            functionality or to the public pricing of the Third-Party Platform
            Services for in which case they will be effective immediately. If
            Customer does not agree to the revised Terms and Conditions,
            Customer shall stop using the Platform Services. Customer&#39;s
            continued use of the Platform Services will constitute
            Customer&#39;s agreement to the revised Terms and Conditions.
          </p>
          <br />
          <h2>
            <u>1.10 Automatically ingested content.</u>
          </h2>
          <p>
            API descriptions, specifications and metadata, pricing information
            and links to third-party websites and other information have either
            been made accessible by suppliers, manufacturers, merchants,
            publications, publicists; or been gathered from public-domain
            sources. The intention of Lugath is that all information on the
            website should be as accurate and up-to-date as possible. However,
            Lugath cannot guarantee the reliability or the accuracy of the
            information contained within its pages. We urge our users to conduct
            their own research prior to purchasing from the vendors listed on
            our service to ensure the accuracy of the prices. All the actions
            taken by the user of the website are the responsibility of the
            individual. Lugath cannot make any guarantees that any information
            is correct or be held liable for any actions taken based on the
            product and pricing information provided.
          </p>
          <p>
            The aforementioned information may be displayed in either original
            form or after being processed by various algorithms including but
            not limited to machine translation, summarization and entity
            extraction.
          </p>
          <p>
            While the information contained on the Lugath website has been
            obtained from sources believed to be reliable, Lugath disclaims all
            warranties as to the accuracy, completeness or adequacy of such
            information. Customer assumes sole responsibility for the use it
            makes of this Website to achieve Customer&#39;s intended results.
          </p>
          <p>
            In case of any error or infringement please contact us at{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
          </p>
          <p>
            Lugath automatically crawls information to find new APIs and uses
            commercially reasonable efforts to keep information up-to-date. If
            you become aware of an error in the Platform Services information,
            please contact us at{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
            .
          </p>
          <br />
          <h2>
            <u> 1.11 Performance evaluation.</u>
          </h2>
          <p>
            Lugath Platform Services use and provide information about
            performance of various third-party services. The performance
            measures are estimated according to Lugath&#39;s benchmarking
            methodology using public and Lugath internal datasets. The
            performance comparison is valid regarding the data and methodology
            Lugath used, at the time Lugath has accessed those services. It does
            not imply any assessment of either providers of those services or
            their brands or the applicability of those services to particular
            business needs of Lugath users. Moreover, it&#39;s highly likely
            that the performance estimated on other datasets would differ.
          </p>
          <p>
            Lugath provides performance estimations on the Company&#39;s own and
            public datasets. To obtain an estimate of the performance of your
            dataset, please contact us at{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
            .
          </p>
          <br />
          <h2>
            <u>1.12 Third-Party Services</u>
          </h2>
          <p>
            The Site may include forms that allow Customer to input criteria for
            finding service vendors (such as desired features and services,
            price points, etc.). Lugath Platform Services will use the
            information entered by Customer to deliver a list of vendors that
            may fit Customer needs. The vendors Lugath may suggest are
            independent parties and are not agents of Lugath. Lugath does not
            endorse or recommend the services of any particular vendor. It is
            entirely up to Customer to evaluate a vendor&#39;s qualifications.
            Lugath does not guarantee that it will be able to match Customer
            service needs with an appropriate vendor or that there are vendors
            that are capable or willing to provide Customer service needs.
          </p>
          <h3>Taxes</h3>
          <p>
            Customer is responsible for any Taxes arising with respect to the
            Platform Services (except for taxes on Lugath&#39;s net income), and
            Customer will pay Lugath for the Platform Services without any
            reduction for Taxes.
          </p>
          <h3>Customer Obligations.</h3>
          <h2>
            <u>3.1 Compliance.</u>
          </h2>
          <p>
            Customer is solely responsible for its Applications, and Customer
            Data and for ensuring that its Applications, and Customer Data
            comply with the AUP. Lugath reserves the right to review the
            Application and Customer Data for compliance with the AUP. Customer
            is responsible for ensuring all Customer End Users comply with
            Customer&#39;s obligations under the AUP and the restrictions in
            <strong> Sections 3.3 and 3.5 below.</strong>
          </p>
          <br />
          <h2>
            <u>3.2 Privacy.</u>
          </h2>
          <p>
            Customer will obtain and maintain any required consents necessary to
            permit the processing of Customer Data by Lugath under these Terms
            and Conditions.
          </p>
          <br />
          <h2>
            <u>3.3 Restrictions.</u>
          </h2>
          <p>
            Customer will not, and will not allow third parties under its
            control to: (a) copy, modify, create a derivative work of, reverse
            engineer, decompile, translate, disassemble, or otherwise attempt to
            extract any or all of the source code of the Platform Services
            (subject to
            <strong>
              <u> Section 3.4 </u>
            </strong>
            below and except to the extent such restriction is expressly
            prohibited by applicable law); (b) use the Platform Services for
            High Risk Activities; (c) sublicense, resell, or distribute any or
            all of the Platform Services separate from any integrated
            Application; (d) create multiple Applications to simulate or act as
            a single Application or otherwise access the Platform Services in a
            manner intended to avoid incurring License Fees; (e) use the
            Platform Services to operate or enable any telecommunications
            service or in connection with any Application that allows Customer
            End Users to place calls or to receive calls from any public
            switched telephone network; or (f) process or store any Customer
            Data that is subject to the International Traffic in Arms
            Regulations maintained by the Department of State. Unless otherwise
            specified in writing by Lugath, Lugath does not intend to use the
            Platform Services to create obligations in accordance with the
            Regulation on Personal Health Data in addition to the scope of the
            HIPAA and Personal Data Protection Law No. 6698 and makes no
            representations that the Platform Services meet the requirements of
            the Regulation on Personal Health Data in addition to the scope of
            the HIPAA and the Personal Data Protection Law No. 6698. If the
            Customer is (or becomes) a Covered Entity or Business Partner as
            defined in the HIPAA or in the Regulation on Personal Health Data in
            addition to the scope of the Personal Data Protection Law No. 6698,
            the Customer shall not use the Platform Services for any purpose or
            in any manner involving the Protected Health Information, unless the
            Customer obtains prior written consent from Lugath for such use.
          </p>
          <br />
          <h2>
            <u>3.4 Third Party Components.</u>
          </h2>
          <p>
            Third party components (which may include open source software) of
            the Platform Services may be subject to separate license agreements.
            To the limited extent a third-party license expressly supersedes
            these Terms and Conditions, that third party license governs
            Customer&#39;s use of that third party component.
          </p>
          <br />
          <h2>
            <u>3.5 Documentation.</u>
          </h2>
          <p>
            Lugath may provide Documentation for Customer&#39;s use of the
            Platform Services. The Documentation may specify restrictions (e.g.
            attribution or HTML restrictions) on how the Applications may be
            built or the Platform Services may be used and Customer will comply
            with any such restrictions specified.
          </p>
          <br />
          <h2>
            <u>3.6 Limited Service Content License.</u>
          </h2>
          <p>
            Lugath grants Customer a limited, revocable, non-transferable,
            non-exclusive right to use the Lugath Content and Third Party
            Content (collectively, &quot;
            <em>
              {" "}
              <strong>Service Content</strong>{" "}
            </em>
            &quot;) by displaying the Service Content on end-user computers and
            using Service Content in Customer&#39;s Applications, under the
            condition that
          </p>
          <p>(i) it complies with AUP,</p>
          <p>
            (ii) Customer does not modify or prepare derivative works from the
            Service Content,
          </p>
          <p>
            (iii) Customer does not obscure, alter or remove any notice of
            copyright set forth on any Service Content,
          </p>
          <p>
            (iv) Customer does not otherwise reproduce, re-distribute or
            publicly display any of the Service Content.
          </p>
          <br />
          <h2>
            <u>3.7 User Content.</u>
          </h2>
          <p>
            If Customer elects to display, post, submit or otherwise make
            available to others, through the Platform Services any content or
            works of authorship, including, without limitation, images, audio
            files, text, software (but excluding User software) or other
            materials, (collectively, &quot;
            <em>
              {" "}
              <strong>User Content</strong>{" "}
            </em>
            &quot;), Customer hereby grants to Lugath a perpetual, irrevocable,
            royalty-free, worldwide, non-exclusive right and license, including
            the right to grant sublicenses to third parties, to use, reproduce,
            publicly display, publicly perform, prepare derivative works from
            and distribute such User Content, for any purpose. In addition,
            Customer hereby irrevocably represents and warrants to Lugath that
            <p>
              (i) Customer has all necessary power, authority, right, title
              and/or licenses to grant to Lugath the foregoing right and license
              and
            </p>
            <p>
              {" "}
              (ii) the posting, submission, display by by Customer of User
              Content, and the exercise by Lugath of the foregoing license does
              not and will not (1) violate any applicable law or government
              regulation or (2) infringe any right of publicity or invades the
              privacy of others, or any intellectual property right of any third
              party,
            </p>{" "}
            <p>
              {" "}
              (iii) no User Content you provide (1) will constitute obscene,
              pornographic, indecent, profane or otherwise objectionable
              material, (2) is discriminatory, hateful or bigoted toward, or
              abusive of, any group or individual, or (3) is libelous or
              defamatory. For the avoidance of doubt, any information or data
              from Customer accounts with third party services (i.e., Gmail or
              Dropbox) that is passes through or is processed by Lugath and
              which you do not make publically available on Lugath, is not User
              Content, and Lugath does not receive any rights in such
              information or data.
            </p>
          </p>
          <h1>Suspension and Removals.</h1>
          <br />
          <h2>
            <u>4.1 Suspension/Removals.</u>
          </h2>
          <p>
            If Customer becomes aware that any Application or Customer Data
            violates the AUP, Customer will immediately suspend the Application
            and/or remove the relevant Customer Data (as applicable). If
            Customer fails to suspend or remove as noted in the prior sentence,
            Lugath may specifically request that Customer do so. If Customer
            fails to comply with Lugath&#39;s request to do so within
            twenty-four hours, then Lugath may disable the Application and/or
            disable the Account (as may be applicable) until such violation is
            corrected.
          </p>
          <br />
          <h2>
            <u>4.2 Emergency Security Issues.</u>
          </h2>
          <p>
            Despite the foregoing, if there is an Emergency Security Issue, then
            Lugath may automatically suspend the offending Application and/or
            Account. Suspension will be to the minimum extent required, and of
            the minimum duration, to prevent or resolve the Emergency Security
            Issue. If Lugath suspends an Application or the Account, for any
            reason, without prior notice to Customer, at Customer&#39;s request,
            Lugath will provide Customer the reason for the suspension as soon
            as is reasonably possible.
          </p>
          <h1>Intellectual Property Rights; Use of Customer Data; Feedback.</h1>
          <br />
          <h2>
            <u>5.1 Intellectual Property Rights.</u>
          </h2>
          <p>
            Except as expressly set forth in these Terms and Conditions, these
            Terms and Conditions do not grant either party any rights, implied
            or otherwise, to the other&#39;s content or any of the other&#39;s
            intellectual property. As between the parties, Customer owns all
            Intellectual Property Rights in Customer Data and the Application
            (if applicable), and Lugath owns all Intellectual Property Rights in
            the Platform Services and Software.
          </p>
          <br />
          <h2>
            <u>5.2 Use of Customer Data.</u>
          </h2>
          <p>
            Lugath will not access or use Customer Data, except as necessary to
            provide the Platform Services to Customer.
          </p>
          <p>
            Customer Data transferred via Platform Services may be analyzed
            automatically by the Platform Services to identify the best
            appropriate Third-Party Service among candidate Third-Party Platform
            Services (&quot;
            <em>
              {" "}
              <strong>Best Match</strong>{" "}
            </em>
            &quot;) if Customer is requested to do so and to improve the Best
            Match only. Customer Data is never used by Lugath, directly or
            indirectly, to improve any other Lugath Service except the Best
            Match.
          </p>
          <p>
            A detailed description of what Customer Data is received by Lugath,
            how it is stored and processed is presented in Exhibit B (Data
            Processing).
          </p>
          <br />
          <h2>
            <u>5.3 Customer Feedback.</u>
          </h2>
          <p>
            If Customer provides Lugath Feedback about the Platform Services,
            then Lugath may use that information without obligation to Customer,
            and Customer hereby irrevocably assigns to Lugath all right, title,
            and interest in that Feedback.
          </p>
          <h1>Technical Support Platform Services</h1>
          <br />
          <h2>
            <u>6.1 By Customer.</u>
          </h2>
          <p>
            Customer is responsible for technical support of its Applications.
          </p>
          <br />
          <h2>
            <u> 6.2 By Lugath.</u>
          </h2>
          <p>
            Lugath provides a basic Technical Support Platform Services, so that
            if the Platform Services stopped to operate according to their
            specification, Lugath use commercially reasonable efforts to remedy
            service failures as soon as possible during normal business hours.
          </p>
          <h1>Deprecation of Platform Services</h1>
          <br />
          <h2>
            <u>7.1 Discontinuance of Platform Services.</u>
          </h2>
          <p>
            Subject to <strong> Section 7.2,</strong> Lugath may discontinue any
            Platform Services or any portion or feature for any reason at any
            time without liability to Customer.
          </p>
          <br />
          <h2>
            <u>7.2 Deprecation Policy.</u>
          </h2>
          <p>
            Lugath will notify Customer if it intends to discontinue or make
            backwards incompatible changes to the Platform Services subscribed
            to by Customer. Lugath will use commercially reasonable efforts to
            continue to operate those Platform Services without these changes
            for at least one year after that notice, unless (as Lugath
            determines in its reasonable good faith judgment):
          </p>
          <p>
            (i) required by law or third party relationship (including if there
            is a change in applicable law or relationship), or
          </p>
          <p>
            (ii) doing so could create a security risk or substantial economic
            or material technical burden.
          </p>
          <p>
            The above policy is the &quot;
            <em>
              {" "}
              <strong>Deprecation Policy</strong>{" "}
            </em>
            .&quot;
          </p>
          <h1>Platform Services Term and Termination of Platform Services.</h1>
          <br />
          <h2>
            <u>8.1 Agreement Platform Services Term.</u>
          </h2>
          <p>
            The &quot;
            <em>
              {" "}
              <strong>Platform Services Term</strong>{" "}
            </em>
            &quot; means the period for which the Customer has subscribed to
            access and use the Platform Services. Details mentioned in the
            Membership Information Form.
          </p>
          <br />
          <h2>
            <u>8.2 Termination for Breach.</u>
          </h2>
          <p>
            Either party may terminate the Platform Services for breach if:{" "}
            <p>
              (i) the other party is in material breach of the Terms and
              Conditions or applicable subscription terms and fails to cure that
              breach within thirty (30) days after receipt of written notice;
            </p>{" "}
            <p>
              {" "}
              (ii) the other party ceases its business operations or becomes
              subject to insolvency proceedings and the proceedings are not
              dismissed within ninety days; or{" "}
            </p>{" "}
            <p>
              (iii) the other party is in material breach of these Terms and
              Conditions or the applicable terms of more than two times
              notwithstanding any cure of such breaches.
            </p>
          </p>
          <br />
          <h2>
            <u> 8.3 Termination for Inactivity.</u>
          </h2>
          <p>
            Lugath reserves the right to terminate the Platform Services for
            inactivity, if, for a period exceeding 180 days: (a) an Application
            has not served any requests; and (b) no electronic bills are being
            generated.
          </p>
          <br />
          <h2>
            <u> 8.4 Termination for Convenience.</u>
          </h2>
          <p>
            Lugath may terminate the Platform Services for its convenience at
            any time without liability to Customer, provided Lugath shall refund
            prepaid and unused License Fees in the event of such termination.
          </p>
          <br />
          <h2>
            <u>8.5 Effect of Termination.</u>
          </h2>
          <p>
            If the Platform Services are terminated, then:
            <p>
              (i) the rights granted by one party to the other will immediately
              cease;
            </p>
            <p>
              (ii) Customer will delete the Software, any Service Content, any
              Application and any Customer Data stored on the Lugath facilities;
              and
            </p>
            <p>
              (iii) upon request, each party will use commercially reasonable
              efforts to return or destroy all Confidential Information of the
              other party.
            </p>
          </p>
          <h1>Publicity.</h1>
          <br />
          <p>
            Customer is permitted to state publicly that it is a customer of the
            Platform Services, consistent with the Trademark Guidelines. If
            Customer wants to display Lugath Brand Features in connection with
            its use of the Platform Services, Customer must obtain written
            permission from Lugath by sending a request to{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
            .
          </p>
          <p>
            Lugath may include Customer&#39;s name or Brand Features in a list
            of Lugath customers, online or in promotional materials. Lugath may
            also verbally reference Customer as a customer of the Platform
            Services. Neither party needs approval if it is repeating a public
            statement that is substantially similar to a previously-approved
            public statement. Any use of a party&#39;s Brand Features will
            insure to the benefit of the party holding Intellectual Property
            Rights to those Brand Features. A party may revoke the other
            party&#39;s right to use its Brand Features under this Section with
            written notice to the other party and a reasonable period to stop
            the use.
          </p>
          <h1>Representations and Warranties.</h1>
          <br />
          <p>
            Each party represents and warrants that: (a) it has full power and
            authority to enter into these Terms and Conditions and the
            applicable subscription terms; and (b) it will comply with all laws
            and regulations applicable to its provision, or use, of the
            Services, as applicable.
          </p>
          <p>
            Customer represents and warrant that Customer (i) is not on a list
            of persons barred you from receiving services under Turkey laws
            (including, without limitation, the Denied Persons List and the
            Entity List issued by the Turkey Department of Commerce and
            Industry)or other applicable jurisdiction and (iii) is not a
            resident of any country subject to Turkey trade sanctions.
          </p>
          <h1>Disclaimers.</h1>
          <br />
          <p>
            THE PLATFORM SERVICES ARE PROVIDED STRICTLY ON AN &quot;AS IS&quot;
            AND &quot;AS AVAILABLE&quot; BASIS, AND LUGATH MAKES NO WARRANTY
            THAT THE PLATFORM SERVICES OR LUGATH CONTENT ARE COMPLETE, SUITABLE
            FOR CUSTOMER&#39;S PURPOSE, OR ACCURATE. ON BEHALF OF ITSELF AND ITS
            LICENSORS, LUGATH HEREBY EXPRESSLY DISCLAIMS ANY AND ALL IMPLIED,
            STATUTORY OR OTHER WARRANTIES WITH RESPECT TO THE PLATFORM SERVICES,
            OR THE AVAILABILITY OF THE FOREGOING, INCLUDING, WITHOUT LIMITATION,
            THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE AND NONINFIRNGEMENT. THE ENTIRE RISK AS TO RESULTS OBTAINED
            THROUGH USE OF THE PLATFORM SERVICES RESTS WITH CUSTOMER. LUGATH IS
            NOT RESPONSIBLE OR LIABLE FOR THE DELETION OF OR FAILURE TO STORE
            ANY CUSTOMER DATA AND OTHER COMMUNICATIONS MAINTAINED OR TRANSMITTED
            THROUGH USE OF THE PLATFORM SERVICES. CUSTOMER IS SOLELY RESPONSIBLE
            FOR SECURING AND BACKING UP ITS APPLICATION AND CUSTOMER DATA.
            NEITHER LUGATH NOR ITS SUPPLIERS, WARRANTS THAT THE OPERATION OF THE
            SOFTWARE OR THE PLATFORM SERVICES WILL BE ERROR-FREE OR
            UNINTERRUPTED. NEITHER THE SOFTWARE NOR THE PLATFORM SERVICES ARE
            DESIGNED, MANUFACTURED, OR INTENDED FOR HIGH RISK ACTIVITIES.
          </p>
          <h1>Confidential Information.</h1>
          <br />
          <h2>
            <u> 12.1 Obligations.</u>
          </h2>
          <p>
            The recipient will not disclose the Confidential Information, except
            to Affiliates, employees, agents or professional advisors who need
            to know it and who have agreed in writing (or in the case of
            professional advisors are otherwise bound) to keep it confidential.
            The recipient will ensure that those people and entities use the
            received Confidential Information only to exercise rights and
            fulfill obligations to the other party while using reasonable care
            to keep it confidential.
          </p>
          <br />
          <h2>
            <u> 12.2 Required Disclosure.</u>
          </h2>
          <p>
            Notwithstanding any provision to the contrary in this Agreement, the
            recipient may also disclose Confidential Information to the extent
            required by applicable Legal Process; provided that the recipient
            uses commercially reasonable efforts to: (i) promptly notify the
            other party of such disclosure before disclosing; and (ii) comply
            with the other party&#39;s reasonable requests regarding its efforts
            to oppose the disclosure. Notwithstanding the foregoing, subsections
            (i) and (ii) above will not apply if the recipient determines that
            complying with (i) and (ii) could: (a) result in a violation of
            Legal Process; (b) obstruct a governmental investigation; and/or (c)
            lead to death or serious physical harm to an individual. As between
            the parties, Customer is responsible for responding to all
            third-party requests concerning its use and Customer End Users&#39;
            use of the Services.
          </p>
          <h1>Limitation of Liability.</h1>
          <br />
          <h2>
            <u>13.1 Limitation on Indirect Liability.</u>
          </h2>
          <p>
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, CUSTOMER AGREES
            THAT LUGATH SHALL NOT BE LIABLE TO CUSTOMER FOR ANY DAMAGES ARISING
            OUT OF OR CONNECTED TO CUSTOMER&#39;S USE OF, OR INABILITY TO THE
            PLATFORM SERVICES, INCLUDING, WITHOUT LIMITATION, ANY AND ALL
            DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL,
            EXEMPLARY OR STATUTORY DAMAGES (INCLUDING, WITHOUT LIMITATION, LOSS
            OF BUSINESS, LOSS OR PROFITS, LOSS OF REVENUE, LOSS OF DATA., LOSS
            OF GOODWILL OR FOR ANY COST OF COVER OR COST OF PROCUREMENT OF
            SUBSTITUTE GOODS OR SERVICES), EVEN IF LUGATH HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF THE LEGAL THEORY
            UNDER WHICH DAMAGES ARE SOUGHT, WHETHER IN BREACH OF CONTRACT OR IN
            TORT, INCLUDING NEGLIGENCE.
          </p>
          <h1>Indemnification.</h1>
          <br />
          <h2>
            <u>14.1 By Customer.</u>
          </h2>
          <p>
            Unless prohibited by applicable law, Customer will defend and
            indemnify Lugath and its Affiliates against Indemnified Liabilities
            in any Third-Party Legal Proceeding to the extent arising from: (i)
            any Application, Customer Data or Customer Brand Features; or (ii)
            Customer&#39;s, or Customer End Users&#39;, use of the Platform
            Services in violation of the AUP.
          </p>
          <br />
          <h2>
            <u>14.2 By Lugath.</u>
          </h2>
          <p>
            Lugath will defend and indemnify Customer and its Affiliates against
            Indemnified Liabilities in any Third-Party Legal Proceeding to the
            extent arising solely from an Allegation that use of (a)
            Lugath&#39;s technology used to provide the Platform Services
            (excluding any open source software) infringes or misappropriates
            the third party&#39;s patent, copyright, trade secret, or trademark,
            or (b) any Lugath Brand Feature infringes or misappropriates the
            third party&#39;s patent, copyright, trade secret, or trademark.
          </p>
          <br />
          <h2>
            <u>14.3 Exclusions.</u>
          </h2>
          <p>
            This <strong> Section 14 </strong>will not apply to the extent the
            underlying Allegation arises from:
          </p>
          <p>
            a. the indemnified party&#39;s breach of these Terms and Conditions;
          </p>
          <p>
            b. modifications to the indemnifying party&#39;s technology or Brand
            Features by anyone other than the indemnifying party;
          </p>
          <p>
            c. combination of the indemnifying party&#39;s technology or Brand
            Features with materials not provided by the indemnifying party; or
          </p>
          <p>
            d. use of non-current or unsupported versions of the Platform
            Services or Brand Features;
          </p>
          <br />
          <h2>
            <u>14.4 Conditions.</u>
          </h2>
          <p>
            <strong>
              <u> Sections 14.1 and 14.2 </u>
            </strong>
            will apply only to the extent:
          </p>
          <p>
            a. The indemnified party has promptly notified the indemnifying
            party in writing of any Allegation(s) that preceded the Third-Party
            Legal Proceeding and cooperates reasonably with the indemnifying
            party to resolve the Allegation(s) and Third-Party Legal Proceeding.
            If breach of this
            <strong>
              <u> Section 14.4(a) </u>
            </strong>
            prejudices the defense of the Third-Party Legal Proceeding, the
            indemnifying party&#39;s obligations under Section 14.1 or 14.2 (as
            applicable) will be reduced in proportion to the prejudice.
          </p>
          <p>
            b. The indemnified party tenders sole control of the indemnified
            portion of the Third-Party Legal Proceeding to the indemnifying
            party, subject to the following: (i) the indemnified party may
            appoint its own non-controlling counsel, at its own expense; and
            (ii) any settlement requiring the indemnified party to admit
            liability, pay money, or take (or refrain from taking) any action,
            will require the indemnified party&#39;s prior written consent, not
            to be unreasonably withheld, conditioned, or delayed.
          </p>
          <br />
          <h2>
            <u>14.5 Remedies.</u>
          </h2>
          <p>
            a. If Lugath reasonably believes the Platform Services might
            infringe a third party&#39;s Intellectual Property Rights, then
            Lugath may, at its sole option and expense: (a) procure the right
            for Customer to continue using the Platform Services; (b) modify the
            Platform Services to make them non-infringing without materially
            reducing their functionality; or (c) replace the Platform Services
            with a non-infringing, functionally equivalent alternative.
          </p>
          <p>
            b. If Lugath does not believe the remedies in
            <strong>
              <u> Section 14.5(a) </u>
            </strong>
            are commercially reasonable, then Lugath may suspend or terminate
            Customer&#39;s use of the impacted Platform Services.
          </p>
          <br />
          <h2>
            <u>14.6 Sole Rights and Obligations.</u>
          </h2>
          <p>
            Without affecting either party&#39;s termination rights, this{" "}
            <strong> Section 14 </strong>states the parties&#39; only rights and
            obligations with respect to third party&#39;s Intellectual Property
            Rights Allegations and Third-Party Legal Proceedings.
          </p>
          <h1>Miscellaneous.</h1>
          <br />
          <h2>
            <u>15.1 Notices.</u>
          </h2>
          <p>
            All notices shall be sent by email. The email address for notices
            sent to Customer shall be the address given at the time the Customer
            subscribes to the Platform Services. The email address for notices
            being sent to Lugath&#39;s Legal Department is{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
            . Notice will be treated as given on receipt as verified by written
            or automated receipt or by electronic log (as applicable).
          </p>
          <br />
          <h2>
            <u>15.2 Assignment.</u>
          </h2>
          <p>
            Neither party may assign any part of these Terms and Conditions
            without the written consent of the other (such consent not to be
            unreasonably withheld, conditioned or delayed), except to an where:
            (a) the assignee is succeeding to the business and assets of the
            assignor and has agreed in writing to be bound by the terms of these
            Terms and Conditions; and (b) the assigning party has notified the
            other party of the assignment.
          </p>
          <br />
          <h2>
            <u>15.3 Force Majeure.</u>
          </h2>
          <p>
            Neither party will be liable for failure or delay in performance to
            the extent caused by circumstances beyond its reasonable control.
          </p>
          <br />
          <h2>
            <u>15.4 No Agency.</u>
          </h2>
          <p>
            These Terms and Conditions and any applicable subscription terms do
            not create any agency, partnership or joint venture between the
            parties.
          </p>
          <br />
          <h2>
            <u>15.5 No Waiver.</u>
          </h2>
          <p>
            Neither party will be treated as having waived any rights by not
            exercising (or delaying the exercise of) any rights under these
            Terms and Conditions.
          </p>
          <br />
          <h2>
            <u>15.6 Severability.</u>
          </h2>
          <p>
            If any terms of these Terms and Conditions is invalid, illegal, or
            unenforceable, the rest of the Agreement will remain in effect.
          </p>
          <br />
          <h2>
            <u>15.7 No Third-Party Beneficiaries.</u>
          </h2>
          <p>
            These Terms and Conditions do not confer any benefits on any third
            party unless it expressly states that it does.
          </p>
          <br />
          <h2>
            <u>15.8 Equitable Relief.</u>
          </h2>
          <p>
            Nothing in these Terms and Conditions will limit either party&#39;s
            ability to seek equitable relief.
          </p>
          <br />
          <h2>
            <u>15.9 Turkey Governing Law.</u>
          </h2>
          <p>
            ALL CLAIMS ARISING OUT OF OR RELATING TO THE PLATFORM SERVICES WILL
            BE GOVERNED BY ISTANBUL COURTS LAW, AND WILL BE LITIGATED
            EXCLUSIVELY IN THE STATE COURTS OF THE ISTANBUL,TURKEY; THE PARTIES
            CONSENT TO PERSONAL JURISDICTION IN THOSE COURTS.
          </p>
          <br />
          <h2>
            <u>15.10 Amendments.</u>
          </h2>
          <p>
            Except as set forth in <strong> Section 1.9,</strong> any amendment
            must be in writing, signed by both parties, and expressly state that
            it is amending these Terms and Conditions and any applicable
            subscription terms
          </p>
          <br />
          <h2>
            <u>15.11 Survival.</u>
          </h2>
          <p>
            The following Sections will survive expiration or termination of
            these Terms and Conditions:
            <strong> 5, 11, 12 13 and 14</strong>.
          </p>
          <br />
          <h2>
            <u>15.12 Entire Agreement.</u>
          </h2>
          <p>
            These Terms and Conditions and the applicable subscription terms set
            out all terms agreed between the parties and supersedes all other
            agreements between the parties relating to its subject matter. In
            entering into these Terms and Conditions and the applicable
            subscription terms, neither party has relied on, and neither party
            will have any right or remedy based on, any statement,
            representation or warranty (whether made negligently or innocently),
            except those expressly set out in these Terms and Conditions and the
            applicable subscription terms.
          </p>
          <br />
          <h2>
            <u> 15.13 Definitions.</u>
          </h2>
          <p>
            Capitalized terms not otherwise defined herein shall have the
            meanings set forth below.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Account</strong>{" "}
            </em>
            &quot; means Customer&#39;s Lugath account.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Access Keys</strong>{" "}
            </em>
            &quot; means an alphanumeric key that is uniquely associated with
            Customer. To initiate a session with the Lugath Platform Services,
            the Application must pass these Keys. This may be Integration Keys.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Affiliate</strong>{" "}
            </em>
            &quot; means any entity that directly or indirectly Controls, is
            Controlled by, or is under common Control with a party.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Allegation</strong>{" "}
            </em>
            &quot; means an unaffiliated third party&#39;s allegation.
          </p>
          <p>
            ·&quot;
            <em>
              <strong>Application(s)</strong>
            </em>
            &quot; means any web or other application Customer creates using the
            Platform Services, including any source code written by Customer to
            be used with the Platform Services.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>AUP</strong>{" "}
            </em>
            &quot; means the acceptable use policy set forth here for the
            Platform Services as presented in the <u> Exhibit A</u>.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Brand Features</strong>{" "}
            </em>
            &quot; means the trade names, trademarks, service marks, logos,
            domain names, and other distinctive brand features of each party,
            respectively, as secured by such party from time to time.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Confidential Information</strong>{" "}
            </em>
            &quot; means information that one party (or an Affiliate) discloses
            to the other party, and which is marked as confidential or would
            normally under the circumstances be considered confidential
            information. It does not include information that is independently
            developed by the recipient, is rightfully given to the recipient by
            a third party without confidentiality obligations, or becomes public
            through no fault of the recipient. Subject to the preceding
            sentence, Customer Data is considered Customer&#39;s Confidential
            Information.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Control</strong>{" "}
            </em>
            &quot; means control of greater than fifty percent of the voting
            rights or equity interests of a party.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Customer Data</strong>{" "}
            </em>
            &quot; means content provided to Lugath by Customer (or at its
            direction) via the Platform Services under the Account, as well as
            data obtained by processing of such content with Lugath Platform
            Services or Third-Party Services.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Customer End Users</strong>{" "}
            </em>
            &quot; means the individuals Customer permits to use the
            Application.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Documentation</strong>{" "}
            </em>
            &quot; means the Lugath documentation (as may be updated from time
            to time) in the form generally made available by Lugath to its
            customers for use with the Platform Services.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Emergency Security Issue</strong>{" "}
            </em>
            &quot; means either: (a) Customer&#39;s or Customer End Users&#39;
            use of the Platform Services in violation of the AUP, which could
            disrupt: (i) the Platform Services; (ii) other customers&#39; or
            their customer end users&#39; use of the Platform Services; or (b)
            the Lugath network or servers used to provide the Platform Services
            or unauthorized third party access to the Platform Services.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Feedback</strong>{" "}
            </em>
            &quot; means feedback or suggestions about the Platform Services
            provided to Lugath by Customer.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>License Fees</strong>{" "}
            </em>
            &quot; means the applicable license fees for the Platform Services
            and any applicable Taxes.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>High Risk Activities</strong>{" "}
            </em>
            &quot; means uses such as the operation of nuclear facilities, air
            traffic control, or life support systems, where the use or failure
            of the Platform Services could lead to death, personal injury, or
            environmental damage.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>HIPAA</strong>{" "}
            </em>
            &quot; means the Health Insurance Portability and Accountability Act
            of 1996 as it may be amended from time to time, and any regulations
            issued under it.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Indemnified Liabilities</strong>{" "}
            </em>
            &quot; means any (i) settlement amounts approved by the indemnifying
            party; and (ii) damages and costs finally awarded against the
            indemnified party and its Affiliates by a court of competent
            jurisdiction.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Integration Keys</strong>{" "}
            </em>
            &quot; provides the full access to Platform Services.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Intellectual Property Rights</strong>{" "}
            </em>
            &quot; means current and future worldwide rights under patent,
            copyright, trade secret, trademark, and moral rights laws, and other
            similar rights.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Legal Process</strong>{" "}
            </em>
            &quot; means a data disclosure request made under law, governmental
            regulation, court order, subpoena, warrant, governmental regulatory
            or agency request, or other valid legal authority, legal procedure,
            or similar process.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Membership Information Form</strong>{" "}
            </em>
            &quot; means a document that describes the membership details like
            character limit, membership start - end date, number of account or
            access keys, package fee and package type.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Platform Services</strong>{" "}
            </em>
            &quot; means the Lugath RESTful API, Lugath Portal, Lugath Editor
            and data provided via the API.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Software</strong>{" "}
            </em>
            &quot; means any downloadable tools, software development kits or
            other such proprietary computer software provided by Lugath in
            connection with the Platform Services, which may be downloaded by
            Customer, and any updates Lugath may make to such Software from time
            to time.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Taxes</strong>{" "}
            </em>
            &quot; means any duties, customs fees, or taxes (other than
            Lugath&#39;s income tax) associated with the purchase of the
            Services, including any related penalties or interest.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Third-Party Legal Proceeding</strong>{" "}
            </em>
            &quot; means any formal legal proceeding filed by an unaffiliated
            third party before a court or government tribunal (including any
            appellate proceeding).
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Trademark Guidelines</strong>{" "}
            </em>
            &quot; means Lugath&#39;s Guidelines for Third Party Use of Lugath
            Brand Features, available by request to{" "}
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
            .
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Personal Data Protection Law No. 6698</strong>{" "}
            </em>
            &quot; is the law enacted in the Republic of Turkey within the scope
            of the protection of personal data and the confidentiality of
            private life.
          </p>
          <p>
            ·&quot;
            <em>
              {" "}
              <strong>Regulation on Personal Health Data</strong>{" "}
            </em>
            &quot; is a regulation created for the protection and regulation of
            health data of individuals and issued in the Republic of Turkey in
            addition to the protection of personal data and the privacy of
            private life.
          </p>
          <h1>EXHIBIT A</h1>
          <br />
          <h2>
            <u>LUGATH API PLATFORM ACCEPTABLE USE POLICY</u>
          </h2>

          <p>
            Use of the Platform Services is subject to this Acceptable Use
            Policy. Capitalized terms have the meaning stated in the applicable
            agreement between Customer and Lugath.
          </p>

          <p>
            Customer agrees not to, and not to allow third parties to use the
            Platform Services:
          </p>
          <p>
            · to violate, or encourage the violation of, the legal rights of
            others (for example, this may include allowing Customer End Users to
            infringe or misappropriate the intellectual property rights of
            others in violation of the Digital Millennium Copyright Act);
          </p>
          <p>· to engage in, promote or encourage illegal activity;</p>
          <p>
            · for any unlawful, invasive, infringing, defamatory or fraudulent
            purpose (for example, this may include phishing, creating a pyramid
            scheme or mirroring a website);
          </p>
          <p>
            · to intentionally distribute viruses, worms, Trojan horses,
            corrupted files, hoaxes, or other items of a destructive or
            deceptive nature;
          </p>
          <p>
            · to interfere with the use of the Platform Services, or the
            equipment used to provide the Platform Services, by customers,
            authorized resellers, or other authorized users;
          </p>
          <p>
            · to disable, interfere with or circumvent any aspect of the
            Platform Services;
          </p>
          <p>
            · to generate, distribute, publish or facilitate unsolicited mass
            email, promotions, advertisements or other solicitations (&quot;
            <em>
              {" "}
              <strong>spam</strong>{" "}
            </em>
            &quot;); or
          </p>
          <p>
            · to use the Platform Services, or any interfaces provided with the
            Platform Services, to access any other Lugath product or service in
            a manner that violates the terms of service of such other Lugath
            product or service.
          </p>
          <p>
            · to engage in scraping or other automated collection of information
            from the Lugath website.
          </p>
          <h1>EXHIBIT B</h1>
          <br />
          <h2>
            <u>DATA PROCESSING</u>
          </h2>
          <p>
            This document describes what Customer Data is received by Lugath,
            how it is transmitted, processed, and stored.
          </p>
          <br />
          <h2>
            <u>1. Types of Data Transit</u>
          </h2>
          <p>
            There are two places where Customer data cross the perimeter of
            Lugath network (in case of the Hosted Services):
          </p>
          <p>
            1. Data transit from Customer to Lugath (sending the requests and
            retrieving the data processing results)
          </p>
          <p>
            2. Data transit from Lugath to Third-Party Service Providers (such
            as Machine Translation services)
          </p>
          <p>
            In both places, the data is protected using industry-standard
            encryption.
          </p>
          <p>
            <strong>Note:</strong> based on customer request, Lugath may provide
            dedicated endpoints for customer requests, included in the
            Customer&#39;s Virtual Private Network. There also may be separate
            endpoints for data processing and account management APIs.
          </p>
          <br />
          <h2>
            <u>2. Types of Data Storage</u>
          </h2>
          <p>
            There are the following types of storage where we may store the user
            data:
          </p>
          <p>1. Short-term storage in memory</p>
          <p>
            2. Customer-Lugath request logs (persistent). Includes request
            metadata and is used for billing purposes and to calculate user
            statistics. It can be deleted by a user's request.
          </p>
          <p>
            3. Lugath-Provider request logs (persistent). Contains request
            metadata and detailed request data for debugging purposes (optional
            requested by the user, see API documentation). Can be deleted by a
            user request.
          </p>
          <p>
            4. Lugath Customer Database. All data relating to the Use of a
            Customer's Platform Services is held here and protected by
            industry-standard encryption. It can be deleted by a user's request.
          </p>
          <p>
            5. Cloud File System. Files and translated versions sent for
            translation during the Customer's Use of the Platform Services. It
            is stored with customer-specific access authorization with
            industry-standard encryption. It can be deleted by a user request.
          </p>
          <p>
            6. Credential manager (Permanent storage of customer credentials to
            third-party services protected by industry standard encryption)
          </p>
          <p>
            Currently, we use a single location to store all the Customer Data
            (US West 2 region (Oregon) in the Amazon AWS cloud). We may switch
            the Cloud Computing vendor to maintain availability as necessary,
            provided that the vendor maintains the same high level of security
            (i.e. Azure, Google Cloud). We also may deploy separate instances of
            the service where appropriate, provided that the contract covers the
            setup and support.
          </p>
          <br />
          <h2>
            <u>3. Types of Customer Data</u>
          </h2>

          <p>Lugath identifies five types of Customer Data:</p>
          <p>
            1. Customer contact information associated with Lugath account
            (name, phone, email address etc.)
          </p>
          <p>
            2. Customer request payload and response (e.g. text to be translated
            and translation results).
          </p>
          <p>
            3. Customer credentials to 3rd party services (e.g. service account
            for Google AutoML Translate connected to Lugath)
          </p>
          <p>4. Customer request metadata (usage logs and statistics)</p>
          <p>5. Lugath API Keys provided to Customer.</p>
          <p>
            All Customer Data stored persistently is encrypted, accessible only
            to the user account that owns the data and the Lugath employees,
            which is covered with proper NDAs. More details on what user data is
            stored at which storage below.
          </p>
          <br />
          <h2>
            <u> 3.1 Customer Contact Information</u>
          </h2>
          <p>
            The Customer Contact Information is stored in the no-SQL database
            (MongoDB) and accessible to Customer and to authorized Lugath
            employees (Customer Service, Account Managers). Also, this data is
            stored in our CRM system (Hubspot) and document management systems
            (Google Drive, Docusign).
          </p>
          <br />
          <h2>
            <u>3.2 Customer Request Payload and Response</u>
          </h2>
          <p>
            The Customer Request payload is stored temporarily (in memory) for
            milliseconds between receiving Customer API request and sending a
            request to the vendor. There are two cases when it may be stored
            longer:
          </p>
          <p>
            1. In order to be prepared in case of a debugging and legal
            reporting request, it is stored in both customer and provider
            request logs in all cases.
          </p>
          <p>
            2. Customer use Lugath API, Lugath Portal or Lugath Editor. In this
            case, the input data is stored in the Lugath database and can be
            accessed by the customer and deleted at any time.
          </p>
          <p>
            The Customer Request Response may include both results of the
            successful data processing and error messages from a Third-Party
            Services. It is stored temporarily (in memory) for milliseconds
            between receiving an answer from an external service and sending the
            result to the clients. There are two cases when it may be stored
            longer:
          </p>
          <p>
            1. In order to be prepared in case of a debugging and legal
            reporting request, it is stored in both customer and provider
            request logs in all cases.
          </p>
          <p>
            2. Customer use Lugath API, Lugath Portal or Lugath Editor. In this
            case, the input data is stored in the Lugath database and can be
            accessed by the customer and deleted at any time.
          </p>
          <br />
          <h2>
            <u>3.3 Customer credentials to 3rd party services</u>
          </h2>
          <p>
            The Customer credentials to 3rd party services are stored
            temporarily (in memory) for milliseconds between receiving Customer
            request and sending a request to the vendor unless Customer has
            explicitly saved them in Lugath Credentials Manager (necessary to
            use Third-Party Services with complex authentication protocols, such
            as Google AutoML).
          </p>
          <br />
          <h2>
            <u>3.4 Customer Request Metadata</u>
          </h2>
          <p>
            The Customer Request Metadata is everything contained in the request
            except the Customer Request Payload, Response, and Customer
            Credentials to Lugath and Third-Party Services. Additionally, the
            metadata includes metadata derived from the payload. For Machine
            Translation, the derived metadata is the size of the text and the
            detected language. The request metadata is securely stored in Lugath
            logs for requests from Customer to Lugath server.
          </p>
          <p>
            User credentials in the Lugath Credentials Manager (Keycloak) are
            stored persistently in the encrypted form. User has full control
            over them via the Lugath Console.
          </p>
          <br />
          <h2>
            <u>3.5 Lugath API Keys provided to Customer</u>
          </h2>
          <p>
            Lugath API Keys provided to the Customer are securely stored in the
            Lugath API Gateway and accessible only to the customer and
            authorized Lugath employees (Account Managers).
          </p>
          <br />
          <h2>
            <u>4. Data Separation</u>
          </h2>
          <p>
            In order to secure customer data, we provide a separation on the
            system level between:
          </p>
          <p>
            · the business-critical Customer Data (request payload, response,
            Lugath API keys, and third-party credentials) and
          </p>
          <p>
            · data, required for Lugath business operation and customer support
            (request metadata).
          </p>
          <p>This separation is also maintained in security policies:</p>
          <p>
            · client business-critical data may be stored only by explicit
            consent of the client for debug purposes and immediately deleted
            afterward
          </p>
          <p>
            · authorized personnel may access client API key and perform
            requests with the client API key only by consent of the client
          </p>
          <p>All the above exclusions are logged, monitored, and audited.</p>
        </div>
      </div>
    </div>
  );
}
