import React, { useState } from "react";
import { ReactComponent as ArrowBackward } from "../../Assets/Icons/arrow-backward.svg";
import { Link } from "react-router-dom";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function CompactHeader({
  data,
  options = {
    theme: "light",
    subdesc: false /* { content:Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric", }).format(new Date(1669018587000)) } */,
    title: "left",
    backBtn: false /* {label:"All Updates", "path": "/news"} */,
  },
}) {
  const { getTranslatableContent, Trans, getRawKey, t } =
    useTranslationEncapsulated();
  return (
    <section className="compact-header__wrapper js-section_nav-dark">
      <div className="compact-header">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="compact-header__header"> {getTranslatableContent(data?.title)} </div>
              <div className="compact-header__desc"> {getTranslatableContent(data?.description)} </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
