import React, { useState } from "react";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTABtnWrapper from "../../Components/CTABtnWrapper";
import Image from "../../Components/Image";
import "./index.scss";

export default function SecurityHeader({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
  },
  pageName = "Security",
  id
}) {
  const { getTranslatableContent, Trans, t } = useTranslationEncapsulated();
  const cta = {};
  return (
    <section id={id} className="security-header js-section_nav-dark">
      <div className="container mx-auto">
        <div className="security-header__wrapper">
          <div className="security-header__inner">
            <div className="parent">
              <div className="div1 security-header__title__wrapper">
                <div className="security-header__title">
                  {getTranslatableContent("%Pages.Security.Title%")}
                </div>{" "}
              </div>
              <div className="div2 security-header__desc__wrapper">
                <div className="security-header__desc">
                  {getTranslatableContent("%Pages.Security.Description%")}
                </div>{" "}
                <div className="security-header__cta">
                  {data?.cta?.map((c, i) => (
                    <CTABtnWrapper
                      options={{
                        formPopupTheme: "light",
                      }}
                      children={<button>{getTranslatableContent(c?.label)}</button>}
                      data={{
                        ...c,
                        ...{ pageName: pageName ? pageName : "" },
                      }}
                    ></CTABtnWrapper>
                  ))}
                </div>{" "}
              </div>
              <div className="div3">
                <div className="security-header__card security-header__card--cyan ">
                  <Image
                    width={128}
                    height={128}
                    fileName={"Assets/Images/security-bg.svg"}
                  />
                </div>
              </div>
              <div className="div4">
                <div className="security-header__card">
                  <Image
                    width={64}
                    height={64}
                    fileName={"Assets/Images/customer-siemens.svg"}
                  />
                </div>
              </div>
              <div className="div5">
                <div className="security-header__card">
                  <Image
                    width={64}
                    height={64}
                    fileName={"Assets/Images/customer-vestel.svg"}
                  />
                </div>
              </div>
              <div className="div6">
                <div className="security-header__card">
                  <Image
                    width={64}
                    height={64}
                    fileName={"Assets/Images/customer-paribu.svg"}
                  />
                </div>
              </div>
              <div className="div7">
                <div className="security-header__card">
                  <Image
                    width={64}
                    height={64}
                    fileName={"Assets/Images/customer-ets.svg"}
                  />
                </div>
              </div>
              <div className="div8">
                <div className="security-header__card">
                  <Image
                    width={64}
                    height={64}
                    fileName={"Assets/Images/customer-arcelik.svg"}
                  />
                </div>
              </div>
              <div className="div9">
                <div className="security-header__card security-header__card--empty"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
