import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { scrollToElement } from "../../Libs/utils";
import CTABtnWrapper from "../CTABtnWrapper";
import { showFormPopUp } from "../../Reducers/settings";
import CustomerLogos from "../CustomerLogos";
import { ReactComponent as PlayIcon } from "../../Assets/Icons/play.svg";
import { useTranslation } from "react-i18next";
import { getOffsetTop } from "../../Libs/utils";

import "./index.scss";

export default function PrimaryTout() {
  const { t, i18n, ready } = useTranslation();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const cta = [
    {
      label: t("Commons.Buttons.GetStarted.Text"),
      action: "register",
      button: (label) => (
        <button
          type="button"
          className="hero-header__content-cta__get-started  text-dark focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold text-lg text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {label}
        </button>
      ),
    },
    {
      label: t("Commons.Buttons.ViewTutorials.Text"),
      action: "view-tutorials",
      button: (label) => (
        <button
          type="button"
          className="hero-header__content-cta__view-demo  text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold text-lg text-center  items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {label}
          <PlayIcon />
        </button>
      ),
    },
  ];
  const onClickExplore = () => {
    scrollToElement("third-section_home", 0, 375);
  };
  const onClickBtn = () => {
    dispatch(showFormPopUp());
  };

  useEffect(() => {
    if (videoRef.current) {
      setTimeout(() => {
        videoRef.current.muted = true;
        videoRef.current.loop = true;
        videoRef.current.play();
      }, 1000);
    }
  }, [videoRef]);

  const onScroll = () => {
    const st = window.pageYOffset;
    const range =
      getOffsetTop(document.getElementsByClassName("shadow-anchor")[0]).top -
      window.innerHeight;
    const opacityVal = 1 - st / (range > 0 ? range : range * -1);
    document.getElementsByClassName("shadow")[0].style.opacity = opacityVal;
    if (opacityVal < -0.2) {
      document
        .getElementsByClassName("mobile-cta--get-access")[0]
        .classList.add("light");
    } else {
      document
        .getElementsByClassName("mobile-cta--get-access")[0]
        .classList.remove("light");
    }
  };

  React.useEffect(() => {
    /*getOffsetTop(document.getElementsByClassName("shadow-anchor")[0]);
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };*/
  }, []);

  return (
    <>
      <div className="bg-hero-header">
        <div className="hero-header">
          <div className="hero-header__content">
            <h1
              dangerouslySetInnerHTML={{ __html: t("Pages.Home.PrimaryTout.title") }}
            />
            <h2
              dangerouslySetInnerHTML={{
                __html: t("Pages.Home.PrimaryTout.description"),
              }}
            />
            <div className="hero-header__content-cta__wrapper">
              {cta.map((c, i) => (
                <CTABtnWrapper
                  key={`cta-btn-key-${i}`}
                  options={{
                    formPopupTheme: "light",
                  }}
                  children={c.button(c.label)}
                  data={{
                    ...c,
                  }}
                ></CTABtnWrapper>
              ))}
            </div>
          </div>
          <CustomerLogos />
        </div>
      </div>
    </>
  );
}
