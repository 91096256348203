import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DefaultLayout from "../Layout/default";
import ErrorLayout from "../Layout/error";
import EmptyLayout from "../Layout/error";
import Solutions from "../Pages/Solutions";
import Features from "../Pages/Features";
import SolutionDetails from "../Pages/Solutions/Details/";
import FeatureDetails from "../Pages/Features/Details/";
import Integrations from "../Pages/Integrations";
import IntegrationDetails from "../Pages/Integrations/Details/";
import Home from "../Pages/Home";
import NotFound from "../Pages/Error/NotFound";
import Sample from "../Pages/Sample";
import Signature from "../Pages/Signature";
import Updates from "../Pages/Updates";
import UpdateDetails from "../Pages/Updates/Details/";
import Glossary from "../Pages/Glossary";
import TermsOfUse from "../Pages/Legal/TermsOfUse/";
import PrivacyPolicy from "../Pages/Legal/PrivacyPolicy/";
import CookiePolicy from "../Pages/Legal/CookiePolicy/";
import SLAPolicy from "../Pages/Legal/SLAPolicy/";
import CookieSettings from "../Pages/Legal/CookieSettings/";
import DynamicPage from "../Pages/Dynamic";
import Blog from "../Pages/Blog";
import BlogDetails from "../Pages/Blog/Details";
import "./index.scss";


export default function RouteConfig(props) {
  const getRoutes = () => {
    return (
      <div className="lugath-marketing-website">
        <Routes>
          <Route
            exact
            path="/:locale?/"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Home {...props} />
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/reports"
            element={
              <DefaultLayout {...props}>
                {" "}
                <DynamicPage {...props} />{" "}
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/:locale?/solutions"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Solutions {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/features"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Features {...props} />{" "}
              </DefaultLayout>
            }
          />
           <Route
            exact
            path="/:locale?/sample"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Sample {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/pricing"
            element={
              <DefaultLayout {...props}>
                {" "}
                <DynamicPage {...props} />
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/integrations"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Integrations {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/integrations/:appName"
            element={
              <DefaultLayout {...props}>
                {" "}
                <IntegrationDetails {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/security"
            element={
              <DefaultLayout {...props}>
                {" "}
                <DynamicPage {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/solutions/:slug"
            element={
              <DefaultLayout {...props}>
                {" "}
                <SolutionDetails {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/features/:slug"
            element={
              <DefaultLayout {...props}>
                {" "}
                <FeatureDetails {...props} />{" "}
              </DefaultLayout>
            }
          />

          <Route
            exact
            path="/:locale?/tutorials"
            element={
              <DefaultLayout {...props}>
                {" "}
                <DynamicPage {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/news"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Updates {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/news/:updateID"
            element={
              <DefaultLayout {...props}>
                {" "}
                <UpdateDetails {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/glossary"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Glossary {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/legal/terms-of-use"
            element={
              <DefaultLayout {...props}>
                {" "}
                <TermsOfUse {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/legal/service-level-policy"
            element={
              <DefaultLayout {...props}>
                {" "}
                <SLAPolicy {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/legal/privacy-policy"
            element={
              <DefaultLayout {...props}>
                {" "}
                <PrivacyPolicy {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/legal/cookie-policy"
            element={
              <DefaultLayout {...props}>
                {" "}
                <CookiePolicy {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/cookie-settings"
            element={
              <DefaultLayout {...props}>
                {" "}
                <CookieSettings {...props} />{" "}
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/faqs"
            element={
              <DefaultLayout {...props}>
                {" "}
                <DynamicPage {...props} />
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/signature"
            element={
              <EmptyLayout {...props}>
                {" "}
                <Signature {...props} />{" "}
              </EmptyLayout>
            }
          />
          <Route
            exact
            path="/:locale?/error"
            element={
              <ErrorLayout {...props}>
                {" "}
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />
          <Route
            exact
            path="/:locale?/blog"
            element={
              <DefaultLayout {...props}>
                {" "}
                <Blog {...props} />
              </DefaultLayout>
            }
          />
          <Route
            exact
            path="/:locale?/blog/:slug"
            element={
              <DefaultLayout {...props}>
                {" "}
                <BlogDetails {...props} />
              </DefaultLayout>
            }
          />
          <Route
            path="*"
            element={
              <ErrorLayout {...props}>
                {" "}
                <NotFound {...props} />{" "}
              </ErrorLayout>
            }
          />
        </Routes>
      </div>
    );
  };

  return getRoutes();
}
