import React, { useState } from "react";
import BrowserFrame from "../BrowserFrame";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import ReactModal from "react-modal";
import VideoPlayer from "../VideoPlayer";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function DemoVideo({ data, pageName }) {
  const { getTranslatableContent, t } = useTranslationEncapsulated();
  const [showModal, setShowModal] = useState(false);

  const onClickAction = () => {
    setShowModal(true);
  };
  
  return (
    <section className="product-feature-video-bg">
      <div className="container mx-auto">
        <div className="product-feature-video__wrapper">
          <h1
            className="product-feature-video__title"
            dangerouslySetInnerHTML={{ __html: getTranslatableContent(data?.title) }}
          />
          <span className="product-feature-video__desc">
            {getTranslatableContent(data?.description)}
          </span>
          <div className="product-feature-video__frame">
            <BrowserFrame
              url={"app.lugath.com"}
              path={data.applicationPath}
              image={data.image}
              onClickAction={onClickAction}
              action={true}
            />
          </div>
          {data.subText ? (
            <span className="product-feature-video__sub">{getTranslatableContent(data.subText)}</span>
          ) : null}
        </div>
        <ReactModal
          style={{
            overlay: {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.75)",
              zIndex: "9999999999",
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "80%",
              height: "80%",
              maxWidth: "1280px",
              maxHeight: "720px",
              transform: "translate(-50%, -50%)",
              background: "#282828",
              overflow: "visible",
              WebkitOverflowScrolling: "touch",
              borderRadius: "0px",
              outline: "none",
              padding: "0",
              border: "0px",
              boxShadow: "0px 0px 125px rgba(0,0,0,0.5)",
            },
          }}
          isOpen={showModal}
          contentLabel={getTranslatableContent(data?.description)}
        >
          <VideoPlayer url={data.videoLink} />
          <button
            className="close-video-btn"
            onClick={() => setShowModal(false)}
          >
            <span className="label">{t("Commons.Buttons.Close.Text")}</span>
            <CloseIcon />
          </button>
        </ReactModal>
      </div>
    </section>
  );
}
