import React from "react";
import "../index.scss";

export default function TermsOfUse() {
  return (
    <div className="policy-page-bg">
      <div className="contract-content container mx-auto">
        <div className="rows-pan-full hidden lg:block"></div>
        <div className="col-span-full lg:col-start-2 lg:col-span-7 ">
          <h1>Privacy Policy</h1>
          <span className="policy-page-date">
            Last Modified/Updated Date . <strong>1 June 2022</strong>
          </span>
        </div>
        <div className="col-span-full lg:col-start-2 lg:col-span-6 paragraph-paddings">
          <p>
            Lugath, Inc. (“Lugath”), as further described in the Scope section
            below, is responsible for collecting, using and storing Personal
            Data transferred from Turkey, the European Union, the United Kingdom
            and Switzerland to the United States, in accordance with{" "}
            <strong>EU-U.S. Privacy Shield Framework</strong> and{" "}
            <strong>
              {" "}
              Personal Data Protection Law (KVKK) No. 6698 of the Republic of
              Turkey.
            </strong>{" "}
            This document summarizes our commitment to the Privacy Shield and
            KVKK Principles <strong>("Principles")</strong> and practices. In
            the event of any conflict between the terms of this Privacy and
            Personal Data Protection Policy and the Privacy Shield or the KVKK
            Principles, the Privacy Shield Principles shall apply to customers
            operating outside the Republic of Turkey and the KVKK shall apply to
            customers operating in the Republic of Turkey.{" "}
            <strong>
              To learn more about the Privacy Shield Framework, please visit the
              U.S. Department of Commerce's website at
            </strong>{" "}
            <a
              className="external-link"
              href="https://www.privacyshield.gov"
              target="_blank"
              rel="noreferrer"
            >
              https://www.privacyshield.gov.
            </a>{" "}
            For more information about KVKK, you can visit{" "}
            <a
              className="external-link"
              href="https://www.kvkk.gov.tr"
              target="_blank"
              rel="noreferrer"
            >
              https://www.kvkk.gov.tr.
            </a>{" "}
          </p>
          <h3 id="scope">Scope</h3>
          <p>
            Welcome to the web site (the "Site") of Lugath, Inc. ( "Lugath",
            "we", "us" and/or "our"). This Site is operated by Lugath and has
            been created to provide information about our company and its
            content and data linking platform and related services (together
            with the Site, the "Service(s)") to our Service visitors ("you",
            "your").
          </p>
          <br />
          <p>
            Lugath complies with the Principles with respect to the Personal
            Data the company receives from its Customers or their Users in the
            European Union, the United Kingdom and Switzerland in connection
            with the use of (i) Lugath website that has been created to provide
            information about our company and its content and data linking
            platform and related services <strong>(“Site”)</strong>; and (ii)
            Lugath’s hosted software applications{" "}
            <strong>(the “Subscription Services”)</strong> and related support
            services <strong>(“Support Services”)</strong>, as well as expert
            services (including professional services, training and
            certification) <strong>(the “Expert Services”)</strong> that we
            provide to Customers. In this Privacy Policy, the Subscription
            Service, Support Services and the Expert Services are collectively
            referred to as the <strong>“Service.”</strong>
          </p>
          <h3 id="definitions">Definitions</h3>
          <p>For the purposes of this Privacy Policy:</p>
          <br />
          <p>
            &quot;<strong>Controller</strong>&quot; means a person or
            organization which, alone or jointly with others, determines the
            purposes and means of the processing of Personal Data.
          </p>
          <br />
          <p>
            &quot;<strong>Customer</strong>&quot; means any entity that
            purchases the Service.
          </p>
          <br />
          <p>
            &quot;<strong>Customer Data</strong>&quot; means the electronic data
            uploaded into the Subscription Service by or for a Customer or its
            Users.
          </p>
          <br />
          <p>
            &quot;<strong>Device</strong>&quot; means a mobile device.
          </p>
          <br />
          <p>
            &quot;<strong>Personal Data</strong>&quot; means any information,
            including Sensitive Data, that is (i) about an identified or
            identifiable individual and (ii) received by Lugath in the U.S. from
            the Turkey, European Union, the United Kingdom or Switzerland in
            connection with the Service.
          </p>
          <br />
          <p>
            &quot;<strong>Processor</strong>&quot; means any natural or legal
            person, public authority, agency or other body that processes
            Personal Data on behalf of a Controller.
          </p>
          <br />
          <p>
            &quot;<strong>Sensitive Data</strong>&quot; means Personal Data
            specifying medical or health conditions, racial or ethnic origin,
            political opinions, religious or philosophical beliefs, trade union
            membership, sex life, the commission or alleged commission of any
            offense, any proceedings for any offense committed or alleged to
            have been committed by the individual or the disposal of such
            proceedings, or the sentence of any court in such proceedings.
          </p>
          <br />
          <p>
            &quot;<strong>User&quot;</strong> means an individual authorized by
            Customer to access and use the Subscription Service.
          </p>
          <br />
          <h3 id="types-of-personal-data-collected">
            Types of Personal Data Collected
          </h3>
          <p>
            Lugath hosts and processes Customer Data, including any Personal
            Data contained therein, at the direction of and pursuant to the
            instructions of Lugath’s Customers. Lugath also collects several
            types of information from our Customers, including:
          </p>
          <ul>
            <li>
              <p>
                Information and correspondence our Customers and Users submit to
                us in connection with Expert Services or other requests related
                to our Service.
              </p>
            </li>
            <li>
              <p>
                Information we receive from our business partners in connection
                with our Customers’ and Users’ use of the Service or in
                connection with services provided by our business partners on
                their behalf, including configuration of the Subscription
                Service.
              </p>
            </li>
            <li>
              <p>
                Information related to Users’ use of the Site, including
                geographic location data and information regarding Users’
                Devices and OS identification, login credentials, language and
                time zone.
              </p>
            </li>
          </ul>
          <p>
            In addition, Lugath collects general information about its
            Customers, including a Customer’s company name and address, credit
            card information, and the Customer representative’s contact
            information <strong>(“General Information”)</strong> for billing and
            contracting purposes. We will keep any information that you provide
            to us until such time as you delete your account with the Lugath
            Services.
          </p>
          <h3 id="purposes-of-collection-and-use">
            Purposes of Collection and Use.
          </h3>
          <p>
            Lugath may use Personal Data submitted by our Customers and Users as
            necessary to provide the Service and Mobile Applications, including
            updating, enhancing, securing and maintaining the Subscription
            Service and Mobile Applications and to carry out Lugath’s
            contractual obligations to its Customers. Lugath also obtains
            General Information in connection with providing the Service and
            maintaining Lugath’s relationships with its Customers.
          </p>
          <h3 id="third-party-disclosures">Third Party Disclosures.</h3>
          <p>
            We may disclose Personal Data that our Customers and Users provide
            to our Service and Mobile Applications:
          </p>
          <ul>
            <li>
              <p>To our subsidiaries and affiliates;</p>
            </li>
            <li>
              <p>
                To contractors, business partners and service providers we use
                to support our Service;
              </p>
            </li>
            <li>
              <p>
                In the event Lugath sells or transfers all or a portion of its
                business or assets (including in the event of a merger,
                acquisition, joint venture, reorganization, dissolution or
                liquidation), in which case Personal Data held by us about our
                Customers will be among the assets transferred to the buyer or
                acquirer;
              </p>
            </li>
            <li>
              <p>If required to do so by law or legal process;</p>
            </li>
            <li>
              <p>
                In response to lawful requests from public authorities,
                including to meet national security, public interest or law
                enforcement requirements.
              </p>
            </li>
          </ul>
          <h3 id="third-party-service-integration">
            Third-Party Service Integration
          </h3>
          <p>
            Our Service allows you to integrate various online third-party
            services ("Third-Party Services"). In order to take advantage of
            this feature, you may need to authenticate, register for or log into
            Third-Party Services through the Service or on the websites of their
            respective providers. When you enable linking between or log in to
            Third-Party Services through the Service, we will collect relevant
            information necessary to enable the Service to access that
            Third-Party Service and your data and content contained within that
            Third-Party Service ("Login Credentials"). We store your Login
            Credentials long enough to enable linking to the Third-Party
            Service.
          </p>
          <br />
          <p>
            When you enable the Service to link content and data between
            Third-Party Services, the Third-Party Services will provide us with
            access to certain information that you may have provided to the
            Third-Party Services, and we will use, store and disclose such
            information in accordance with this Privacy Policy and the rules you
            set to govern the linking. However, please remember that the manner
            in which Third-Party Services use, store and disclose your
            information is governed by the policies of such Third-Party
            Services, and Lugath shall have no liability or responsibility for
            the privacy practices or other actions of any Third-Party Services
            that may be enabled within the Service.
          </p>
          <br />
          <p>
            We may retain certain personally non-identifiable information
            related to the data or content linked between Third-Party Services
            (for example, date sent, link configuration, names of the
            Third-Party Services), for the purpose of improving our Services and
            as described below in the "Non-Identifiable Data" section.
          </p>
          <h3 id="third-party-payment-processor">
            Third-Party Payment Processor
          </h3>
          <p>
            We use a third party payment processor to process payments made to
            us. In connection with the processing of such payments, we do not
            retain any personally identifiable information or any financial
            information such as credit card numbers. Rather, all such
            information is provided directly to our third party processor,
            Iyzico, whose use of your personal information is governed by their
            privacy policy, which may be viewed at{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.iyzico.com/en/privacy-policy"
            >
              https://www.iyzico.com/en/privacy-policy.
            </a>
          </p>
          <h3 id="children">Children</h3>
          <p>
            Lugath does not knowingly collect Personal Data from children under
            the age of 13. If you are under the age of 13, please do not submit
            any Personal Data through the Services. We encourage parents and
            legal guardians to monitor their children's Internet usage and to
            help enforce our Privacy Policy by instructing their children never
            to provide Personal Data on the Services without their permission.
            If you have reason to believe that a child under the age of 13 has
            provided Personal Data to Lugath through the Services, please
            contact us, and we will endeavor to delete that information from our
            databases.
          </p>
          <h3 id="links-to-other-web-sites">Links to Other Web Sites</h3>
          <p>
            This Privacy Policy applies only to the Services. The Services may
            contain links to other web sites not operated or controlled by
            Lugath (the "Third-Party Sites"). The policies and procedures we
            described here do not apply to the Third-Party Sites. The links from
            the Services do not imply that Lugath endorses or has reviewed the
            Third-Party Sites. We suggest contacting those sites directly for
            information on their privacy policies.
          </p>
          <h3 id="security">Security</h3>
          <p>
            Lugath takes reasonable steps to protect the Personal Data provided
            via the Services from loss, misuse, and unauthorized access,
            disclosure, alteration, or destruction. However, no Internet or
            email transmission is ever fully secure or error free. In
            particular, email sent to or from the Services may not be secure.
            Therefore, you should take special care in deciding what information
            you send to us via email. Please keep this in mind when disclosing
            any Personal Data to Lugath via the Internet.
          </p>
          <h3 id="changes-to-lugath-39-s-privacy-policy">
            Changes to Lugath&#39;s Privacy Policy
          </h3>
          <p>
            The Services and our business may change from time to time. As a
            result, at times it may be necessary for Lugath to make changes to
            this Privacy Policy. Lugath reserves the right to update or modify
            this Privacy Policy at any time and from time to time. If we do
            this, we will post the changes to this Privacy Policy on{" "}
            <a
              className="external-link"
              href="https://www.lugath.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.lugath.com/privacy-policy
            </a>{" "}
            and will indicate at the top of this page the date these terms were
            last revised. We will also notify you, either through the Service
            user interface, in an email notification or through other reasonable
            means. Please review this policy periodically, and especially before
            you provide any Personal Data. Any such changes will become
            effective no earlier than fourteen (14) days after they are posted,
            except that changes addressing new functions of the Service or
            changes made for legal reasons will be effective immediately. This
            Privacy Policy was last updated on the date indicated above. Your
            continued use of the Service after the date any such changes become
            effective constitutes your acceptance of the new Privacy Policy.
          </p>
          <h3 id="other-terms">Other Terms</h3>
          <p>
            Your access to and use of the Services is subject to the Terms of
            Service at{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="https://www.lugath.com/terms-of-use"
            >
              https://www.lugath.com/terms-of-use
            </a>
            .
          </p>
          <h3 id="access">Access</h3>
          <p>
            Individuals in the Turkey, European Union, the United Kingdom and
            Switzerland generally have the right to access their Personal Data.
            As an agent processing Personal Data on behalf of its Customers,
            Lugath does not own or control the Personal Data that it processes
            on behalf of its Customers or their Users and does not have a direct
            relationship with the Users whose Personal Data may be processed in
            connection with providing the Service. Since each Customer is in
            control of what information, including any Personal Data, it
            collects from its Users, how that information is used and disclosed,
            and how that information can be changed, Users of the Subscription
            Service should contact the applicable Customer administrator with
            any inquiries about how to access or correct Personal Data contained
            in Customer Data. To the extent a User makes an access or correction
            request to Lugath, we will refer the request to the appropriate
            Lugath Customer and will support such Customer as needed in
            responding to any request.
          </p>
          <br />
          <p>
            To access or correct any General Information Customer has provided,
            the Customer should contact their Lugath account representative
            directly or by using the contact information indicated below.
          </p>
          <br />
          <p>
            <a
              className="external-link"
              href="mailto:support@lugath.com"
              target="_blank"
              rel="noreferrer"
            >
              support@lugath.com
            </a>
          </p>
          <h3 id="choice">Choice</h3>
          <p>
            In accordance with the Principles, Lugath will offer Customers and
            Users choice to the extent it (i) discloses their Personal Data to
            third party Controllers, or (ii) uses their Personal Data for a
            purpose that is materially different from the purposes for which the
            Personal Data was originally collected or subsequently authorized by
            the Customer or User. To the extent required by the Principles,
            Lugath also will obtain opt‑in consent if it engages in certain uses
            or disclosures of Sensitive Data. Unless Lugath offers Customers and
            Users an appropriate choice, Lugath uses Personal Data only for
            purposes that are materially the same as those indicated in this
            Policy.
          </p>
          <br />
          <p>
            Lugath may disclose Personal Data of Customers and Users without
            offering an opportunity to opt out, and may be required to disclose
            the Personal Data, (i) to third‑party Processors that Lugath has
            retained to perform services on its behalf and pursuant to its
            instructions, (ii) if it is required to do so by law or legal
            process, or (iii) in response to lawful requests from public
            authorities, including to meet national security, public interest or
            law enforcement requirements. Lugath also reserves the right to
            transfer Personal Data in the event of an audit or if the company
            sells or transfers all or a portion of its business or assets
            (including in the event of a merger, acquisition, joint venture,
            reorganization, dissolution or liquidation).
          </p>
          <h3 id="liability-for-onward-transfers">
            Liability for Onward Transfers
          </h3>
          <p>
            Lugath complies with the Privacy Shield’s Principle regarding
            accountability for onward transfers. Lugath remains liable under the
            Principles if its onward transfer recipients process Personal Data
            in a manner inconsistent with the Principles, unless Lugath proves
            that it was not responsible for the event giving rise to the damage.
          </p>
          <h3 id="complaints-and-dispute-resolution">
            Complaints and Dispute Resolution
          </h3>
          <p>
            In compliance with the Privacy Shield Principles and KVKK, Lugath
            commits to resolve complaints about our collection or use of your
            personal information. EU individuals with inquiries or complaints
            regarding our Privacy Policy should first contact Lugath at:
          </p>
          <p>
            <a className="external-link" href="mailto:support@lugath.com">
              support@lugath.com
            </a>{" "}
            or by regular mail as indicated below.
          </p>
          <br />
          <p>
            Lugath shall respond within 45 days. Lugath has further committed to
            cooperate with the panel established by the EU data protection
            authorities (DPAs) with regard to unresolved Privacy Shield
            complaints concerning data transferred from the EU.
          </p>
          <br />
          <h3 id="how-to-contact-lugath">How to Contact Lugath</h3>
          <p>
            To keep your Personal Data accurate, current, and complete, please
            contact us as specified below. We will take reasonable steps to
            update or correct Personal Data in our possession that you have
            previously submitted via the Services. Please also feel free to
            contact us if you have any questions about Lugath's Privacy Policy
            or the information practices of the Services.
          </p>
          <br />
          <p>
            All disputes arising out of or relating to these Terms of Service
            will be submitted to the exclusive jurisdiction of a court of
            competent jurisdiction located in Istanbul,Turkey, and each party
            irrevocably consents to such personal jurisdiction and waives all
            objections to this venue.
          </p>
          <br />
          <p>
            To ask questions or comment about this Privacy Policy and our
            privacy practices or if you need to update, change or remove your
            information, contact us with:{" "}
            <a className="external-link" href="mailto:support@lugath.com">
              support@lugath.com
            </a>
          </p>
          <br />
          <p>Lugath, Inc.</p>
          <p>Sanayi Mah. Teknopark Blv. N.1 4C Z08</p>
          <p>Istanbul Teknopark Cube Incubation</p>
          <p>Pendik / Istanbul / Turkey, Istanbul, Marmara 34906</p>
        </div>
      </div>
    </div>
  );
}
