import React from "react";
import Accordion from "../../Components/Accordion";
import { ReactComponent as HelpIcon } from "../../Assets/Icons/help.svg";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function GenericFAQs({ data, pageName }) {
  const { getTranslatableContent, t } = useTranslationEncapsulated();

  return (
    <section
      className={`generic-faqs generic-faqs--${data?.options?.theme} ${
        data?.options?.theme === "dark" ? "" : "js-section_nav-dark"
      }`}
    >
      <div className="w-full">
        <div className="container mx-auto">
          <div className="generic-faqs-content grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full col-start-1 lg:col-span-6 lg:col-start-2">
              {data?.questions ? (
                <div className="category-group" id={`${data.title}`}>
                  <h2>{getTranslatableContent(data.title)}</h2>
                  {data?.questions.map((question) => {
                    return (
                      <Accordion
                        key={question.id}
                        question={getTranslatableContent(question.question)}
                        answer={getTranslatableContent(question.answer)}
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
          <div className="generic-faqs-more">
            <div className="w-full">
                <div className="grid grid-cols-4 lg:grid-cols-8">
                  <div className="col-span-full col-start-1 lg:col-span-6 lg:col-start-2">
                    <div className="generic-faqs-more__message">
                      <span className="generic-faqs-more__message-icon"><HelpIcon /></span>
                      <span className="generic-faqs-more__message-text">{t("Commons.Buttons.CouldNotFindSomething.Text")}  </span>
                      <a href="mailto:support@lugath.com" className="generic-faqs-more__message-link">{t("Commons.Buttons.MessageUs.Text")}</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
