import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/Icons/logo-t-black.svg";
import { ReactComponent as Logo } from "../../Assets/Icons/logo-t.svg";
import "./index.scss";

export default function Sample() {
  return (
    <section className="js-section_nav-dark">
      <div className="not-found-page not-found-page-bg flex flex-col justify-center items-center h-screen w-full">
        <div className="not-found-page__content">
          Hello this is a sample message
        </div>
      </div>
    </section>
  );
}
