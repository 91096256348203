import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { ReactComponent as FileFormat } from "../../Assets/Icons/file-format.svg";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function SupportedFormats({ data, pageName }) {
  const [showFiles, setShowFiles] = useState(false);
  const [activeItem, setActiveItem] = useState(data?.supportedFormatList[0]);
  
  const {getTranslatableContent} = useTranslationEncapsulated();

  const onClickCategoryItem = (clickedItem) => {
    setShowFiles(false);
    setTimeout(() => {
      setActiveItem(clickedItem);
      setTimeout(() => {
        setShowFiles(true);
      }, 100);
    }, 500);
  };

  return (
    <section className="supported-formats-bg js-section_nav-dark">
      <div className="container mx-auto supported-formats__container">
        <div className="supported-formats__wrapper">
          <div className="parent">
            <div className="div1">
              <div className="supported-formats__title-wrapper">
                {data?.title ? (
                  <div
                    className="supported-formats__title"
                    dangerouslySetInnerHTML={{ __html: getTranslatableContent(data.title) }}
                  ></div>
                ) : null}
              </div>
              <div className="supported-formats__menu">
                {data?.supportedFormatList.map((s) => {
                  return (
                    <div
                      className={`supported-formats__menu-item ${
                        s.categoryName === activeItem.categoryName
                          ? "active"
                          : ""
                      }`}
                      onClick={() => onClickCategoryItem(s)}
                    >
                      {getTranslatableContent(s.categoryLabel)}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="div2">
              <VisibilitySensor
                onChange={(isVisible) => {
                  if (isVisible) {
                    setShowFiles(true);
                  } else {
                    setShowFiles(false);
                  }
                }}
              >
                <div className="supported-formats__inner js-supported-files-section">
                  <ul
                    className={`supported-formats__list ${
                      showFiles ? "show" : "hide"
                    }`}
                  >
                    {activeItem?.items?.map((v, index) => (
                      <li
                        class={`supported-formats__list-item__wrapper relative item${
                          index + 1
                        }`}
                      >
                        <div className="supported-formats__list-item">
                          <div className="supported-formats__list-item__icon">
                            <FileFormat />
                          </div>
                          <div className="supported-formats__list-item__extension">
                            {v}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
