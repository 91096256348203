import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowBackward } from "../../Assets/Icons/arrow-backward.svg";
import "./index.scss";
import CONFIG from "../../Services/ConfigService";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTAButtonWrapper from "../CTABtnWrapper";

export default function IntegrationCover({ data, pageName, id }) {
  const { t, getTranslatableContent, getRawKey, Trans} = useTranslationEncapsulated();
  
  useEffect(() => {}, [data]);
  
  return (
    <section className="integrations-details-page__cover ">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="integrations-details-page__cover__title">
                
              <Trans i18nKey={getRawKey(data.title)} components={{
                span_shine: <span className="shine" />
              }} />
              </div>
              <Link
                className="integrations-details-page__cover__content-cta"
                to="/integrations"
              >
                <span className="icon">
                  <ArrowBackward />
                </span>
                <span className="label">{t("Commons.Buttons.AllIntegrations.Text")}</span>
              </Link>
            </div>
            <div className="div2">
              <div className="integrations-details-page__cover__content">
                <div className="integrations-details-page__cover__content-desc">
                <Trans i18nKey={getRawKey(data.description)} components={{
                span_shine: <span className="shine" />
              }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
