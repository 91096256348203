import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Icons/logo-t-black.svg";
import { ReactComponent as Logo } from "../../../Assets/Icons/logo-t.svg";
import "./index.scss";

export default function NotFound() {
  return (
    <section className="js-section_nav-dark">
      <div className="not-found-page not-found-page-bg flex flex-col justify-center items-center h-screen w-full">
        <div className="not-found-page__content">
          <div className="logo ">
            <Logo />
          </div>
          <h1 className="font-eina01 ">404</h1>
          <h6>The requested page could not be found.</h6>

          <Link to="/">
            <button name="login" className="bg-dark">
              <span>Back to Homepage</span>
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
