import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { solutionsData } from "../../../Services/DataService";
import Modular from "../../../Components/Modular";
import "./index.scss";

export default function SolutionDetails() {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activeSolution, setActiveSolution] = useState(false);

  useEffect(() => {
    const findItem = solutionsData.find((p) => p.slug === slug);
    findItem ? setActiveSolution(findItem) : navigate("/error");
  }, [location, slug, navigate]);

  return activeSolution?.modules?.length ? (
    <div className="solution-details-page">
      {activeSolution?.modules
        .filter((m) => m.active !== false)
        .map((module, index) => {
          console.log(module);
          return <Modular key={`module-key-${index}`} module={module} />;
        })}
    </div>
  ) : null;
}
