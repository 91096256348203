import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as ChevronRight } from "../../Assets/Icons/chevron-right.svg";
import { ReactComponent as ChevronLeft } from "../../Assets/Icons/chevron-left.svg";
import { ReactComponent as StarFilled } from "../../Assets/Icons/star-filled.svg";
import { ReactComponent as StarBordered } from "../../Assets/Icons/star-bordered.svg";
import { ReactComponent as TestimonialBackground } from "../../Assets/Images/testimonial-bg.svg";
import { ReactComponent as QuoteBackground } from "../../Assets/Images/quote-bg.svg";
import { testimonialsData } from "../../Services/DataService";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function Testimonials({ data, pageName }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [visibleTestimonials, setVisibleTestimonials] = useState([]);
  const [availableTestimonials] = useState(testimonialsData);
  const {getTranslatableContent} = useTranslationEncapsulated(); 
  
  const onClickNextBtn = () => {
    const index = activeIndex + 1;
    if (index > visibleTestimonials?.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(index);
    }
  };

  const onClickPrevBtn = () => {
    const index = activeIndex - 1;
    if (index < 0) {
      setActiveIndex(visibleTestimonials?.length - 1);
    } else {
      setActiveIndex(index);
    }
  };


  const getVisibleTestimonials = () => {

    const visibleTestimonials = availableTestimonials.filter((a) =>
      data.testimonials.map((t) => t.alias).includes(a.alias)
    );
    
    const updateWithIncomingData = visibleTestimonials.map((p) => {
      const incomingTestimonial = data?.testimonials.find(
        (f) => f.alias === p.alias
      );
      return incomingTestimonial ? { ...p, ...incomingTestimonial } : p;
    })
    setVisibleTestimonials(updateWithIncomingData);
  };

  useEffect(() => {
    if (data?.testimonials?.length) {
      getVisibleTestimonials();
    } else {
      setVisibleTestimonials(testimonialsData)
    }
  }, [data, availableTestimonials]);

  return (
    <section className="bg-testimonials js-section_nav-dark">
      <span className="bg-testimonials-icon">
        <QuoteBackground />
      </span>
      <div className="testimonial-section container mx-auto">
        <h1 className="testimonial-section__title">
          That’s why <br /> our clients loves{" "}
          <span style={{ color: "#aaa" }}>lugath </span>
        </h1>
        <div className="testimonial-section__content">
          <div className="testimonials-slider">
            {visibleTestimonials?.map((t, i) => (
              <div
                key={`testimonials-key-${i}`}
                className="testimonials-slide"
                style={{ display: activeIndex === i ? "flex" : "none" }}
              >
                <div className="testimonials-testimonial">
                  <div className="testimonials-rating">
                    <div className="testimonials-rating__icons">
                      {[1, 2, 3, 4, 5].map((i) =>
                        t.rating >= i ? (
                          <StarFilled
                            key={`icon-key-${i}`}
                            style={{ fill: "orange" }}
                          />
                        ) : (
                          <StarFilled
                            key={`icon-key-${i}`}
                            style={{ fill: "#ccc" }}
                          />
                        )
                      )}
                    </div>
                    <div className="testimonials-rating__label">
                      <span style={{ color: "#282828", paddingRight: "4px" }}>
                        {t.rating}
                      </span>
                      <span style={{ color: "#ccc", paddingLeft: "6px" }}>
                        / 5
                      </span>
                    </div>
                  </div>
                  <blockquote>{getTranslatableContent(t.testimonial)}</blockquote>
                  <p className="testimonials-author">
                    <span className="testimonials-author-name">{getTranslatableContent(t.author)}</span>
                    <span className="testimonials-author-title">{getTranslatableContent(t.title)}</span>
                    <span className="testimonials-author-brand">{getTranslatableContent(t.brand)}</span>
                  </p>
                </div>

                <div className="testimonials-slider-img">
                  <TestimonialBackground />
                </div>
              </div>
            ))}
            <div
              className="testimonials-buttons-wrapper"
              style={
                visibleTestimonials?.length > 1
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <div className="testimonials-buttons" onClick={onClickNextBtn}>
                <div className="testimonials-previous" onClick={onClickPrevBtn}>
                  <ChevronLeft />
                </div>
                <div className="testimonials-next">
                  <ChevronRight />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
