import React from "react";
import SolutionHeader from "../SolutionHeader";
import SolutionTouts from "../SolutionTouts";
import TextToutWithImage from "../TextToutWithImage";
import TextTout from "../TextTout";
import Testimonials from "../Testimonials";
import TextToutWithList from "../TextToutWithList";
import SecurityHeader from "../SecurityHeader";
import StaticPageHeader from "../StaticPageHeader";
import CompactHeader from "../CompactHeader";
import CategoricalFAQs from "../CategoricalFAQs";
import HeroImage from "../HeroImage";
import Integrations from "../Integrations";
import OtherProfiles from "../OtherProfiles";
import CustomerLogos from "../CustomerLogos";
import FeatureHeader from "../FeatureHeader";
import Packages from "../Packages";
import DemoVideo from "../DemoVideo";
import VideoList from "../VideoList";
import FeatureTouts from "../FeatureTouts";
import FeatureFAQs from "../FeatureFAQs";
import GenericFAQs from "../GenericFAQs";
import ReportList from "../ReportList";
import BetaForm from "../BetaForm";
import SupportedFormats from "../SupportedFormats";
import WorkflowScheme from "../WorkflowScheme";
import SecurityCoverage from "../SecurityCoverage";
import SecurityCerts from "../SecurityCerts";
import IntegrationHeader from "../IntegrationHeader";
import IntegrationCover from "../IntegrationCover";

const modules = {
  IntegrationHeader: (data, options, pageName, id) => (
    <IntegrationHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  IntegrationCover: (data, options, pageName, id) => (
    <IntegrationCover
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  StaticPageHeader: (data, options, pageName, id) => (
    <StaticPageHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  VideoList: (data, options, pageName, id) => (
    <VideoList
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  CompactHeader: (data, options, pageName, id) => (
    <CompactHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  ReportList: (data, options, pageName, id) => (
    <ReportList
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  CategoricalFAQs: (data, options, pageName, id) => (
    <CategoricalFAQs
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SecurityHeader: (data, options, pageName, id) => (
    <SecurityHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SecurityCoverage: (data, options, pageName, id) => (
    <SecurityCoverage
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SecurityCerts: (data, options, pageName, id) => (
    <SecurityCerts
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  CustomerLogos: (data, options, pageName, id) => (
    <CustomerLogos
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  Packages: (data, options, pageName, id) => (
    <Packages
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SolutionHeader: (data, options, pageName, id) => (
    <SolutionHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SolutionTouts: (data, options, pageName, id) => (
    <SolutionTouts
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  TextToutWithImage: (data, options, pageName, id) => (
    <TextToutWithImage
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  TextTout: (data, options, pageName, id) => (
    <TextTout
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  Testimonials: (data, options, pageName, id) => (
    <Testimonials
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  HeroImage: (data, options, pageName, id) => (
    <HeroImage
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  TextToutWithList: (data, options, pageName, id) => (
    <TextToutWithList
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  Integrations: (data, options, pageName, id) => (
    <Integrations
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  OtherProfiles: (data, options, pageName, id) => (
    <OtherProfiles
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  FeatureHeader: (data, options, pageName, id) => (
    <FeatureHeader
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  DemoVideo: (data, options, pageName, id) => (
    <DemoVideo
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  FeatureTouts: (data, options, pageName, id) => (
    <FeatureTouts
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  FeatureFAQs: (data, options, pageName, id) => (
    <FeatureFAQs
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  GenericFAQs: (data, options, pageName, id) => (
    <GenericFAQs
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  BetaForm: (data, options, pageName, id) => (
    <BetaForm
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  SupportedFormats: (data, options, pageName, id) => (
    <SupportedFormats
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
  WorkflowScheme: (data, options, pageName, id) => (
    <WorkflowScheme
      data={{ ...data }}
      options={{ ...options }}
      pageName={pageName}
      id={id}
    />
  ),
};

export default function Modular({ module }) {
  return typeof modules?.[module?.moduleName] === "function"
    ? modules?.[module?.moduleName](
      module?.data ? module?.data : {},
      module?.options ? module?.options : {},
      module?.pageName ? module?.pageName : false,
      module?.id ? module.id : "no-id"
    )
    : null;
}
