import React from "react";
import { v4 as uuidv4 } from "uuid";
import MileStone from "../MileStone";
import CTAButton from "../CTABtnWrapper";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function BetaForm({ data, pageName }) {
  const { getTranslatableContent, Trans, t } = useTranslationEncapsulated();
  debugger;
  return (
    <section className="product-beta-access-bg js-section_nav-dark">
      <div className="container mx-auto product-beta-access__container">
        <div className="product-beta-access__wrapper">
          <div className="product-beta-access__inner">
            <div className="parent">
              <div className="div1">
                <div className="product-beta-access__content-wrapper">
                  <div className="product-beta-access__content-inner">
                    <div className="product-beta-access__content">
                      <div className="product-beta-access__content-title">
                        {t("Commons.Modules.BetaForm.Title")}
                      </div>
                      <div className="product-beta-access__content-desc">
                      <Trans i18nKey="Commons.Modules.BetaForm.Description" components={{
                        span_highlight: <span className="highlight" />,
                        span_highlight_cyan:<span className="highlight-cyan" />
                      }} values={{ pageName:getTranslatableContent(pageName)}} />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div2">
                <div className="product-beta-access__subscriber-wrapper">
                  <div className="product-beta-access__subscriber-inner">
                    <div className="product-beta-access__subscriber">
                      <span className="product-beta-access__subscriber-count">
                        <MileStone
                          duration={0.5}
                          end={
                            data?.subscriberCount ? data?.subscriberCount : 0
                          }
                        />
                      </span>
                      <span className="product-beta-access__subscriber-label">
                       {t("Commons.Modules.BetaForm.AlreadyJoinedCountDesc")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="div3">
                <div className="product-beta-access__form-wrapper">
                  <div className="product-beta-access__form-inner">
                    <div className="product-beta-access__form">
                      {data.cta.map((c, i) => (
                        <CTAButton
                          key={uuidv4()}
                          options={{
                            formPopupTheme: "light",
                          }}
                          children={
                            <button className="product-beta-access__form-btn">
                              {getTranslatableContent(c.label)}
                            </button>
                          }
                          data={{
                            ...c,
                            ...{ pageName: getTranslatableContent(pageName) ? getTranslatableContent(pageName) : "" },
                          }}
                        ></CTAButton>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
