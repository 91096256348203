import React, { useState, useEffect } from "react";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTABtnWrapper from "../CTABtnWrapper";
import Image from "../Image";
import "./index.scss";

export default function ReportList({
  data,
  options = {
    shape: "icon",
    imageMinWidth: "64px",
  },
  pageName
}) {
  const { getTranslatableContent, getRawKey, Trans } = useTranslationEncapsulated();
  
  return (
    <section className="reports-page-content ">
      <div className="reports-page-content__list">
        {data.reports.map((r) => <div className="reports-page-content__list-item__wrapper">
          <div className="container mx-auto">
            <div className="reports-page-content__list-item">
              <div className="reports-page-content__list-item__content">
                <div className="reports-page-content__list-item__content-title">
                  <Trans i18nKey={getRawKey(r.name)} />
                </div>
                <div className="reports-page-content__list-item__content-desc">
                  <Trans i18nKey={getRawKey(r.description)} />
                </div>
                <div className="reports-page-content__list-item__content-cta">
                  {r.cta.map((c, i) => (
                    <CTABtnWrapper
                    options={{
                      formPopupTheme: "dark",
                    }}
                    children={<button>{getTranslatableContent(c?.label)}</button>}
                    data={{
                      ...c,
                      ...{ pageName: getTranslatableContent(pageName) ? getTranslatableContent(pageName) : "" },
                    }}
                  ></CTABtnWrapper>
                  ))}
                </div>
              </div>
                {r?.image ? (
                  <div className="reports-page-content__list-item__image">
                    <Image
                      style={
                        options?.imageMinWidth
                          ? { minWidth: options?.imageMinWidth }
                          : {}
                      }
                      fileName={r.image.source}
                      width={64}
                      height={64}
                    />
                  </div>
                ) : null}
            </div>
          </div>
        </div>)}
        
      </div>
    </section>
  );
}
