import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Image from "../Image";
import TextToutWithList from "../TextToutWithList";
import { featuresData } from "../../Services/DataService";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function FeatureList({
  data,
  options = {
    shape: "icon",
    imageMinWidth: "64px",
  },
}) {
  
  const [featureData, setFeatureData] = useState(false);

  const {getTranslatableContent} = useTranslationEncapsulated(); 

  const transformData = (data) => {
    const items = data
      .map((d) => ({
        slug: d.slug,
        pageName: d.pageName,
        icon: d.icon,
        linkTitle: getTranslatableContent(d?.linkTitle ? d?.linkTitle : "%Commons.Buttons.Discover.Text%"),
        data: d.modules.find((m) => m.moduleName === "FeatureHeader")
          ?.data,
      }))
      .filter((i) => !!i.data).map((i) => {
      return {
        image:
          options.shape === "icon"
            ? `Assets/Icons/${i.icon}`
            : `Assets/Images/Features/${i.data?.icon}`,
        link: `/features/${i.slug}`,
        linkTitle:getTranslatableContent(i?.linkTitle ? i?.linkTitle : "%Commons.Buttons.Discover.Text%"),
        title: i.pageName,
      };
    });
    return items;
  };

  const getTransformedData = () => {
    const defaultData = {
      primaryTitle: data.title,
      secondaryTitle: data.description,
      features: transformData(featuresData),
    };
    return defaultData;
  };

  useEffect(() => {
    setFeatureData(getTransformedData(featuresData));
  }, []);

  return featureData ? (
    <TextToutWithList
      options={{
        theme: "dark",
        box: "dark",
        listItems: 3,
        imageMinWidth: options.imageMinWidth,
        primaryTextAlign: "center",
        secondaryTextAlign: "center",
        padding: "large-both",
      }}
      data={featureData}
    />
  ) : null;
}
