import React from "react";
import _ from "lodash";
import { ReactComponent as ChevronRight } from "../../Assets/Icons/chevron-right.svg";
import CTAButton from "../CTABtnWrapper";
import Image from "../Image";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";


export default function FeatureTouts({ data, pageName }) {
  const { getTranslatableContent } = useTranslationEncapsulated();
  
  return (
    <section className="product-feature-list-bg js-section_nav-dark">
      <div className="container mx-auto product-feature-list__container">
        <div className="product-feature-list__wrapper">
          <div className="product-feature-list__inner">
            <div className="parent">
              <div className="div1">
                <div className="product-feature-list__title-wrapper">
                  <div
                    className="product-feature-list__title"
                    dangerouslySetInnerHTML={{ __html: getTranslatableContent(data?.title)}}
                  />
                </div>
              </div>
              {data?.featureList.map((f, i) => (
                <div key={i} className={f.wrapper}>
                  <div className="product-feature-list__card-wrapper">
                    <div className="product-feature-list__card">
                      <div className="product-feature-list__card-content">
                        <div className="product-feature-list__card-title">
                          {getTranslatableContent(f.title)}
                        </div>
                        <div className="product-feature-list__card-desc">
                          {getTranslatableContent(f.description)}
                        </div>
                        {f.image ? (
                          <div className="product-feature-list__card-image">
                            <Image
                              fileName={f.image.source}
                              width={f.image.width}
                              height={f.image.height}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="product-feature-list__link-wrapper">
          {data.cta.map((c, i) => (
            <CTAButton
              id={c.id}
              key={c.id}
              options={{
                formPopupTheme: "light",
                utmLabel: "js_try_all_features_14_days_btn",
              }}
              children={
                <button 
                  id={c.id}
                  key={c.id + "_children"} className="product-feature-list__link">
                  <span className="product-feature-list__link-desc">
                    {getTranslatableContent(c?.label)}
                  </span>
                  <span className="product-feature-list__link-icon">
                    <ChevronRight />
                  </span>
                </button>
              }
              data={{ ...c, ...{ pageName: getTranslatableContent(pageName) } }}
            ></CTAButton>
          ))}
        </div>
      </div>
    </section>
  );
}
