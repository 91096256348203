import updates from "./Data/Exports/updatesData.json";
import features from "./Data/Exports/featuresData.json";
import solutions from "./Data/Exports/solutionsData.json";
import apps from "./Data/Exports/appData.json";
import staticPages from "./Data/Exports/staticPagesData.json";
import { seo } from "./Data/seo";
import menu from "./Data/Exports/menuData.json";
import integrations from "./Data/Exports/integrationsData.json";
import glossary from "./Data/Exports/glossaryData.json";
import profiles from "./Data/Exports/profilesData.json";
import testimonials from "./Data/Exports/testimonialsData.json";

export const menuData = menu;
export const updatesData = updates;
export const featuresData = features;
export const solutionsData = solutions;
export const profilesData = profiles;
export const testimonialsData = testimonials;
export const integrationsData = integrations;
export const appsData = apps;
export const glossaryData = glossary;
export const seoData = seo;
export const staticPagesData = staticPages;
