import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowBackward } from "../../../Assets/Icons/arrow-backward.svg";
import "../index.scss";
import { updatesData } from "../../../Services/DataService";

export default function UpdateDetails() {
  let { updateID } = useParams();
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(false);
  const onClickUpdateItem = (item) => {
    navigate(`/news/${item.slug}`);
  };

  useEffect(() => {
    setActiveItem(updatesData.find((u) => u.slug === updateID));
  }, [updateID]);

  return (
    <div className="updates-page updates-page-bg">
      <section className="updates-page-header js-section_nav-dark">
        <div className="container mx-auto">
          <div className="parent">
            <div className="div1">
              <div className="updates-page-header__title">
                Updates by <span className="shine">lugath</span>
              </div>
              <Link className="updates-page-header__content-cta" to="/news">
                <span className="icon">
                  <ArrowBackward />
                </span>
                <span className="label">All Updates</span>
              </Link>
            </div>
            <div className="div2">
              <div className="updates-page-header__content">
                <div className="updates-page-header__content-desc">
                  {activeItem?.title}
                  {activeItem?.createDate ? (
                    <span className="date">
                      {Intl.DateTimeFormat("en-US", {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      }).format(new Date(activeItem?.createDate))}{" "}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="updates-page-content updates-page-content--bg-light js-section_nav-dark">
        <div className="container mx-auto">
          <div className="updates-page-content__details">
            <div className="updates-page-content__details-content">
              <div
                dangerouslySetInnerHTML={{
                  __html: activeItem?.body,
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
