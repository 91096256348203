import React from "react";
import "../index.scss";

export default function CookiePolicy(props) {
  return (
    <div className="policy-page-bg">
      <div className="contract-content container mx-auto">
        <div className="rows-pan-full hidden lg:block"></div>
        <div className="col-span-full lg:col-start-2 lg:col-span-7 ">
          <h1>Cookie Policy</h1>
          <span className="policy-page-date">
            Last Modified/Updated Date . <strong>14 April 2022</strong>
          </span>
        </div>
        <div className="col-span-full lg:col-start-2 lg:col-span-6 paragraph-paddings">
          <p>
            This cookie policy describes how Lugath (“<strong>Lugath</strong>”)
            uses “cookies” and other similar technologies, in connection with
            our Site and Services. To learn more about Lugath-affiliated
            websites, please see section 4 below. Any capitalized term used and
            not otherwise defined below has the meaning assigned to it in the
            Privacy Policy.
          </p>

          <h3>1. What is a Cookie?</h3>

          <p>
            Cookies are small text files that are stored in a computer's browser
            directory. They help site providers with things like understanding
            how people use a site, remembering a User's login details, and
            storing site preferences.
          </p>

          <h3>2. Does Lugath use Cookies?</h3>

          <p>
            Yes. We use cookies in accordance with our{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/legal/privacy-policy"
            >
              Privacy Policy
            </a>{" "}
            to:
          </p>

          <ul>
            <li>
              <p>
                ensure that our Services function properly,
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                detect and prevent fraud,
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                understand how visitors use and engage with our Site, and
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                analyze and improve Services.
                <br />
              </p>{" "}
            </li>
          </ul>

          <h3>3. Who sets cookies when I use Lugath's Site?</h3>

          <p>There are two main types of cookies that can be set:</p>

          <ul>
            <li>
              <p>
                First party cookies: these cookies are placed and read by Lugath
                directly when you use our Services,
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                Third party cookies: these cookies are not set by Lugath, but by
                other companies, like Google or Facebook, for site analytics
                purposes. See further details below on how to manage these
                cookies.
                <br />
              </p>{" "}
            </li>
          </ul>

          <h3>4. How Lugath Uses Cookies</h3>

          <p>
            Cookies play an important role in helping us provide effective and
            safe Services. Below is a description of the commonly used cookie
            types and the purposes that apply to them. Each section references
            Lugath’s{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Cookie Settings
            </a>
            , where you can find more information about each cookie, and
            exercise your choices.{" "}
          </p>

          <p>
            For Lugath-affiliated websites, you can learn more about cookies by
            visiting those sites directly.
          </p>

          <h3>Necessary Cookies</h3>

          <p>
            Some cookies are essential to the operation of our Site and Services
            and make it usable and secure by enabling basic functions like page
            navigation and access to secure areas of the Site. We use those
            cookies in a number of different ways, including:
          </p>

          <ul>
            <li>
              <p>
                <strong>Authentication</strong>. To remember your login state so
                you don't have to log in as you navigate through our Site and
                dashboard.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>Fraud Prevention and Detection</strong>. Cookies and
                similar technologies that we deploy through our Site help us
                learn things about computers and web browsers used to access the
                Services. This information helps us monitor for and detect
                potentially harmful or illegal use of our Services. For example,
                in order to process payments transactions for our Users, it is
                necessary for Lugath to collect information about the
                transaction and the Customer. To help secure these transactions
                and minimize fraud, we collect additional information through
                the use of cookies and other technologies in helping to identify
                bad actors and prevent them from making fraudulent transactions.
                Customers should check our Users' sites for more information
                about the use of Lugath cookies for fraud detection.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>Security</strong>. To protect user data from
                unauthorized access.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>Functionality</strong>. To keep our Site and Services
                working correctly, like showing you the right information for
                your selected location.
                <br />
              </p>{" "}
            </li>
          </ul>

          <p>
            For more information, please see the{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Authentication
            </a>
            ,{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Fraud Prevention
            </a>
            ,{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Security
            </a>{" "}
            and{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Functionality
            </a>{" "}
            sections in the{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Lugath Cookie Settings Dashboard
            </a>
            . For Lugath-affiliated websites, you can learn more about cookies
            by visiting those sites directly.
          </p>

          <h3>Preference Cookies</h3>

          <p>
            Preference cookies are used by Lugath to remember your preferences
            and to recognize you when you return to our Services.
          </p>

          <p>
            For more information, please see the{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings#preferences"
            >
              Preferences
            </a>{" "}
            section in the Lugath{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Cookie Settings Dashboard
            </a>
            . For Lugath-affiliated websites, you can learn more about cookies
            by visiting those sites directly.
          </p>

          <h3>Analytics Cookies</h3>

          <p>
            Analytics cookies help us understand how visitors interact with our
            Services. We use those cookies in a number of different ways,
            including:
          </p>

          <ul>
            <li>
              <p>
                <strong>Site Features and Services</strong>. To remember how you
                prefer to use our Services so that you don't have to reconfigure
                your settings each time you log into your account.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>To Analyze and Improve Our Services</strong>. To make
                our Site and Services work better for You. Cookies help us
                understand how people reach our Site and our Users' sites. They
                give us insights into improvements or enhancements we need to
                make to our Site and Services.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>
                  Pixel tags (also known as web beacons and clear GIFs)
                </strong>
                . May be used in connection with some Services to, among other
                things, track the actions of Users (such as email recipients),
                measure the success of our marketing campaigns and compile
                statistics about usage of the Services and response rates.
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                <strong>Third Party Analytics</strong>. Through Google Analytics
                in order to collect and analyze information about the use of the
                Services and report on activities and trends. This service may
                also collect information regarding the use of other sites, apps
                and online resources. You can learn about Google's practices on
                the{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://policies.google.com/technologies/partner-sites"
                >
                  Google website
                </a>
                . See further details below on how to manage these cookies.
                <br />
              </p>{" "}
            </li>
          </ul>

          <p>
            For more information, please see the{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Analytics
            </a>{" "}
            section in the Lugath{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Cookie Settings Dashboard
            </a>
            . For Lugath-affiliated websites, you can learn more about cookies
            by visiting those sites directly.
          </p>

          <h3>Advertising Cookies</h3>

          <p>
            We and our service providers will use cookies and similar
            technologies on Lugath.com to direct Lugath ads to you through
            targeted advertisements for Lugath Services on other sites you visit
            and to measure your engagement with those ads.
          </p>

          <p>
            For more information, please see the{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Advertising
            </a>{" "}
            section in the Lugath{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Cookie Settings Dashboard
            </a>
            . For Lugath-affiliated websites, you can learn more about cookies
            by visiting those sites directly.
          </p>

          <h3>5. Can I opt-out?</h3>

          <p>
            Yes. You can opt out of cookies through our{" "}
            <a
              className="external-link"
              target="_blank"
              rel="noreferrer"
              href="/cookie-settings"
            >
              Cookie Settings Dashboard
            </a>
            , with the exception of those cookies that are necessary to provide
            you with our Services. For Lugath-affiliated websites, you can learn
            more about cookies by visiting those sites directly. Your web
            browser may allow you to manage your cookie preferences, including
            to delete and disable Lugath cookies. You can take a look at the
            help section of your web browser or follow the links below to
            understand your options. If you choose to disable cookies, some
            features of our Site or Services may not operate as intended.
          </p>

          <ul>
            <li>
              <p>
                Chrome:{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.google.com/chrome/answer/95647?hl=en"
                >
                  https://support.google.com/chrome/answer/95647?hl=en
                </a>
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                Explorer:{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                >
                  https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                Safari:{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
                >
                  https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
                </a>
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                Firefox:{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
                >
                  https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer
                </a>
                <br />
              </p>{" "}
            </li>

            <li>
              <p>
                Opera:{" "}
                <a
                  className="external-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://help.opera.com/en/latest/web-preferences/#cookies"
                >
                  https://help.opera.com/en/latest/web-preferences/#cookies
                </a>
                <br />
              </p>{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
