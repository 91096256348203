import React, { useState } from "react";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function TextTout({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
  },
}) {
  const {getTranslatableContent,Trans, getRawKey, t} = useTranslationEncapsulated();
  return (
    <section
      className={`text-tout text-tout--${options.theme} ${
        options.theme === "light" ? "js-section_nav-dark" : ""
      }`}
    >
      <div className="container mx-auto">
        <div className="text-tout__wrapper">
          <div className={`${options.title === "left" ? "div1" : "div2"}`}>
            <div
              className={`text-tout__content-title text-tout__content-title--${options.title}`}
            >
              {getTranslatableContent(data.title)}
            </div>
          </div>
          <div
            className={`${options.description === "left" ? "div1" : "div2"}`}
          >
            <div
              className={`text-tout__desc text-tout__desc--${options.description}`}
            >
              {getTranslatableContent(data.description)}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
