import { useTranslation, Trans } from "react-i18next";
import _pick from "lodash/pick";
import CONFIG from "../Services/ConfigService";
import { useEffect } from "react";

const useTranslationEncapsulated = () => {
  const { t, i18n, ready } = useTranslation();
  
  const getTranslatableContent = (string) => {
    if(string && string?.includes("%") && string?.startsWith("%") && string?.endsWith("%")) {
      //i18n?.options?.debug && console.log(string)
      const translatedText =  t(string.replaceAll("%", ""));
      
      if(translatedText.includes("{{")){
        const regex = /\{\{(.*?)\}\}/g;
        const found = translatedText.match(regex);
        if (found?.length) {
         const foundEscapeList = found.map((v) => v.replaceAll("{{", "").replaceAll("}}", ""))
         const test = _pick(CONFIG.VARIABLES, foundEscapeList);
         const interpolatedTranslatedText = t(string.replaceAll("%", ""), test);
         return interpolatedTranslatedText;
        }
        //i18n?.options?.debug && console.log(found.length, found)
        return translatedText;
      
      } else {
        return translatedText;
      }
    } else {
      return string;
    }
  }

  const getRawKey = (string) => {
    if(string && string.length && string?.includes("%") && string?.startsWith("%") && string?.endsWith("%")) {
      //i18n?.options?.debug && console.log(string)
      const translatedText =  t(string.replaceAll("%", ""));
      return translatedText;
    } else {
      return string;
    }
  }

  const isKeyExist = (string) => {
    if(string && string?.includes("%") && string?.startsWith("%") && string?.endsWith("%")) { 
      return i18n.exists(string.replaceAll("%", ""));
    } else {
      return i18n.exists(string);
    }
  }

  useEffect(() => {
    console.log(">>> reConciliate ");
  }, [])

  return {
    getTranslatableContent,
    Trans,
    getRawKey,
    isKeyExist,
    t,
    i18n,
    ready
  };
};

export default useTranslationEncapsulated;
