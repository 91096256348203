import React, { useState } from "react";
import BrowserFrame from "../BrowserFrame";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function HeroImage({
  data,
  options = {
    theme: "dark",
    description: "right",
    title: "left",
    imageAlignment: "bottom",
  },
}) {
  const [showModal, setShowModal] = useState(false);
  const { getTranslatableContent, isKeyExist, Trans, getRawKey } =
    useTranslationEncapsulated();

  const onClickAction = () => {
    setShowModal(true);
  };

  return (
    <section
      className={`hero-image-bg hero-image-bg__${options.imageAlignment}`}
    >
      <div className="container mx-auto">
        <div className="hero-image__wrapper">
          {data.title ? (
            <h1 className="hero-image__title">
              
              {isKeyExist(data.title) ? 
                <Trans
                  i18nKey={getRawKey(data.title)}
                  components={{
                    span_highlightGray: <span className="highlight-gray" />,
                    span_highlightDropbox: (
                      <span className="highlight-dropbox" />
                    ),
                    span_highlightCyan: <span className="highlight-cyan" />,
                  }}
                /> : getTranslatableContent(data.title)
              }
            </h1>
          ) : null}
          {data.description ? (
            <span className="hero-image__desc">{getTranslatableContent(data.description)}</span>
          ) : null}
          <div
            className={`hero-image__frame hero-image__frame__${options.imageAlignment}`}
          >
            <BrowserFrame
              url={"app.lugath.com"}
              path={data.path}
              image={data.image}
              onClickAction={onClickAction}
            />
          </div>
          {data.bottomDescription ? (
            <span className="hero-image__sub">{data.bottomDescription}</span>
          ) : null}
        </div>
      </div>
    </section>
  );
}
