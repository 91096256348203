import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowBackward } from "../../../Assets/Icons/arrow-backward.svg";
import "../index.scss";
import { updatesData } from "../../../Services/DataService";

export default function BlogDetails() {
 

  return (
    <p> blog details</p>
  );
}
