import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "../../Assets/Icons/arrow_outward.svg";
import "./index.scss";
import { updatesData } from "../../Services/DataService";

export default function Blog() {
  const [pageData, setPageData] = useState({});
  const [pageReady, setPageReady] = useState(false);
  const navigate = useNavigate();
  const {locale, slug} = useParams();
  const onClickUpdateItem = (item) => {
    navigate(`/news/${item.slug}`);
  };

  async function test(){
    
    try{
      console.log(slug);
      debugger;
      const pageSlug = "how-to-translate-your-documents-with-lugath";
      const res = await fetch(`https://blog.lugath.com/wp-json/wp/v2/posts?slug=${pageSlug}`);
      const data = await res.json();
      setPageData(data[0]);
      setPageReady(true);

    } catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    test();
  }, [pageData])

 
  return pageReady && pageData ? (
    <div className="blog-page" > 
      {/*<div>{JSON.stringify(pageData)}</div>*/}
      <div 
      dangerouslySetInnerHTML={{__html:JSON.stringify(pageData.content.rendered) }} />
    
    </div>
  ) : null
}

/*
https://blog.lugath.com/wp-json/wp/v2/posts?slug=lugath-partners-with-tenity-supporting-startups
https://blog.lugath.com/wp-json/wp/v2/pages
https://blog.lugath.com/wp-json/wp/v2/navigation
*/