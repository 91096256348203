import { useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "../../Assets/Icons/check-icon.svg";
import { dynamicParse } from "../../Libs/utils";
import "./index.scss";
import SegmentedControl from "../../Components/SegmentedControl";
import MileStone from "../../Components/MileStone";
import CONFIG from "../../Services/ConfigService";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import ReactGA from "react-ga";
import "./index.scss";

export default function Packages({ data, options, pageName }) {
  const { t, Trans } = useTranslationEncapsulated();
  const DEFAULT_PACKAGE = options.packages.default;
  const DEFAULT_PERIOD = options.period.default;
  const DEFAULT_CURRENCY = options.currency.default;
  const RESTRICTED_PACKAGES = options.packages.restricted;
  const memberships = [
    {
      id: "6458e13ac4486803ca8d5af0",
      createDate: 1683546426297,
      updateDate: 1683546426297,
      createdBy: "",
      updatedBy: "",
      charLimit: 10000,
      tmCharLimit: 100000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      packagePrice: {
        amount: 0,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 0.0,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 0.0,
        currency: "TRY",
      },
      membershipType: "FREEMIUM",
      membershipPeriod: "MONTHLY",
      paymentRequired: false,
      extendable: false,
      maxCharLimit: 0,
      maxTmCharLimit: 0,
      maxUserLimit: 0,
      order: 0,
      enabled: true,
    },
    {
      id: "6458e13ac4486803ca8d5af0",
      createDate: 1683546426297,
      updateDate: 1683546426297,
      createdBy: "",
      updatedBy: "",
      charLimit: 10000,
      tmCharLimit: 100000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 0,
        currency: "USD",
      },
      packagePrice: {
        amount: 0,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 0.0,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 0.0,
        currency: "TRY",
      },
      membershipType: "FREEMIUM",
      membershipPeriod: "YEARLY",
      paymentRequired: false,
      extendable: false,
      maxCharLimit: 0,
      maxTmCharLimit: 0,
      maxUserLimit: 0,
      order: 0,
      enabled: true,
    },
    {
      id: "645a64fe1409af6890a60dba",
      createDate: 1683645694111,
      updateDate: 1691675712883,
      createdBy: "",
      updatedBy: "",
      charLimit: 100000,
      tmCharLimit: 1000000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0.35,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 35,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 1174.07,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 1408.89,
        currency: "TRY",
      },
      membershipType: "BASIC",
      membershipPeriod: "MONTHLY",
      paymentRequired: true,
      extendable: false,
      maxCharLimit: 0,
      maxTmCharLimit: 0,
      maxUserLimit: 0,
      order: 1,
      enabled: true,
    },
    {
      id: "645b2fdacff29444d3247aad",
      createDate: 1683697626985,
      updateDate: 1683697626985,
      createdBy: "",
      updatedBy: "",
      charLimit: 300000,
      tmCharLimit: 3000000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0.297,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 89,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 2985.48,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 3582.58,
        currency: "TRY",
      },
      membershipType: "PREMIUM",
      membershipPeriod: "MONTHLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 1000000,
      maxTmCharLimit: 10000000,
      maxUserLimit: 3,
      order: 2,
      enabled: true,
    },
    {
      id: "645b3000cff29444d3247aae",
      createDate: 1683697664026,
      updateDate: 1683697664026,
      createdBy: "",
      updatedBy: "",
      charLimit: 1000000,
      tmCharLimit: 10000000,
      userLimit: 3,
      translationUnitPrice: {
        amount: 0.219,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 219,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 7346.29,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 8815.55,
        currency: "TRY",
      },
      membershipType: "BUSINESS",
      membershipPeriod: "MONTHLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 1500000,
      maxTmCharLimit: 15000000,
      maxUserLimit: 10,
      order: 3,
      enabled: true,
    },
    {
      id: "645b3026cff29444d3247aaf",
      createDate: 1683697702517,
      updateDate: 1683697702517,
      createdBy: "",
      updatedBy: "",
      charLimit: 2500000,
      tmCharLimit: 25000000,
      userLimit: 10,
      translationUnitPrice: {
        amount: 0.16,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 399,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 13384.34,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 16061.21,
        currency: "TRY",
      },
      membershipType: "ENTERPRISE",
      membershipPeriod: "MONTHLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 5000000,
      maxTmCharLimit: 50000000,
      maxUserLimit: 20,
      order: 4,
      enabled: true,
    },
    {
      id: "645b30c2cff29444d3247ab0",
      createDate: 1683697858446,
      updateDate: 1683697858446,
      createdBy: "",
      updatedBy: "",
      charLimit: 30000000,
      tmCharLimit: 300000000,
      userLimit: 10,
      translationUnitPrice: {
        amount: 0.133,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 3990,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 133843.36,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 160612.04,
        currency: "TRY",
      },
      membershipType: "ENTERPRISE",
      membershipPeriod: "YEARLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 100000000,
      maxTmCharLimit: 1000000000,
      maxUserLimit: 20,
      order: 3,
      enabled: true,
    },
    {
      id: "645b30fccff29444d3247ab1",
      createDate: 1683697916919,
      updateDate: 1683697916919,
      createdBy: "",
      updatedBy: "",
      charLimit: 12000000,
      tmCharLimit: 120000000,
      userLimit: 3,
      translationUnitPrice: {
        amount: 0.183,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 2190,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 73462.9,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 88155.48,
        currency: "TRY",
      },
      membershipType: "BUSINESS",
      membershipPeriod: "YEARLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 18000000,
      maxTmCharLimit: 180000000,
      maxUserLimit: 10,
      order: 2,
      enabled: true,
    },
    {
      id: "645b3212cff29444d3247ab2",
      createDate: 1683698194882,
      updateDate: 1683698194882,
      createdBy: "",
      updatedBy: "",
      charLimit: 3600000,
      tmCharLimit: 36000000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0.247,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 890,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 29854.79,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 35825.75,
        currency: "TRY",
      },
      membershipType: "PREMIUM",
      membershipPeriod: "YEARLY",
      paymentRequired: true,
      extendable: true,
      maxCharLimit: 8600000,
      maxTmCharLimit: 86000000,
      maxUserLimit: 3,
      order: 1,
      enabled: true,
    },
    {
      id: "645b3238cff29444d3247ab3",
      createDate: 1683698232394,
      updateDate: 1683698232394,
      createdBy: "",
      updatedBy: "",
      charLimit: 1200000,
      tmCharLimit: 12000000,
      userLimit: 1,
      translationUnitPrice: {
        amount: 0.292,
        currency: "USD",
      },
      memoryUnitPrice: {
        amount: 0.005,
        currency: "USD",
      },
      userUnitPrice: {
        amount: 10,
        currency: "USD",
      },
      packagePrice: {
        amount: 350,
        currency: "USD",
      },
      usdTryCurrency: 33.5447,
      packagePriceTRY: {
        amount: 11740.65,
        currency: "TRY",
      },
      packagePriceTRYWithTax: {
        amount: 14088.78,
        currency: "TRY",
      },
      membershipType: "BASIC",
      membershipPeriod: "YEARLY",
      paymentRequired: true,
      extendable: false,
      maxCharLimit: 0,
      maxTmCharLimit: 0,
      maxUserLimit: 0,
      order: 0,
      enabled: true,
    },
  ];

  const FreePackage = {
    disabled: true,
    id: "6458e13ac4486803ca8d5af0",
    createDate: 1683546426297,
    updateDate: 1683546426297,
    createdBy: "",
    updatedBy: "",
    charLimit: 10000,
    tmCharLimit: 100000,
    userLimit: "Unlimited",
    translationUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    memoryUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    userUnitPrice: {
      amount: 0,
      currency: "USD",
    },
    packagePrice: {
      amount: 0,
      currency: "USD",
    },
    usdTryCurrency: 26.9066,
    packagePriceTRY: {
      amount: 0.0,
      currency: "TRY",
    },
    packagePriceTRYWithTax: {
      amount: 0.0,
      currency: "TRY",
    },
    membershipType: "FREEMIUM",
    membershipPeriod: "YEARLY",
    paymentRequired: false,
    extendable: false,
    maxCharLimit: 0,
    maxTmCharLimit: 0,
    maxUserLimit: 0,
    order: 0,
    enabled: true,
  };

  const featuresDefinitions = [
    {
      id: 1,
      title: "Unlimited projects & seats",
      status: false,
      highlight: false,
    },
    {
      id: 2,
      title: "Unlimited languages & keys",
      status: true,
      highlight: false,
    },
    {
      id: 3,
      title: "Translation History",
      status: true,
      highlight: false,
    },
    {
      id: 4,
      title: "Glossary of terms",
      status: true,
      highlight: false,
    },
    {
      id: 5,
      title: "API Integration",
      status: true,
      highlight: false,
    },
    {
      id: 6,
      title: "Screenshots (with OCR)",
      status: true,
      highlight: true,
    },
    {
      id: 7,
      title: "Translation Sharing",
      status: true,
      highlight: false,
    },
    {
      id: 8,
      title: "Multiplatform Development",
      status: true,
      highlight: false,
    },
    {
      id: 9,
      title: "Automated Translation Memory",
      status: true,
      highlight: false,
    },
    {
      id: 10,
      title: "SDKs",
      status: true,
      highlight: false,
    },
    {
      id: 11,
      title: "{{membershipType}} Support",
      status: true,
      highlight: true,
      isString: true,
      dynamic: true,
      key: "membershipType",
    },
    {
      id: 12,
      title: "{{userLimit}} Seats Included",
      status: true,
      dynamic: true,
      key: "userLimit",
      highlight: true,
    },

    /*{
      id: 1,
      title: "MT House",
      status: false,
      highlight: false,
    },
    {
      id: 2,
      title: "Dynamic Routing",
      status: true,
      highlight: false,
    },
    {
      id: 3,
      title: "Private Shared Link",
      status: true,
      highlight: false,
    },
    {
      id: 4,
      title: "Document Translation",
      status: true,
      highlight: false,
    },
    {
      id: 5,
      title: "{{charLimit}} Characters",
      status: true,
      dynamic: true,
      key: "charLimit",
      highlight: true,
    },
    {
      id: 6,
      title: "Unlimited Source Keys",
      status: true,
      highlight: true,
    },
    {
      id: 7,
      title: "Glossary",
      status: true,
      highlight: false,
    },
    {
      id: 8,
      title: "API Integration",
      status: true,
      highlight: false,
    },
    {
      id: 9,
      title: "Automated Translation Memory",
      status: true,
      highlight: false,
    },
    {
      id: 10,
      title: "SDKs",
      status: true,
      highlight: false,
    },
    {
      id: 11,
      title: "{{membershipType}} Support",
      status: true,
      highlight: true,
      isString: true,
      dynamic: true,
      key: "membershipType",
    },
    {
      id: 12,
      title: "{{userLimit}} Seats Included",
      status: true,
      dynamic: true,
      key: "userLimit",
      highlight: true,
    }*/
  ]


  const featuresTranslations = t("Commons.Pricing.Packages.Features", { returnObjects: true });


  const featureTranslated = featuresDefinitions.map((f) => {
    const ft = featuresTranslations.find((ft) => ft.id === f.id);
    return f?.id ===ft?.id  ? {...f, ...ft} : f;
  })


  const packageOptions = {
    membership: null,
    membershipDetails: null,
    extendMode: false,
    extendPackage: null,
    invoices: null,
    paymentLocale: "USD",
    packages: {
      displayNames: {
        FREEMIUM: t("Commons.Pricing.Packages.displayNames.FREEMIUM.Title"),
        BASIC: t("Commons.Pricing.Packages.displayNames.BASIC.Title"),
        PREMIUM: t("Commons.Pricing.Packages.displayNames.PREMIUM.Title"),
        BUSINESS: t("Commons.Pricing.Packages.displayNames.BUSINESS.Title"),
        ENTERPRISE: t("Commons.Pricing.Packages.displayNames.ENTERPRISE.Title"),
      },
      ctaBtnText: {
        FREEMIUM: t("Commons.Pricing.Packages.ctaBtnText.FREEMIUM.Title"),
        BASIC: t("Commons.Pricing.Packages.ctaBtnText.BASIC.Title"),
        PREMIUM: t("Commons.Pricing.Packages.ctaBtnText.PREMIUM.Title"),
        BUSINESS: t("Commons.Pricing.Packages.ctaBtnText.BUSINESS.Title"),
        ENTERPRISE: t("Commons.Pricing.Packages.ctaBtnText.ENTERPRISE.Title"),
      },
      ctaForWhoText: {
        FREEMIUM: t("Commons.Pricing.Packages.ctaForWhoText.FREEMIUM.Title"),
        BASIC: t("Commons.Pricing.Packages.ctaForWhoText.BASIC.Title"),
        PREMIUM: t("Commons.Pricing.Packages.ctaForWhoText.PREMIUM.Title"),
        BUSINESS: t("Commons.Pricing.Packages.ctaForWhoText.BUSINESS.Title"),
        ENTERPRISE: t("Commons.Pricing.Packages.ctaForWhoText.ENTERPRISE.Title"),
      },
      supportTypeNames: {
        FREEMIUM: t("Commons.Pricing.Packages.supportTypeNames.FREEMIUM.Title"),
        BASIC: t("Commons.Pricing.Packages.supportTypeNames.BASIC.Title"),
        PREMIUM: t("Commons.Pricing.Packages.supportTypeNames.PREMIUM.Title"),
        BUSINESS: t("Commons.Pricing.Packages.supportTypeNames.BUSINESS.Title"),
        ENTERPRISE: t("Commons.Pricing.Packages.supportTypeNames.ENTERPRISE.Title"),
      },
      selectedPackage: false,
      activePackage: false,
      all: [],
      monthly: [],
      yearly: [],
      period: DEFAULT_PERIOD,
      availablePackages: [],
      features:featureTranslated,
    },
  };

  const [selectedPeriod, setSelectedPeriod] = useState(DEFAULT_PERIOD);
  
  const [packageList, setPackageList] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(
    memberships.find(
      (m) =>
        m.membershipType === DEFAULT_PACKAGE &&
        m.membershipPeriod === DEFAULT_PERIOD
    )
  );

  const getDynamicFeatureContent = (f, activePackage) => {
    const featureValue = dynamicParse(f.title, {
      charLimit: Intl.NumberFormat("en-US").format(
        activePackage?.membershipDetails.charLimit
      ),
      userLimit: activePackage?.membershipDetails.userLimit,
      membershipType: activePackage.supportTypeName,
    });

    return featureValue;
  };

  const onClickToSelectItem = (item) => {
    ReactGA.event({
      category: "PRICING_PAGE",
      action: "CLICK_TO_PACKAGE",
      label: item.id,
    });

    if (item?.membershipDetails?.id || item?.id) {
      const selectedPackageId = item?.membershipDetails?.id || item?.id;
      const generateUTMLink =
        CONFIG.APP.REGISTER_URL +
        `?utm_source=${encodeURIComponent(
          window.location.href
        )}&utm_medium=referral&utm_content=js_pricing_page_btn&selectedPackageId=${selectedPackageId}`;
      window.location.href = generateUTMLink;
    }
  };

  const getPackageDiscountView = (item) => {
    if (item?.membershipDetails?.membershipPeriod === "YEARLY") {
      if (item.membershipDetails?.membershipType !== "FREEMIUM") {
        return (
          <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">
            {t("Commons.Pricing.Discount.25OFF.Text")}
          </span>
        );
      } else {
        return (
          <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
           {t("Commons.Pricing.Discount.FREE.Text")}
          </span>
        );
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (selectedPeriod) {
      const availableList = Object.entries(packageOptions.packages.displayNames)
        .map(([key, value]) => ({
          key,
          value,
          keyName: key,
          displayName: packageOptions.packages.displayNames[key],
          ctaBtnText: packageOptions.packages.ctaBtnText[key],
          ctaForWhoText: packageOptions.packages.ctaForWhoText[key],
          membershipDetails: memberships.find(
            (m) =>
              m.membershipType === key && m.membershipPeriod === selectedPeriod
          ),
          supportTypeName: packageOptions.packages.supportTypeNames[key],
          features: packageOptions.packages.features,
        }))
        .filter((f) => !RESTRICTED_PACKAGES.includes(f.keyName));

      setPackageList(availableList);
      setSelectedPackage(
        memberships.find(
          (m) =>
            m.membershipType === DEFAULT_PACKAGE &&
            m.membershipPeriod === selectedPeriod
        )
      );
    }
  }, [selectedPeriod]);

  return (
    <>
      <section className="pricing-page-hero js-section_nav-light">
        <section className="container mx-auto">
          <div className="pricing-page-heading grid  grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full flex flex-col justify-center items-center	 lg:col-span-6 lg:col-start-2">
              <h1>
              <Trans i18nKey="Commons.Pricing.Packages.Header.Title" components={{
                        span_highlight: <span className="highlight" />,
                        span_highlight_cyan:<span className="highlight-cyan" />
                      }} />
              </h1>
              <h2>
              <Trans i18nKey="Commons.Pricing.Packages.Header.Description" components={{
                        span_highlight_cyan:<span className="highlight-cyan" />
                      }} />
              </h2>

              <div className="segment-container">
                <SegmentedControl
                  name="period"
                  callback={(val) => setSelectedPeriod(val)}
                  controlRef={useRef()}
                  defaultIndex={0}
                  segments={[
                    {
                      label: t("Commons.Pricing.SegmentControl.Labels.MONTHLY.Label"),
                      value: "MONTHLY",
                      ref: useRef(),
                    },
                    {
                      label: t("Commons.Pricing.SegmentControl.Labels.YEARLY.Label"),
                      value: "YEARLY",
                      ref: useRef(),
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
      <section className="bg-white w-full js-section_nav-dark pricing-page-content__wrapper">
        <div className="container mx-auto">
          <div className="pricing-page-content grid grid-cols-4 lg:grid-cols-8">
            <div className="col-span-full col-start-1 lg:col-span-4 lg:col-start-2"></div>
            <div className="pricing-page-list col-span-full">
              {packageList.map((item) => {
                return (
                  <div
                    className={
                      item?.membershipDetails?.id === selectedPackage?.id
                        ? `pricing-page-list__pricing-box__wrapper pricing-page-list__pricing-box__wrapper--selected`
                        : `pricing-page-list__pricing-box__wrapper`
                    }
                    onClick={() => onClickToSelectItem(item)}
                  >
                    <div
                      className={
                        item.membershipDetails.disabled
                          ? `pricing-page-list__pricing-box pricing-page-list__pricing-box--disabled`
                          : item?.membershipDetails?.id === selectedPackage?.id
                          ? `pricing-page-list__pricing-box pricing-page-list__pricing-box--selected`
                          : `pricing-page-list__pricing-box`
                      }
                    >
                      <div className="pricing-page-list__pricing-box__title">
                        {item.displayName}
                      </div>
                      <div className="pricing-page-list__pricing-box__label">
                        {getPackageDiscountView(item)}
                      </div>
                      <div className="pricing-page-list__pricing-box__price">
                        <span className="currency-symbol">
                          {DEFAULT_CURRENCY === "TRY" ? "₺" : "$"}
                        </span>
                        <MileStone
                          duration={0.15}
                          end={
                            selectedPeriod === "YEARLY"
                              ? item.membershipDetails.packagePrice.amount / 12
                              : item.membershipDetails.packagePrice.amount
                          }
                          formattingFn={
                            Intl.NumberFormat(
                              DEFAULT_CURRENCY === "TRY" ? "tr-TR" : "en-US"
                            ).format
                          }
                        />

                        <span className="currency-note">
                          {t("Commons.Pricing.Packages.AdditionalInfo.AllTaxesIncludes.Text")}
                          <br />
                          <span className="highlight">
                            {item.membershipDetails?.membershipType ===
                            "FREEMIUM"
                              ? t("Commons.Pricing.Packages.AdditionalInfo.BilledAnnually.Text")
                              : item?.membershipDetails?.membershipPeriod ===
                                "MONTHLY"
                              ? t("Commons.Pricing.Packages.AdditionalInfo.BilledMonthly.Text")
                              : t("Commons.Pricing.Packages.AdditionalInfo.PerMonthBilledAnnually.Text")}
                          </span>
                        </span>
                      </div>
                      <div className="pricing-page-list__pricing-box__for-who">
                        {item.ctaForWhoText}
                      </div>
                      <div className="pricing-page-list__pricing-box__features">
                        <ul>
                          {item.features.slice(0, 5).map((f, i) => (
                            <li
                              key={i}
                              className={f.highlight ? `highlighted` : ``}
                            >
                              <span className="label">
                                {f.dynamic
                                  ? getDynamicFeatureContent(f, item)
                                  : f.title}
                              </span>
                              <span className="icon">
                                <CheckIcon />
                              </span>
                            </li>
                          ))}
                          <li className={"more"}>
                            <span className="label">{t("Commons.Pricing.Packages.AdditionalInfo.AndMore.Text")}</span>
                          </li>
                        </ul>
                      </div>
                      <button
                        className="pricing-page-list__pricing-box__cta-btn"
                        onClick={() => onClickToSelectItem(item)}
                      >
                        {item.ctaBtnText}
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            {
              <div className="pricing-page-enterprise col-span-full">
                <div className="pricing-page-enterprise__title">
                  <span className="pricing-page-enterprise__title-first">
                    {t("Commons.Pricing.Packages.displayNames.ENTERPRISE.Title")}
                  </span>
                  <span className="pricing-page-enterprise__title-second">
                  {t("Commons.Pricing.Packages.ctaForWhoText.ENTERPRISE.Title")}
                  </span>
                </div>
                <div className="pricing-page-enterprise__desc">
                {t("Commons.Pricing.Packages.ctaDescText.ENTERPRISE.Title")}
                </div>
                <div className="pricing-page-enterprise__cta">
                  <button onClick={() => onClickToSelectItem(FreePackage)}>
                    {t("Commons.Pricing.Packages.ctaBtnText.ENTERPRISE.Title")}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
}
