import React from "react";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import { syncHistoryWithStore } from "react-router-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Routes from "./Routes/";
import StoreService from "./Services/StoreService";
import i18n from "./i18n";
import "./Assets/Styles/main.scss";
const store = StoreService.setup();
const history = syncHistoryWithStore(StoreService.browserHistory, store);
const root = createRoot(document.getElementById("____lugath"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Routes {...{ store, history }} isUserValid={true} />
      </Router>
    </Provider>
  </StrictMode>,
);
