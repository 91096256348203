import React, { useState } from "react";
import BrowserFrame from "../BrowserFrame";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/close-icon--alternative.svg";
import CTAButtonWrapper from "../CTABtnWrapper";
import ReactModal from "react-modal";
import VideoPlayer from "../VideoPlayer";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function SolutionHeader({ data }) {
  const [showModal, setShowModal] = useState(false);
  const { getTranslatableContent} = useTranslationEncapsulated();
  
  const onClickAction = () => {
    setShowModal(true);
  };
  return (
    <section className="solution-details-header js-section_nav-dark">
      <div className="container mx-auto">
        <div className="solution-details-header__wrapper">
          <div className="solution-details-header__title-wrapper">
            <div
              className="solution-details-header__title"
              dangerouslySetInnerHTML={{ __html: getTranslatableContent(data.title) }}
            ></div>
          </div>
          <div className="solution-details-header__desc-wrapper">
            <div className="solution-details-header__desc">
              {getTranslatableContent(data.description)}
            </div>
            <div className="solution-details-header__btns">
              {data?.cta?.length ? (
                <>
                  {data.cta.map((c, i) => (
                    <CTAButtonWrapper
                      key={`cta-btn-wrapper-key-${i}`}
                      options={{
                        formPopupTheme: "light",
                        className: "product-content__cta-btn__wrapper filled",
                        utmLabel: "js_solution_header__cta_btn",
                      }}
                      children={
                        <button
                          className={`${i % 2 === 0 ? "bordered" : "filled"}`}
                        >
                          {getTranslatableContent(c.label)}
                        </button>
                      }
                      data={{
                        ...c,
                      }}
                    ></CTAButtonWrapper>
                  ))}
                </>
              ) : null}
            </div>
          </div>
          <div className="solution-details-header__image-wrapper">
            <div className="solution-details-header__image">
              <BrowserFrame
                url={
                  data?.demoFrame?.url ? data?.demoFrame?.url : "app.lugath.com"
                }
                path={data?.demoFrame?.applicationPath}
                image={{
                  source: data?.demoFrame?.image?.source,
                  width: data?.demoFrame?.image?.width,
                  height: data?.demoFrame?.image?.height,
                }}
                onClickAction={onClickAction}
                action={data?.demoFrame?.action}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(71, 204, 223, 0.75)",
            zIndex: "9999999999",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "80%",
            height: "80%",
            maxWidth: "1280px",
            maxHeight: "720px",
            transform: "translate(-50%, -50%)",
            background: "#47ccdf",
            overflow: "visible",
            WebkitOverflowScrolling: "touch",
            borderRadius: "0px",
            outline: "none",
            padding: "0",
            border: "0px",
            boxShadow: "0px 0px 125px rgba(0,0,0,0.5)",
          },
        }}
        isOpen={showModal}
        contentLabel="Minimal Modal Example"
      >
        <VideoPlayer url={data?.demoFrame?.videoLink} />
        <button className="close-video-btn" onClick={() => setShowModal(false)}>
          <span className="label">CLOSE</span>
          <CloseIcon />
        </button>
      </ReactModal>
    </section>
  );
}
