import _extend from "lodash/extend";
import { featuresData } from "../Services/DataService";
const SUCCESS_SUFFIX = "_SUCCESS";
const LIST_FEATURES = "LIST_FEATURES";
const GET_FEATURE = "GET_FEATURE";

const initialState = [];

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case LIST_FEATURES + SUCCESS_SUFFIX:
      return action.payload.data;
    case GET_FEATURE + SUCCESS_SUFFIX:
      return action.payload.data;
    default:
      return state;
  }
};

export const allFeatures = () => {
  return (dispatch) => {
    dispatch({
      type: LIST_FEATURES + SUCCESS_SUFFIX,
      payload: { data: featuresData },
    });
  };
};

export const setActiveFeature = (slug) => {
  return (dispatch) => {
    dispatch({
      type: GET_FEATURE + SUCCESS_SUFFIX,
      payload: { data: featuresData.filter((item) => item.slug === slug) },
    });
  };
};
