import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../Image";
import CONFIG from "../../Services/ConfigService";
import { ReactComponent as PromoPlayIcon } from "../../Assets/Icons/promo-play-icon.svg";
import { ReactComponent as ChevronDown } from "../../Assets/Icons/chevron-down.svg";
import "./index.scss";
import { menuData } from "../../Services/DataService";
import LanguageSettings from "../../Services/LanguageService";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function MenuSm({ setshowSmMenu, showSmMenu, availableLanguages, selectedLanguage, onClickSelectItem, languagesAreLoaded  }) {
  const [showSubLinks, setshowSubLinks] = useState(false);
  const [subLinks, setSublLinks] = useState([]);
  const [activeParent, setActiveParent] = useState(false);
  const {getTranslatableContent, t} = useTranslationEncapsulated();

  const createSubLinks = (id) => {
    const subLinks = menuData
      .filter((item) => item.showInMobile === true)
      .find((item) => item.id === id).sublinks;
    setSublLinks(subLinks);
  };


  const onSetShowMenu = () => {
    setshowSmMenu(false);
    setshowSubLinks(false);
    setActiveParent(false);
  };

  useEffect(() => {
    console.log("menuSM", selectedLanguage, availableLanguages);
  }, [selectedLanguage, availableLanguages])

  return (
    <div
      className={`w-full h-full bg-black fixed bg-opacity-60 z-50 menu-sm-wrapper ${
        showSmMenu ? "show" : ""
      }`}
    >
      <div className="menu-sm">
        <div className="menu-sm-content h-full overflow-y-scroll flex flex-col">
          <div className="menu-sm-content--top flex justify-between">
            {showSubLinks ? (
              <div
                className="back"
                onClick={() => {
                  setshowSubLinks(false);
                  setActiveParent(false);
                }}
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#282828"
                    width="15px"
                    height="15px"
                    viewBox="0 2 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </span>
                back
              </div>
            ) : (
              <div className="navbar-lang">
              <div className="select">
                <span
                  className="current focus:ring-4 focus:outline-none focus:ring-blue-300"
                >
                  <div className="option">
                    <span>{selectedLanguage}</span>
                    <span className="arrow-down">
                      <ChevronDown />
                    </span>
                  </div>
                </span>
                <div className="options">
                  <ul id="language-box">
                    {availableLanguages.map((option, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => onClickSelectItem(option)}
                        >
                          <div
                            className={
                              option === selectedLanguage
                                ? `option selected`
                                : `option`
                            }
                          >
                            <span>{option.code}</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            )}
            <div onClick={() => onSetShowMenu()} className="close-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#282828"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                stroke="#282828"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
          <div className="menu-sm-content--body flex-grow mb-16 relative">
            {showSubLinks && !!subLinks ? (
              <div className="menu-sm-content--body_sublinks">
                <ul>
                  {activeParent ? <h6>{getTranslatableContent(activeParent.title)}</h6> : null}
                  {subLinks
                    ? subLinks
                        .filter((item) => item.showInMobile === true)
                        .map((link) => {
                          return (
                            <li key={link.id}>
                              {link.isExternal ? (
                                <a
                                  href={link.slug}
                                  rel="noreferrer"
                                  target={"_blank"}
                                  onClick={() => onSetShowMenu()}
                                >
                                  <span className="icon">
                                    <>
                                      <Image
                                        width={24}
                                        height={24}
                                        fileName={`Assets/Icons/${link.icon}`}
                                      />
                                    </>
                                  </span>
                                  <span className="label"> {getTranslatableContent(link.title)}</span>
                                </a>
                              ) : (
                                <Link
                                  to={
                                    link.isExternal
                                      ? { pathname: link.slug }
                                      : link.slug
                                  }
                                  target={link.isExternal ? "_blank" : ""}
                                  onClick={() => onSetShowMenu()}
                                >
                                  <span className="icon">
                                    <>
                                      <Image
                                        width={24}
                                        height={24}
                                        fileName={`Assets/Icons/${link.icon}`}
                                      />
                                    </>
                                  </span>
                                  <span className="label"> {getTranslatableContent(link.title)}</span>
                                </Link>
                              )}
                            </li>
                          );
                        })
                    : null}
                </ul>
              </div>
            ) : (
              <div className="menu-sm-content--body_links h-full flex flex-col justify-between text-black">
                <ul>
                  {menuData
                    .filter((item) => item.showInMobile === true)
                    .map((link) => {
                      return link.sublinks.length ? (
                        <li key={link.id}>
                          {link.isExternal ? (
                            <a
                              href={link.slug}
                              rel="noreferrer"
                              target={"_blank"}
                              onClick={() => {
                                setshowSubLinks(true);
                                createSubLinks(link.id);
                                setActiveParent(link);
                              }}
                            >
                              {getTranslatableContent(link.title)}
                            </a>
                          ) : (
                            <Link
                              to={
                                link.isExternal
                                  ? { pathname: link.slug }
                                  : link.slug
                              }
                              target={link.isExternal ? "_blank" : ""}
                              onClick={() => {
                                setshowSubLinks(true);
                                createSubLinks(link.id);
                                setActiveParent(link);
                              }}
                            >
                              {getTranslatableContent(link.title)}
                            </Link>
                          )}
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              width="15px"
                              height="15px"
                              viewBox="0 2 24 24"
                              stroke="#282828"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M9 5l7 7-7 7"
                              />
                            </svg>
                          </span>
                        </li>
                      ) : (
                        <li key={link.id}>
                          <Link onClick={() => onSetShowMenu()} to={link.slug}>
                            {getTranslatableContent(link.title)}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <div className="menu-sm-content--body__video">
                  <div className="menu-sm-content--body__video-promo">
                    <div className="menu-sm-content--body__video-promo__box">
                      <div className="menu-sm-content--body__video-promo__box-video">
                        <Link to={"/tutorials"}>
                          <PromoPlayIcon />
                        </Link>
                      </div>
                      <div className="menu-sm-content--body__video-promo__box-content">
                        <div className="menu-sm-content--body__video-promo__box-content__title" dangerouslySetInnerHTML={{__html:t("Commons.Header.Menu.PromotionBanner.Title")}}/>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href={`${
                    CONFIG.APP.REGISTER_URL
                  }?utm_source=${encodeURIComponent(
                    window.location.href
                  )}&utm_medium=referral&utm_content=js_mobile_menu_login_btn`}
                  target="_blank"
                  rel="noreferrer"
                  className="menu-sm-content--body_links__login"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="black"
                      viewBox="0 2 24 24"
                      stroke="white"
                      width="16px"
                      height="16px"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                      />
                    </svg>
                  </span>
                  {t("Commons.Header.Menu.Login.Text")}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
