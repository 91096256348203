import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextToutWithList from "../../Components/TextToutWithList";
import PrimaryTout from "../../Components/PrimaryTout";
import { useDispatch } from "react-redux";
import { allFeatures } from "../../Reducers/features";
import { allSolutions } from "../../Reducers/solutions";
import Testimonials from "../../Components/Testimonials";
import Integrations from "../../Components/Integrations";
import FeatureList from "../../Components/FeatureList";
import { profilesData } from "../../Services/DataService";
import { useTranslation, Trans } from "react-i18next";
import LanguageSettings from "../../Services/LanguageService";
import "./index.scss";

export default function Home(props) {
  const { t, i18n, ready } = useTranslation();
  const dispatch = useDispatch();

  const SolutionsModuleData = {
    moduleName: "TextToutWithList",
    data: {
      features: [
        {
          image: "Assets/Icons/feature-web.svg",
          title: t("Pages.Home.Solutions.Component.Features.Website.Title"),
          description: t(
            "Pages.Home.Solutions.Component.Features.Website.Description"
          ),
        },
        {
          image: "Assets/Icons/feature-mobile.svg",
          title: t("Pages.Home.Solutions.Component.Features.MobileApp.Title"),
          description: t(
            "Pages.Home.Solutions.Component.Features.MobileApp.Description"
          ),
        },
        {
          image: "Assets/Icons/feature-game.svg",
          title: t("Pages.Home.Solutions.Component.Features.Game.Title"),
          description: t("Pages.Home.Solutions.Component.Features.Game.Description"),
        },
        {
          image: "Assets/Icons/web_stories_black_24dp.svg",
          title: t("Pages.Home.Solutions.Component.Features.MarketingAssets.Title"),
          description: t(
            "Pages.Home.Solutions.Component.Features.MarketingAssets.Description"
          ),
        },
        {
          image: "Assets/Icons/product-document-translation.svg",
          title: t("Pages.Home.Solutions.Component.Features.OfficeDocuments.Title"),
          description: t(
            "Pages.Home.Solutions.Component.Features.OfficeDocuments.Description"
          ),
        },
        {
          image: "Assets/Icons/user-guide_black_24dp.svg",
          title: t("Pages.Home.Solutions.Component.Features.UserGuides.Title"),
          description: t(
            "Pages.Home.Solutions.Component.Features.UserGuides.Description"
          ),
        },
      ],
      cta: [
        {
          label: "Try For Free 14 Days",
          action: "register",
        },
      ],
    },
    options: {
      theme: "cyan",
      box: "cyan",
      listItems: 3,
      padding: "small-top",
    },
  };

  React.useEffect(() => {
    Promise.all([
      dispatch(allFeatures()),
      dispatch(allSolutions()),
    ]);
  }, [ready]);

  return (
    <>
      <div className="homepage">
        <PrimaryTout></PrimaryTout>
        <div className="shadow-anchor"></div>
        <section className="homepage-solutions__cover js-section_nav-dark">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div
                  className="homepage-solutions__cover__title"
                  dangerouslySetInnerHTML={{
                    __html: t("Pages.Home.Solutions.Component.Title"),
                  }}
                />
              </div>
              <div className="div2">
                <div className="homepage-solutions__cover__content">
                  <div
                    className="homepage-solutions__cover__content-desc"
                    dangerouslySetInnerHTML={{
                      __html: t("Pages.Home.Solutions.Component.Description"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <TextToutWithList
          options={SolutionsModuleData.options}
          data={SolutionsModuleData.data}
        />
        <section className="homepage-solutions__cover homepage-solutions__cover--light js-section_nav-dark">
          <div className="container mx-auto">
            <div className="parent">
              <div className="div1">
                <div
                  className="homepage-solutions__cover__title"
                  dangerouslySetInnerHTML={{
                    __html: t("Pages.Home.Profiles.Component.Title"),
                  }}
                />
              </div>
              <div className="div2">
                <div className="homepage-solutions__cover__content">
                  <div
                    className="homepage-solutions__cover__content-desc"
                    dangerouslySetInnerHTML={{
                      __html: t("Pages.Home.Profiles.Component.Description"),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <TextToutWithList
          style={{ borderTop: "0px", paddingBottom: "200px" }}
          options={{
            theme: "light",
            box: "light",
            listItems: 3,
            padding: "small-top",
          }}
          data={{
            features: profilesData,
          }}
        />
        <FeatureList
          data={{
            title: (
               <Trans i18nKey="Pages.Home.FeatureList.Component.Title" components={{
                b: <b/>}}
               />
            ),
            description: (
              <Trans i18nKey="Pages.Home.FeatureList.Component.Description" components={{
                b: <b/>}}
               />
            ),
          }}
          options={{ shape: "icon", imageMinWidth: "32px" }}
        />
        <Testimonials />
        <Integrations />
      </div>
    </>
  );
}
