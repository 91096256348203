import React from "react";
import Marquee from "react-fast-marquee";
import { ReactComponent as CustomerVestel } from "../../Assets/Images/customer-vestel.svg";
import { ReactComponent as CustomerArcelik } from "../../Assets/Images/customer-arcelik.svg";
import { ReactComponent as CustomerParibu } from "../../Assets/Images/customer-paribu.svg";
import { ReactComponent as CustomerEts } from "../../Assets/Images/customer-ets.svg";
import { ReactComponent as CustomerSabanci } from "../../Assets/Images/customer-sabanci.svg";
import { ReactComponent as CustomerSiemens } from "../../Assets/Images/customer-siemens.svg";
import { ReactComponent as CustomerGama } from "../../Assets/Images/customer-gama.svg";
import { ReactComponent as CustomerFlorence } from "../../Assets/Images/customer-florence.svg";
import "./index.scss";
import { useTranslation } from "react-i18next";

const customerLogos = [
  {
    component: (customStyles) => <CustomerVestel style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => <CustomerParibu style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => <CustomerEts style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => (
      <CustomerArcelik style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => (
      <CustomerSabanci style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => (
      <CustomerSiemens style={{ ...customStyles }} />
    ),
  },
  {
    component: (customStyles) => <CustomerGama style={{ ...customStyles }} />,
  },
  {
    component: (customStyles) => (
      <CustomerFlorence style={{ ...customStyles }} />
    ),
  },
];

export default function CustomerLogos({
  data ={},
  options = {
      theme: "transparent",
      listItems: 4,
      box: "default",
      padding: "default",
      primaryTextAlign: "left",
      secondaryTextAlign: "left",
    title: {
      show: true,
      align: "center",
    },
    customStyles: {}
  },
  pageName = "Home",
  id
}) {
  const { t } = useTranslation();

  return (
    <section id={id} className={`customer-section-bg customer-section-bg--${
      options.padding
    } customer-section-bg--${options.theme} ${
      options.theme === "light" || options.theme === "cyan"
        ? "js-section_nav-dark"
        : ""
    }`} style={{maxWidth:"100% !important;"}}>
      <div className={`customer-section container mx-auto customer-section-align--${options.title.align}`} style={options.customStyles}>
        {options?.title.show ? <span className={`customer-section__title customer-section__title--${options.title.size}`} dangerouslySetInnerHTML={{ __html: t("Pages.Home.Customers.Component.Title") }} /> : null}
        <div className="customer-section__content">
          <Marquee
            style={{ backgroundColor: "transparent" }}
            gradientColor={"transparent"}
          >
            {customerLogos.map((c, i) => (
              <div
                key={`customer-logo-${i}`}
                className="customer-section__content-item"
              >
                {" "}
                {c.component()}
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
}
