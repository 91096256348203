import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "../Reducers";
import { getURLParams } from "../Libs/utils";
const browserHistory = createBrowserHistory();

const setup = () => {
  //const options = getURLParams(window.location.search);
  //const clearLocalStorage = options.clearLocalStorage === "true" ? true : false;
  //const startFromLocalStorage = options.startFromLocalStorage === "false" ? false : true;
  //const clearLocalStorage = false;
  //const startFromLocalStorage = true;
  //clearLocalStorage && window.localStorage.clear();

  /*const initialState = startFromLocalStorage
    ? localStorage.getItem("lugath.website.storage")
      ? JSON.parse(localStorage.getItem("lugath.website.storage"))
      : {}
    : {};*/

  const initialState = {};
  const enhancers = [];

  const middleware = [thunk, routerMiddleware(browserHistory)];

  if (process.env.NODE_ENV === "development") {
    enhancers.push(applyMiddleware());
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  return createStore(rootReducer, initialState, composedEnhancers);
};
// eslint-disable-next-line
export default {
  browserHistory,
  setup,
};
