import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { seoData } from "../../Services/DataService";
import { Helmet } from "react-helmet";
import { dynamicParse } from "../../Libs/utils";
import _ from "lodash";

export default function SEOContent() {
  const location = useLocation();

  const getAttribute = (attributes) => {
    const VARIABLES = {
      //placeholder : value
      "{{window.location.pathname}}": location.pathname,
    };

    _.each(attributes, (value, key) => {
      const regex = /\{\{(.*?)\}\}/g;
      const found = value.match(regex);
      if (found?.length) {
        attributes[key] = value.replaceAll(found[0], VARIABLES[found[0]]);
      }
    });
    return attributes;
  };
  const getHelmetContent = () => {
    const path = window?.location.pathname;
    const defaultSeoInformation = seoData.default;
    const relatedSeoInformation = seoData?.paths.find((s) => s.path === path);
    const SEO_INFORMATION = relatedSeoInformation
      ? relatedSeoInformation.seo
      : defaultSeoInformation.seo;
    /*const ldJson = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Istanbul, TR, Turkey",
        postalCode: "34865",
        streetAddress: "Pendik Sanayi Mah. Teknopark Bulvarı, No: 1 / 4C",
      },
      description:
        "Lugath is a translation management system built for agile teams who want to automate their localization process. A better way to localize your website, mobile apps and digital content.",
      name: "Lugath",
      legalName: "Lugath Inc.",
      url: "https://lugath.com/",
      logo: "https://www.lugath.com/cover-logo.png",
      image: "https://www.lugath.com/cover-pic.png",
      foundingDate: "2021",
      sameAs: [
        "https://twitter.com/lugathcom",
        "https://www.linkedin.com/company/lugath/",
      ],
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          name: "Blogs",
          item: "https://www.speblog.org",
        },
        {
          "@type": "ListItem",
          position: 2,
          name: SEO_INFORMATION.title,
          item: window.location.href,
        },
      ],
    };*/

    return (
      <Helmet>
        {SEO_INFORMATION.map((t, index) => {
          if (t?.attributes) {
            return (
              <t.tag key={`seo-tag-${index}`} {...getAttribute(t.attributes)} />
            );
          } else {
            return <t.tag key={`seo-tag-${index}`}>{t.value}</t.tag>;
          }
        })}
        {/*<script type="application/ld+json">{JSON.stringify(ldJson)}</script>;*/}
      </Helmet>
    );
  };

  useEffect(() => { }, [location]);

  return <>{getHelmetContent()}</>;
}
