import React, { useState } from "react";
import { ReactComponent as ArrowBackward } from "../../Assets/Icons/arrow-backward.svg";
import { Link } from "react-router-dom";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function StaticPageHeader({
  data,
  options = {
    theme: "light",
    subdesc:false, /* { content:Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric", }).format(new Date(1669018587000)) } */
    title: "left",
    backBtn:false, /* {label:"All Updates", "path": "/news"} */
  },
}) {
  const { getTranslatableContent,Trans, getRawKey, t} = useTranslationEncapsulated();
  return (
    <section className={`static-page-header static-page-header--${options.theme} ${
        options.theme === "light" ? "js-section_nav-dark" : ""
      }`}>
    <div className="container mx-auto">
      <div className="parent">
        <div className="div1">
          <div className="static-page-header__title">
          <Trans i18nKey={getRawKey(data.title)} components={{
                span_shine: <span className="shine" />
              }} />
          </div>
          {options.backBtn && options.backBtn?.label && options.backBtn?.path ? 
          <Link className="updates-page-header__content-cta" to={options.backBtn?.path}>
                <span className="icon">
                  <ArrowBackward />
                </span>
                <span className="label">{options.backBtn?.label}</span>
          </Link> : null 
          }
        </div>
        <div className="div2">
          <div className="static-page-header__content">
            <div className="static-page-header__content-desc">
              <Trans i18nKey={getRawKey(data.description)} components={{
                span_shine: <span className="shine" />
              }} />
              {options?.subdesc && options?.subdesc?.content ? (
                    <span className="subdesc">
                      {options?.subdesc?.content}
                    </span>
                  ) : null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}