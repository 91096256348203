import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import ScrollToTop from "../Components/ScrollToTop";
import MarqueeComponent from "../Components/Marquee";
import LoadingBar from "../Components/LoadingBar";
import CookiePopUp from "../Components/CookiePopUp";
import FormPopUp from "../Components/FormPopUp";
import GetStarted from "../Components/GetStarted/";
import SEOContent from "../Components/SEOContent/";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getURLParams } from "../Libs/utils";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { showFormPopUp } from "../Reducers/settings";
import "./default.scss";

export default function DefaultLayout(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const options = getURLParams(window.location.search);
    if (!_.isEmpty(options)) {
      const actionType = options?.action;
      if (actionType && actionType === "book-a-demo") {
        dispatch(
          showFormPopUp({
            content: {
              type: "book-a-demo",
            },
            theme: "light",
          })
        );
        navigate("/");
      }
    }
  }, [props, location]);
  const getLayout = () => {
    return (
      <React.Fragment>
        <SEOContent />
        <LoadingBar />
        <ScrollToTop />
        <FormPopUp></FormPopUp>
        <CookiePopUp></CookiePopUp>
        <Navbar></Navbar>
        <div>{props.children}</div>
        <GetStarted></GetStarted>
        <Footer></Footer>
      </React.Fragment>
    );
  };

  return getLayout();
}
