import React, { useState } from "react";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import CTABtnWrapper from "../../Components/CTABtnWrapper";
import Image from "../../Components/Image";
import "./index.scss";


export default function SecurityCerts({
  options = {
    theme: "dark",
    listItems: 4,
    box: "default",
    padding: "default",
    primaryTextAlign: "left",
    secondaryTextAlign: "left",
  },
  data,
  pageName="Security",
  id
}) {
  const { getTranslatableContent, Trans, t } = useTranslationEncapsulated();
  return (
    <section id={id} className={`security-certificates-bg security-certificates-bg--${options.theme} security-certificates-bg--${options.padding} ${options.theme === "light" || options.theme === "cyan"
      ? "js-section_nav-dark"
      : ""
      }`}>
      <div className="container mx-auto">
        <div className="security-certificates__wrapper">
          <div className="security-certificates__inner">
            <div className="security-certificates__title">
              {getTranslatableContent(data.title)}
            </div>

            <div className="security-certificates__content__wrapper">
              <div className="security-certificates__content">
                <div className="security-certificates__content-list__wrapper">
                  <ul className="security-certificates__content-list">
                    {data.certs.map((c) => (
                      <li className="security-certificates__content-list__item">
                        <div className="security-certificates__content-list__item-image">
                          <Image
                            fileName={c.image.source}
                            width={c.image.width}
                            height={c.image.height}
                          />
                        </div>
                        <div className="security-certificates__content-list__item-title">
                          {getTranslatableContent(c.title)}
                        </div>
                        <div className="security-certificates__content-list__item-desc">
                          {getTranslatableContent(c.description)}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}
