import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { staticPagesData } from "../../Services/DataService";
import Modular from "../../Components/Modular";
import "./index.scss";

export default function DynamicPage() {
  const { slug } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(false);
  
  useEffect(() => {
    const findItem = staticPagesData.find((p) => p.slug === pathname.substring(1));
    findItem ? setActivePage(findItem) : navigate("/error");
  }, [location, slug, navigate]);


  return activePage?.modules?.length ? (
    <div className={`dynamic-page ${activePage?.options?.customClasses}`}>
      {activePage?.modules
        .filter((m) => m.active !== false)
        .map((module) => {
          console.log("module >>>", module);
          return (
            <Modular
              key={module.id}
              module={{
                ...module,
                ...{
                  pageName: activePage?.pageName
                    ? activePage?.pageName
                    : false,
                },
              }}
            />
          );
        })}
    </div>
  ) : null;
}
