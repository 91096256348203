import React, { useState, useEffect } from "react";
import Image from "../Image";
import "./index.scss";
import useTranslationEncapsulated from "../../Libs/useTranslation";

const items = [
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Upload.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Upload.Description%",
    index: 0,
    alias: "upload",
    background: "bg-white",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Create.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Create.Description%",
    index: 1,
    alias: "create",
    background: "bg-white",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Localize.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Localize.Description%",
    index: 2,
    alias: "localize",
    background: "bg-cyan",
  },

  {
    title: "%Commons.Modules.WorkflowScheme.Items.Api.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Api.Description%",
    index: 3,
    alias: "api",
    background: "bg-white",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Apps.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Apps.Description%",
    index: 4,
    alias: "apps",
    background: "bg-white",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Sdk.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Sdk.Description%",
    index: 5,
    alias: "sdk",
    background: "bg-white",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Mt.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Mt.Description%",
    index: 6,
    alias: "mt",
    background: "bg-cyan",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Review.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Review.Description%",
    index: 7,
    alias: "review",
    background: "bg-cyan",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Memory.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Memory.Description%",
    index: 8,
    alias: "memory",
    background: "bg-black",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Publish.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Publish.Description%",
    index: 9,
    alias: "publish",
    background: "bg-black",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Share.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Share.Description%",
    index: 10,
    alias: "share",
    background: "bg-cyan",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Continues.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Continues.Description%",
    index: 11,
    alias: "continues",
    background: "bg-black",
  },
  {
    title: "%Commons.Modules.WorkflowScheme.Items.Edit.Title%",
    desc: "%Commons.Modules.WorkflowScheme.Items.Edit.Description%",
    index: 12,
    alias: "edit",
    background: "bg-cyan",
  }
];

export default function WorkflowScheme({ data, pageName }) {
  const { getTranslatableContent, Trans, t } = useTranslationEncapsulated();
  
  
  
  const [showInfo, setShowInfo] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (activeItem) {
      setShowInfo(true);
    } else {
      setShowInfo(false);
    }
  }, [activeItem]);

  const setVisibleItem = (alias) => {
    const findedItem = items.find((i) => i.alias === alias);
    if (findedItem) {
      setActiveItem(findedItem);
    }
  };

  const showVisibleItem = (alias, e) => {
    setPosition({ x: e.clientX, y: e.clientY });

    setVisibleItem(alias);
  };

  const hideVisibleItem = () => {
    setActiveItem(false);
  };

  useEffect(() => {}, [position]);

  return (
    <section className="workflow-scheme-bg js-section_nav-dark">
      <div className="container mx-auto workflow-scheme__container">
        <div className="workflow-scheme__wrapper">
          <div className="workflow-scheme__title-wrapper">
            <div className="workflow-scheme__title">
            <Trans i18nKey="Commons.Modules.WorkflowScheme.Title" components={{ span_highlight: <span className="highlight" />}}/>
             
            </div>
          </div>
          <div className="workflow-scheme__inner">
            <div className="parent">
              <div
                className="box bg-white div1 image"
                onMouseEnter={(e) => showVisibleItem("upload", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                {" "}
                <span className="num">1</span>
                <Image
                  fileName="Assets/Icons/upload_file_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-white div2 content no-action">
                <span className="num">2</span> {t("Commons.Modules.WorkflowScheme.Items.Create.Title")}
              </div>
              <div
                className="box bg-white div3 image"
                onMouseEnter={(e) => showVisibleItem("api", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">3</span>
                <Image
                  fileName="Assets/Icons/api_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white image div4"
                onMouseEnter={(e) => showVisibleItem("create", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">4</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_down_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-cyan image div5"
                onMouseEnter={(e) => showVisibleItem("localize", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                {" "}
                <span className="num">5</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_down_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>

              <div
                className="box bg-cyan image div6"
                onMouseEnter={(e) => showVisibleItem("MT", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">6</span>
                <Image
                  fileName="Assets/Icons/translate_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-cyan div7 content no-action">
                <span className="num">7</span>{t("Commons.Modules.WorkflowScheme.Items.Localize.Title")}{" "}
              </div>
              <div
                className="box bg-cyan image div8"
                onMouseEnter={(e) => showVisibleItem("review", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <Image
                  fileName="Assets/Icons/review_eye_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
                <span className="num">8</span>
              </div>
              <div
                className="box bg-black image inverted div9"
                onMouseEnter={(e) => showVisibleItem("memory", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">9</span>{" "}
                <Image
                  fileName="Assets/Icons/product-translation-memory.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>

              <div className="box bg-black div10 content no-action">
                <span className="num">10</span>{t("Commons.Modules.WorkflowScheme.Items.Publish.Title")}{" "}
              </div>
              <div
                className="box bg-black div11 image inverted"
                onMouseEnter={(e) => showVisibleItem("continues", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">11</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_up_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white div12 image"
                onMouseEnter={(e) => showVisibleItem("sdk", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">12</span>{" "}
                <Image
                  fileName="Assets/Icons/code_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-white div13 image"
                onMouseEnter={(e) => showVisibleItem("apps", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">13</span>
                <Image
                  fileName="Assets/Icons/apps_black_24dp.svg"
                  width={64}
                  height={64}
                />
              </div>
              <div
                className="box bg-cyan div14 image"
                onMouseEnter={(e) => showVisibleItem("share", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">14</span>
                <Image
                  fileName="Assets/Icons/supervisor_account_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-cyan div15 image"
                onMouseEnter={(e) => showVisibleItem("edit", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">15</span>
                <Image
                  fileName="Assets/Icons/edit_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div
                className="box bg-black image inverted div16"
                onMouseEnter={(e) => showVisibleItem("publish", e)}
                onMouseLeave={() => hideVisibleItem()}
              >
                <span className="num">16</span>
                <Image
                  fileName="Assets/Icons/keyboard_double_arrow_left_black_24dp.svg"
                  width={64}
                  height={64}
                />{" "}
              </div>
              <div className="box bg-black no-action div17">
                <span className="num">17</span>
              </div>
              <div
                style={{ left: position.x, top: position.y }}
                className={`info-box div18 ${showInfo ? "show" : ""} ${
                  activeItem?.background ? activeItem.background : ""
                }`}
              >
                <div className="info-box__inner">
                  <div className="info-box__title">{getTranslatableContent(activeItem.title)}</div>
                  <div className="info-box__description">{getTranslatableContent(activeItem.desc)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
