import React from "react";
import { Link } from "react-scroll";
import Accordion from "../Accordion";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";
export default function CategoricalFAQs({
  data,
  options = {
    theme: "light",
  },
}) {
  const { getTranslatableContent, t} = useTranslationEncapsulated();
  
  
  const getCategoryMenuItem = (category) => {
    return (
      <li>
        <Link
          to={`category_${category.id}`}
          spy={true}
          smooth={true}
          activeClass={"active"}
        >
          {getTranslatableContent(category?.title)}
        </Link>
      </li>
    );
  };

  return (
    <section className="categorical-faqs-container js-section_nav-dark">
      <div className="bg-white w-full">
        <div className="container mx-auto">
          <div className="faq-page-content grid grid-cols-4 lg:grid-cols-8">
            <div className="hidden lg:block lg:row-span-full lg:col-start-2">
              <div className="categorical-faqs--categories">
                <ul>
                  {data.categories.map((category) => {
                    return getCategoryMenuItem(category)
                  })}
                </ul>
              </div>
            </div>
            <div className="col-span-full col-start-1 lg:col-span-4 lg:col-start-4">
              {data.categories.map((category) => {
                return (
                  <div
                    key={category.id}
                    className="category-group"
                    id={`category_${category.id}`}
                  >
                    <h2>{getTranslatableContent(category?.title)}</h2>
                    {category.children.map((child) => {
                      return (
                        <Accordion
                          key={child.id}
                          question={getTranslatableContent(child?.question)}
                          answer={getTranslatableContent(child?.answer)}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
