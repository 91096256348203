const LOCALE = "en_GB";
const HARD_ROUTE_CHANGE = false;
//const REGISTER_URL = "https://app.lugath.com";
//const LOGIN_URL = "https://app.lugath.com";
//const INTEGRATION_URL = "https://app.lugath.com/integrations";

const LOGIN_URL = "https://app.lugath.com";
const REGISTER_URL = "https://app.lugath.com/login";
const INTEGRATION_URL = "https://localhost:app.lugath.com/integrations";
const MARQUEE_VISIBILITY = false;
const MARQUEE_COOKIE_KEY_NAME = "lugath.website.marqueeIsVisible";
const COOKIE_SETTINGS_KEY_NAME = "lugath.website.cookieSettings";
const COOKIE_SETTINGS_EXPIRE_IN_DAYS = 90;
const MARQUEE_COOKIE_EXPIRE_IN_DAYS = 1;
const AVAILABLE_LANGUAGES = ["EN", "TR", "ES"]; //, "TR", "ES"];

const DEFAULT_COOKIE_SETTINGS_VALUE = {
  cookiePopupVisibility: true,
  channelSettings: [
    {
      cookieType: "advertising",
      value: true,
    },
    {
      cookieType: "preferences",
      value: true,
    },
    {
      cookieType: "analytics",
      value: true,
    },
  ],
};
const VARIABLES = {
  "fremiumDayRate":"14",
  "brandName":"Lugath",
}

const APP = {
  ENV: process.env.REACT_APP_ENV,
  LOCALE: LOCALE,
  HARD_ROUTE_CHANGE: HARD_ROUTE_CHANGE,
  REGISTER_URL: REGISTER_URL,
  LOGIN_URL: LOGIN_URL,
  INTEGRATION_URL:INTEGRATION_URL,
  MARQUEE_VISIBILITY: MARQUEE_VISIBILITY,
  AVAILABLE_LANGUAGES: AVAILABLE_LANGUAGES,
  MARQUEE_COOKIE_KEY_NAME: MARQUEE_COOKIE_KEY_NAME,
  MARQUEE_COOKIE_EXPIRE_IN_DAYS: MARQUEE_COOKIE_EXPIRE_IN_DAYS,
  COOKIE_SETTINGS_KEY_NAME: COOKIE_SETTINGS_KEY_NAME,
  COOKIE_SETTINGS_EXPIRE_IN_DAYS: COOKIE_SETTINGS_EXPIRE_IN_DAYS,
  DEFAULT_COOKIE_SETTINGS_VALUE: DEFAULT_COOKIE_SETTINGS_VALUE,
};

export default {
  APP,
  VARIABLES
};
