import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowOutward } from "../../Assets/Icons/arrow_outward.svg";
import { profilesData } from "../../Services/DataService";
import "./index.scss";
import CTAButton from "../CTABtnWrapper";
import useTranslationEncapsulated from "../../Libs/useTranslation";

export default function OtherProfiles({ data, pageName }) {
  const {getTranslatableContent} = useTranslationEncapsulated();
  
  const [visibleProfiles, setVisibleProfiles] = useState([]);
  const [availableProfiles] = useState(profilesData);
  
  const getVisibleProfiles = () => {
    const visibleProfiles = availableProfiles.filter((a) =>
      data.profiles.map((p) => p.profile).includes(a.alias)
    );

    setVisibleProfiles(
      visibleProfiles.map((p) => {
        const incomingProfile = data.profiles.find(
          (f) => f.profile === p.alias
        );
        return incomingProfile?.description
          ? { ...p, ...{ description: incomingProfile.description } }
          : p;
      })
    );
  };



  useEffect(() => {
    if (data?.profiles?.length) {
      getVisibleProfiles();
    }
  }, [data, availableProfiles]);
  return (
    <section className="product-other-solutions-bg ">
      <div className="container mx-auto product-other-solutions__container">
        <div className="product-other-solutions__wrapper">
          <div className="product-other-solutions__inner">
            <div className="parent">
              <div className="div1">
                <div className="product-other-solutions__title" dangerouslySetInnerHTML={
                  typeof data.title === "string"
                    ? { __html: getTranslatableContent(data.title) }
                    : null
                }>
                   {typeof data.secondaryTitle === "object"
                  ? data.title
                  : null}
                </div>
              </div>
              <div className="div2">
                {" "}
                <div className="product-other-solutions__desc-wrapper">
                  <div className="product-other-solutions__desc-title">
                    {getTranslatableContent(data?.description)}
                  </div>
                  {data.cta.map((c, i) => (
                    <CTAButton
                      key={c.id}
                      options={{
                        formPopupTheme: "light",
                        utmLabel: "js_other_profiles__cta_btn",
                      }}
                      children={
                        <div
                          key={c.id + "child"}
                          className="product-other-solutions__desc-link"
                        >
                          <span className="label">{getTranslatableContent(c?.label)}</span>
                          <span className="icon">
                            {" "}
                            <ArrowOutward />
                          </span>
                        </div>
                      }
                      data={{
                        ...c,
                        ...{ pageName: pageName ? getTranslatableContent(pageName) : "" },
                      }}
                    ></CTAButton>
                  ))}
                </div>
              </div>
              {visibleProfiles?.length
                ? visibleProfiles.map((p, index) => (
                    <div key={index} className={`div${index + 3}`}>
                      <Link to={p.link}>
                        <div className="product-other-solutions__card-wrapper">
                          <div className="product-other-solutions__card">
                            <div className="product-other-solutions__card-content">
                              <div className="product-other-solutions__card-icon">
                                <ArrowOutward />
                              </div>
                              <div className="product-other-solutions__card-title">
                                {getTranslatableContent(p?.title)}
                              </div>
                              <div className="product-other-solutions__card-desc">
                                {getTranslatableContent(p?.description)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
