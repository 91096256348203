import React, { useEffect } from "react";
import Image from "../Image";
import CONFIG from "../../Services/ConfigService";
import useTranslationEncapsulated from "../../Libs/useTranslation";
import "./index.scss";

export default function IntegrationHeader({ data, pageName, id }) {
  const { getTranslatableContent, Trans, getRawKey} = useTranslationEncapsulated();
  useEffect(() => {}, [data]);
  
  return (
    <section id={id} className="integrations-details-page__hero">
        <div className="container mx-auto">
          <div className="integrations-details-page__hero-header__wrapper">
            {data ? (
              <>
                <div className="integrations-details-page__hero-header">
                  <div className="integrations-details-page__hero-header__logo">
                    <Image
                      fileName={`Assets/Images/Marketplace/${data.image}`}
                    />
                  </div>
                  <div className="integrations-details-page__hero-header__content">
                    <div className="integrations-details-page__hero-header__content-name">
                      {getTranslatableContent(data.name)}
                    </div>
                    <div className="integrations-details-page__hero-header__content-category">
                      {getTranslatableContent(data.categoryLabel)}
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="integrations-details-page__hero-actions">
              <div
                className={`integrations-details-page__hero-actions__label integrations-details-page__hero-actions__label--${data?.status?.alias}`}
              >
                {data?.status?.label}
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  DEVELOPER
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="https://www.lugath.com">
                    Lugath
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  INTEGRATION SUPPORT
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="mailto:support@lugath.com">
                    support@lugath.com
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                <div className="integrations-details-page__hero-actions-box__title">
                  ADDITIONAL INFORMATION
                </div>
                <div className="integrations-details-page__hero-actions-box__content">
                  <a target="_blank" href="https://www.dropbox.com/apps/lugath">
                    Lugath for Dropbox
                  </a>
                </div>
              </div>
              <div className="integrations-details-page__hero-actions-box">
                {/*<div className="integrations-details-page__hero-actions-box__title">
                  GET START
                </div>*/}
                <div className="integrations-details-page__hero-actions-box__content">
                  <a
                    className="js_landing_page_top_cta_btn install-now-btn"
                    target="_blank"
                    href={`${CONFIG.APP.INTEGRATION_URL}?utm_source=${encodeURIComponent(
                      window.location.href
                    )}&utm_medium=referral&utm_content=js_landing_page_top_cta_btn&utm_campaign=DROPBOX`}
                  >
                    Try Now!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
}
