import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { featuresData } from "../../../Services/DataService";
import Modular from "../../../Components/Modular";
import "./index.scss";

export default function FeatureDetailsP() {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState(false);

  useEffect(() => {
    const findItem = featuresData.find((p) => p.slug === slug);
    findItem ? setActivePage(findItem) : navigate("/error");
  }, [location, slug, navigate]);

  return activePage?.modules?.length ? (
    <div className="feature-details-page">
      {activePage?.modules
        .filter((m) => m.active !== false)
        .map((module) => {
          console.log(module);
          return (
            <Modular
              key={module.id}
              module={{
                ...module,
                ...{
                  pageName: activePage?.pageName
                    ? activePage?.pageName
                    : false,
                },
              }}
            />
          );
        })}
    </div>
  ) : null;
}
