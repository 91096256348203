import React from "react";
import { Link } from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import { ReactComponent as LugathFooterLogo } from "../../Assets/Icons/logo-footer-black.svg";
import { ReactComponent as FacebookLogo } from "../../Assets/Icons/social/facebook.svg";
import { ReactComponent as XLogo } from "../../Assets/Icons/social/x.svg";
import { ReactComponent as LinkedinLogo } from "../../Assets/Icons/social/linkedin.svg";
import { ReactComponent as InstagramLogo } from "../../Assets/Icons/social/instagram.svg";
import { ReactComponent as GithubLogo } from "../../Assets/Icons/social/github.svg";
import "./index.scss";

export default function Footer() {
  const { t, i18n, ready } = useTranslation();
  return (
    <>
      <section className="bg-footer js-section_nav-dark">
        <div className="container mx-auto">
          <div className="footer">
            <div className="parent">
              <div className="div1 footer-logo__wrapper">
                <div className="div1 footer-logo">
                  <LugathFooterLogo />
                </div>
              </div>
              <div className="div2 footer-cta-slogan__wrapper">
                <div className="footer-cta-slogan">
                  <div className="footer-cta-slogan__content">
                    <Trans i18nKey="Commons.Footer.GetContactText" components={{
                      mailtolink: <a
                      href="mailto:support@lugath.com"
                      style={{ textDecoration: "underline", color: "#202020" }}
                    />
                    }} />

                  </div>
                </div>
              </div>
              <div className="div3 footer-newsletter-block__wrapper">
                <div
                  style={{ display: "none" }}
                  className="footer-newsletter-block"
                >
                  <div className="footer-newsletter-block__title">
                  </div>
                  <div className="footer-newsletter-block__component">
                   
                  </div>
                </div>
              </div>
              <div className="div4 footer-stay-in-touch__wrapper">
                <div className="footer-stay-in-touch">
                  <div className="footer-stay-in-touch__title">
                    {t("Commons.Site.Description")}
                  </div>
                </div>
              </div>
              <div className="div5 footer-navigate-menu__wrapper">
                {" "}
                <div className="footer-navigate-menu">
                  <div className="footer-navigate-menu__title">{t("Commons.Footer.Menu.Navigate.Title")}</div>
                  <div className="footer-navigate-menu__items-wrapper">
                    <ul className="footer-navigate-menu__items">
                      <li className="footer-navigate-menu__item">
                        <Link to="/solutions">{t("Commons.Header.Menu.Solutions.Title")}</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        {" "}
                        <Link to="/features">{t("Commons.Header.Menu.Features.Title")}</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/pricing">{t("Commons.Header.Menu.Pricing.Title")}</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        {" "}
                        <a
                          className="cursor-pointer"
                          target="_blank"
                          rel="noreferrer"
                          href={"https://docs.lugath.com"}
                        >
                          API
                        </a>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/security">{t("Commons.Header.Menu.Features.Sublinks.Security.Title")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="div6 footer-resource-menu__wrapper">
                <div className="footer-navigate-menu">
                  <div className="footer-navigate-menu__title">{t("Commons.Header.Menu.Resources.Title")}</div>
                  <div className="footer-navigate-menu__items-wrapper">
                    <ul className="footer-navigate-menu__items">
                      <li className="footer-navigate-menu__item">
                        <Link to="/reports">{t("Commons.Header.Menu.Resources.Sublinks.Reports.Title")}</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/integrations">{t("Commons.Header.Menu.Resources.Sublinks.Integrations.Title")}</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/news">{t("Commons.Header.Menu.Resources.Sublinks.Updates.Title")}</Link>
                      </li>
                      <li className="footer-navigate-menu__item">
                        <Link to="/tutorials">{t("Commons.Header.Menu.Resources.Sublinks.Tutorials.Title")}</Link>
                      </li>

                      <li className="footer-navigate-menu__item">
                        <Link to="/faqs">{t("Commons.Header.Menu.Resources.Sublinks.FAQs.Title")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="div7 footer-terms-menu__wrapper">
                <div className="footer-terms">
                  <div className="footer-terms__title">{t("Commons.Footer.Menu.Legal.Title")}</div>
                  <ul className="footer-terms-menu__list">
                    <li className="footer-terms-menu__list-item">
                      <Link to="/legal/privacy-policy">{t("Commons.Footer.Menu.Legal.Sublinks.PrivacyPolicy.Title")}</Link>
                    </li>
                    <li className="footer-terms-menu__list-item">
                      <Link to="/legal/terms-of-use">{t("Commons.Footer.Menu.Legal.Sublinks.TermsOfUse.Title")}</Link>
                    </li>
                    <li className="footer-terms-menu__list-item">
                      <Link to="/legal/cookie-settings">{t("Commons.Footer.Menu.Legal.Sublinks.Cookies.Title")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="div8 footer-social-media__wrapper">
                <div className="footer-social-media">
                  <div className="div8 footer-social-media__title">
                  {t("Commons.Footer.Menu.FollowUs.Title")}
                  </div>
                  <ul className="footer-social-media__list">
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.facebook.com/Lugathcom"}
                      >
                        <FacebookLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.twitter.com/lugathcom"}
                      >
                        <XLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.linkedin.com/company/lugath/"}
                      >
                        <LinkedinLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link target="_blank" to={"https://github.com/lugath"}>
                        {" "}
                        <GithubLogo />
                      </Link>
                    </li>
                    <li className="footer-social-media__list-item">
                      <Link
                        target="_blank"
                        to={"https://www.instagram.com/lugathcom/"}
                      >
                        <InstagramLogo />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<div className="footer-bottom__wrapper">
        <div className="container mx-auto">
          Hi 👋 Want to ask our team member to see if our product could help
          your team?
        </div>{" "}
                      </div>*/}
    </>
  );
}
